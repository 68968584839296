import React, {Dispatch, useEffect} from 'react';
import {connect} from 'react-redux';
import {RootState, actions} from '../../store';
import {ThunkDispatch} from 'redux-thunk';
import {Action, AnyAction} from 'redux';
import {Switch, Route} from 'react-router-dom';
import Header from '../Header/Header';
import Personal from '../Personal/Personal';
import Applications from '../Applications/Applications';
import VideoCall from '../VideoCall/VideoCall';
import Authorization from '../Authorization/Authorization';
import Menu from '../Menu/Menu';
import Clients from '../Clients/Clients';
import {SessionAPI} from '../../api/SessionAPI';
import {GetUserInfo} from '../../store/Auth/types';
import Spinner from '../Spinner/Spinner';
import {ISettings} from '../../store/Admin/types';
import DocumentPanel from "../Document/DocumentPanel";
import Organization from "../Organization/Organization";
import Services from "../Services/Services";
import Editor from "../Document/Editor";
import ClientInfo from "../ClientInfo/ClientInfo";
import ViewClientInfo from "../ClientInfo/ViewClientInfo";

import DocumentTable from "../DocumentTable/DocumentTable";
import ViewDocument from '../bricks/ViewDocument';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const AppStack: React.FC<Props> = (props) => {
    const { isAuthorized, loading, getUserInfo } = props;
    const {pathname} = window.location;

    // useEffect(() => {
    //     if(sessionId) {
    //         setLoaderAction(true);
    //         SessionAPI.getUserInfo(sessionId).then(data => {
    //             const getUserInfo = {
    //                 fullName: data.fullName,
    //                 inn: data.inn,
    //                 innOrganisation: data.innOrganisation,
    //                 roles: data.roles,
    //             }
    //             getUserInfoAction(getUserInfo, true);
    //             setLoaderAction(false);
    //         })
    //     }
    // }, [sessionId, getUserInfoAction, setLoaderAction]);

    if (loading) {
        return <Spinner fixed="fixed"/>
    }

    if (!isAuthorized) {
        return <Authorization/>
    }

    const companyAdmin = () => {
        if (getUserInfo && getUserInfo.roles === "CompanyAdmin") {
            return (
                <Switch>
                    <Route
                        path='/'
                        component={Organization}
                        exact
                    />
                    <Route
                        path="/personal"
                        component={Personal}
                        exact/>
                    <Route
                        path="/clients"
                        component={Clients}
                        exact/>
                    <Route
                        path="/clients/:customerId"
                        component={ClientInfo}/>
                      <Route
                        path="/client-view/:id"
                        component={ViewClientInfo}/>
                    <Route path="/add/document" component={Editor}/>
                    <Route path="/document" component={DocumentPanel}/>
                    <Route
                        path='/services'
                        component={Services}
                    />
                    <Route exact path='/list/document'  component={DocumentTable} />
                    <Route path="/document-view/:requestId" component={ViewDocument} />
                </Switch>
            )
        }
    }

    const moderator = () => {
        if (getUserInfo && getUserInfo.roles === "Moderator") {
            return (
                <Switch>
                    <Route
                        path="/"
                        exact
                        component={Applications}/>
                    <Route
                        path="/clients"
                        component={Clients}
                        exact/>
                    <Route
                        path="/clients/:customerId"
                        component={ClientInfo}/>
                        <Route
                        path="/client-view/:id"
                        component={ViewClientInfo}/>
<Route path="/document-view" component={ViewDocument} />

                </Switch>
            )
        }
    };

    return (
        <div>
            <Switch>
                <Route
                    path="/video-call"
                    component={VideoCall}/>
            </Switch>
            <div className="mainContainer">
                <Spinner fixed="fixed"/>
                {!pathname.match(/video-call/) &&
                <>
                    <Header/>
                    <Menu/>
                </>
                }
                {companyAdmin()}
                {moderator()}
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.isAuthorized,
    loading: state.auth.loading,
    getUserInfo: state.auth.getUserInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    getUserInfoAction: (getUserInfo: GetUserInfo, isAuthorized: boolean) => dispatch(actions.auth.getUserInfoAction(getUserInfo, isAuthorized)),
    setLoaderAction: (loading: boolean) => dispatch(actions.auth.setLoaderAction(loading)),
    settingsAction: (settings: ISettings) => dispatch(actions.admin.settingsAction(settings)),
    logoutAction: () => dispatch(actions.auth.logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppStack);
