import {ReductionState, ReductionTypes} from "./types";
import {ReductionActions} from './action';
import {Reducer} from "redux";

const defaultState: ReductionState = {
    reduction: null,
    addReductionSuccess: false
};

export const ReductionReducer: Reducer<ReductionState, ReductionActions> = (state = defaultState, action) => {
    switch (action.type) {
        case ReductionTypes.GET_REDUCTION_SUCCESS:
            return {
                ...state,
                reduction: [...action.payload]
            };
        case ReductionTypes.ADD_REDUCTION:
            return {
                ...state,
                addReductionSuccess: !state.addReductionSuccess
            };
        default:
            return state
    }
};

export default ReductionReducer;
