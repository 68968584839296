import axios from "axios";
import { InstanceDocumentHead, InstanceHead } from "./InstanceHead";

export class ClientDocumentsAPI {
  public static async getPaymentOrders(
    sessionId: string,
    clientAccountId: number
  ) {
    return await InstanceHead.instance
      .get(`ClientDocuments/GetPaymentOrders/${sessionId}/${clientAccountId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async addPaymentOrder(
    sessionId: string,
    clientAccountId: number,
    paymentOrder: IAddPaymentOrder
  ) {
    return await InstanceHead.instance
      .post(
        `ClientDocuments/AddPaymentOrder/${sessionId}/${clientAccountId}`,
        paymentOrder
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async addPayment(
    sessionId: string,
    userAccountId: number,
    documentDescription: string,
    documentJson: string,
    documentType: string
  ) {
    documentJson = JSON.stringify(documentJson);
    return await InstanceDocumentHead.instance
      .post(`/documents/AddJsonDocument/${sessionId}`, {
        userAccountId,
        documentDescription,
        documentJson,
        documentType,
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async getContracts(sessionId: string, clientAccountId: number) {
    return await InstanceHead.instance
      .get(`ClientDocuments/GetContracts/${sessionId}/${clientAccountId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocuments(
    sessionId: string,
    userAccountId: number,
    documentStatus: string,
    documentType: string = ""
  ) {
    return await InstanceDocumentHead.instance
      .post(`/documents/GetDocuments/${sessionId}`, {
        documentStatus,
        userAccountId,
        documentType,
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocumentHtml(sessionId: string, documentId: string) {
    return await InstanceDocumentHead.instance
      .get(`/documents/GetDocumentHtml/${sessionId}/${documentId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async getSessionEvents(sessionId: string) {
    return await InstanceDocumentHead.instance
      .get(`/Events/GetEvents/${sessionId}`, {
        timeout: 30 * 1000,
        timeoutErrorMessage: "Время ожидания истекло!",
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocumentForAdmin(
    sessionId: string,
    userAccountId?: number | null,
    documentStatus?: string,
    documentType?: string,
    createdDateFrom?: Date | number | null,
    createdDateTo?: Date | number | null
  ) {
    return await InstanceDocumentHead.instance
      .post(`/documents/GetShortDocuments2/${sessionId}`, {
        userAccountId,
        documentStatus,
        documentType,
        createdDateFrom,
        createdDateTo,
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocumentForAvconAdmin(
    userAccountId?: number | null,
    documentStatus?: string,
    documentType?: string,
    createdDateFrom?: Date | number | null,
    createdDateTo?: Date | number | null
  ) {
    const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
    return await InstanceDocumentHead.instance
      .post(`/documents/GetShortDocuments/${key}`, {
        userAccountId,
        documentStatus,
        documentType,
        createdDateFrom,
        createdDateTo,
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async changeStatusDocument(
    sessionId: string,
    documentId: string,
    executionStatus: string,
    executionComment: string
  ) {
    return await InstanceDocumentHead.instance
      .post(`/documents/ChangeDocumentExecutionStatus/${sessionId}`, {
        documentId,
        executionStatus,
        executionComment,
      })
      .then((res) => {
        return res.data;
      });
  }

  public static async getDocument(sessionId: string, documentId: string) {
    console.log(sessionId, documentId);
    return await InstanceDocumentHead.instance
      .get(`/documents/GetDocument/${sessionId}/${documentId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => console.log("getDocument", err));
  }

  public static async getDocumentPdf(sessionId: string, documentId: string) {
    return await InstanceDocumentHead.instance
      .get(`/documents/GetDocumentPdf/${sessionId}/${documentId}`)
      .then((res) => {
        return res.data;
      });
  }

  public static async sendClientContract(
    sessionId: string,
    clientAccountId: number
  ) {
    return await InstanceHead.instance
      .post(
        `ClientDocuments/SendClientContract/${sessionId}/${clientAccountId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async getContract(
    sessionId: string,
    documentId: string,
    apiKey: string
  ) {
    return await InstanceHead.instance
      .get(`ClientDocuments/GetContract/${sessionId}/${documentId}/${apiKey}`)
      .then((res) => {
        return res.data;
      });
  }
}

export interface IAddPaymentOrder {
  paymentOrderNumber: string;
  description: string;
  date: Date;
  payer: {
    accountOfBank: string;
    calculationScheme: string;
  };
  paymentRecipient: {
    name: string;
    bank: string;
    bik: string;
    paymentAccount: string;
    target: string;
    paymentCode: string;
    amount: number;
    currency: string;
  };
}
