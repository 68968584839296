import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import Search from '../bricks/Search'
import ClientsTable from '../bricks/ClientsTable'

const Clients = () => {
  const [search, setSearch] = useState('')
  // @ts-ignore
  const handleChange = value => {
    setSearch(value)
  }
  return (
    <div className="mainContent">
      <div className="topRow">
        <Grid container spacing={3}>
          <Grid item>
            <div className="title">Клиенты</div>
          </Grid>
          <Grid item>
            <Search search={search} setSearch={handleChange} />
          </Grid>
        </Grid>
      </div>
      <ClientsTable search={search} setSearch={handleChange} />
    </div>
  )
}

export default Clients
