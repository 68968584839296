import React, { Dispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import format from "date-fns/format";
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

type Props = ReturnType<typeof mapDispatchToProps>;

const DateRange:React.FC<Props> = ({ startDateAction, endDateAction }) => {
    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    let firstDay = new Date(y, m, 1);
    let lastDay = new Date(y, m + 1, 0);
    const [startDate, setStartDate] = useState<any>(
        moment(firstDay)
    );

    const [endDate, setEndDate] = useState<any>(
        moment(lastDay)
    );

    const startDateChange = (date: Date | any) => {
        setStartDate(date);
    };

    const endDateChange = (date: Date | any) => {
        setEndDate(date);
    };

    useEffect(() => {
        if(startDate) {
            startDateAction(startDate);
        }
    }, [startDate, startDateAction]);

    useEffect(() => {
        if(endDate) {

            endDateAction(endDate);
        }
    }, [endDate, endDateAction]);


    return (
        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
            <div className="dateRight">
                {/* <DatePicker
                    format="d MMMM yyyy"
                    cancelLabel="отмена"
                    value={startDate}
                    onChange={startDateChange} /> */}
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="d MMMM yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={startDate}
                    onChange={startDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <span className="arrow">-</span>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="d MMMM yyyy"
                    margin="normal"
                    id="date-picker-inline-end"
                    value={endDate}
                    onChange={endDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                {/* <DatePicker
                    format="d MMMM yyyy"
                    cancelLabel="отмена"
                    value={endDate}
                    onChange={endDateChange} /> */}
            </div>
        </MuiPickersUtilsProvider>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    startDateAction: (startDate: Date) => dispatch(actions.moderation.startDateAction(startDate)),
    endDateAction: (endDate: Date) => dispatch(actions.moderation.endDateAction(endDate)),
});

export default connect(null, mapDispatchToProps)(DateRange);

class RuLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "LLLL", { locale: this.locale });
    }

    getDatePickerHeaderText(date: any) {
        return format(date, "dd MMMM", { locale: this.locale });
    }
}
