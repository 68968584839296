import { combineReducers } from 'redux';
import auth from './Auth/reducer';
import moderation from './Moderation/reducer';
import admin from './Admin/reducer';
import document from './Document/reducer';
import reduction from './Reduction/reducer';
import createDocumentReducer from './CreateDocument/reducer';

const rootReducer = combineReducers({
    auth,
    moderation,
    admin,
    document,
    reduction,
    createDocumentReducer
});


export default rootReducer;
