import React, { useState } from "react";
import { Grid, Tabs, Tab, TextField } from "@material-ui/core";
import InboxTable from "../bricks/InboxTable";
import MissingTable from "../bricks/MissingTable";
import HistoryTable from "../bricks/HistoryTable";
import DateRange from "../bricks/DateRange";
import { connect } from "react-redux";
import { RootState } from "../../store";
import Search from "../bricks/Search";

type Props = ReturnType<typeof mapStateToProps> & {};

const Applications: React.FC<Props> = ({ settings }) => {
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = useState("");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="mainContent">
      <div className="topRow">
        <Grid container spacing={3} justify="space-between" alignItems="center">
          <Grid item>
            <div className="title">Заявки</div>
          </Grid>
          <Grid item>
            <Search search={search} setSearch={setSearch} />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            className="tabs">
            <Tab
              label="Входящие"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            {settings && !settings.btbInterface && (
              <Tab
                label="Пропущенные "
                id="simple-tab-1"
                aria-controls="simple-tabpanel-1"
              />
            )}
            <Tab
              label="История"
              id="simple-tab-2"
              aria-controls="simple-tabpanel-2"
            />
          </Tabs>
        </Grid>
        {value === 2 && (
          <Grid item>
            <DateRange />
          </Grid>
        )}
      </Grid>
      <TabPanel value={value} index={0}>
        <InboxTable search={search} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MissingTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HistoryTable />
      </TabPanel>
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  settings: state.admin.settings,
});
export default connect(mapStateToProps)(Applications);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <div className="tabPanel ">{children}</div>}
    </div>
  );
}
