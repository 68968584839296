import React, {
  useState,
  useEffect,
} from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { baseUrl } from "../../utils/baseUrl";
import { IPasswordInfo } from "../../store/Moderation/types";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import {
  EditIcon,
  SaveIcon,
} from "../../icons/icons";
import { AdminAPI } from "../../api/AdminAPI";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SnackbarAlert from "./SnackbarAlert";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useHistory } from "react-router-dom";

type Props = ReturnType<
  typeof mapStateToProps
> & {
  passwordInfo: IPasswordInfo;
  imageClickOpen: (number: number) => void;
  customerId: number;
  clientInfo?: boolean;
  pdf?: any;
};

const PasswordData: React.FC<Props> = (props) => {
  const {
    sessionId,
    customerId,
    passwordInfo,
    imageClickOpen,
    settings,
    clientInfo,
    getUserInfo,
    pdf,
  } = props;
  const [isEditPhone, setEditPhone] =
    useState(false);
  const [phone, setPhone] = useState("");
  const [notification, setNotification] =
    useState(false);
  const [message, setMessage] = useState("");
  let history = useHistory();
  useEffect(() => {
    setPhone(passwordInfo.phone!);
  }, [passwordInfo]);

  const changeClientPhone = () => {
    if (!phone.endsWith("_")) {
      AdminAPI.changeUserPhone(
        sessionId!,
        customerId,
        phone
          .replaceAll(" ", "")
          .replaceAll("+", "")
          .replaceAll("_", "")
      )
        .then((res) => {
          setNotification(true);
          setMessage(
            "Вы успешно сменили номер телефона пользователя!"
          );
        })
        .catch((err) => {
          setNotification(true);
          setMessage(
            "Не удалось сменить номер телефона!"
          );
        });
      setEditPhone(!isEditPhone);
    }
  };

  const resetClientPhone = () => {
    const randomNumber = Math.floor(
      100000 + Math.random() * 900000
    );
    AdminAPI.changeUserPhone(
      sessionId!,
      customerId,
      `996111${randomNumber}`
    )
      .then((res) => {
        setNotification(true);
        setMessage(
          "Вы успешно сменили номер телефона пользователя!"
        );
        setPhone(`+996111${randomNumber}`);
      })
      .catch((err) => {
        setNotification(true);
        setMessage(
          "Не удалось сменить номер телефона!"
        );
      });
  };

  const changePhoneState = () => {
    setEditPhone(!isEditPhone);
  };

  const downloadPdfDocument = (
    rootElementId: any
  ) => {
    const input = document.getElementById(
      rootElementId
    );
    html2canvas(input!).then((canvas) => {
      const imgData =
        canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps =
        pdf.getImageProperties(imgData);
      const pdfWidth =
        pdf.internal.pageSize.getWidth();
      const pdfHeight =
        (imgProps.height * pdfWidth) /
        imgProps.width;
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        pdfWidth,
        pdfHeight
      );
      pdf.save(`${passwordInfo.userName}.pdf`);
    });
  };

  const seeMore = () => {
    return window.open(
      `/client-view/${customerId}`
    );
  };

  return (
    <div className="personInfo" id="info">
      {sessionId && settings && (
        <>
          {clientInfo ? (
            <div className="personInfo__imgRow">
              <img
                src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${customerId}/photo/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(0)}
              />
              <img
                src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${customerId}/passportFront/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(1)}
              />
              <img
                src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${customerId}/passportBack/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(2)}
              />
            </div>
          ) : (
            <div className="personInfo__imgRow">
              <img
                src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${customerId}/photo/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(0)}
              />
              <img
                src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${customerId}/passportFront/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(1)}
              />
              <img
                src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${customerId}/passportBack/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(2)}
              />
              {/* <img src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${customerId}/userPhotoWithPassport/${settings.apiKey}`} alt={passwordInfo.userName} onClick={() => imageClickOpen(3)} /> */}
            </div>
          )}
        </>
      )}
      <div className="personInfoRow">
        <div className="personInfo__item">
          <div className="personInfo__label">
            Ф.И.О.
          </div>
          <div className="personInfo__textField">
            {passwordInfo.userName}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Персональный номер
          </div>
          <div className="personInfo__textField">
            {passwordInfo.userInn}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Номер паспорта
          </div>
          <div className="personInfo__textField">
            {passwordInfo.passportNumber}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Дата рождения
          </div>
          <div className="personInfo__textField">
            {passwordInfo.dateBirth}
          </div>
        </div>
        <div className="passwordData__item">
          <div className="passwordData__label">
            Пол
          </div>
          <div className="passwordData__textField">
            {(passwordInfo.userInn[0] === "1" &&
              "Женский") ||
              (passwordInfo.userInn[0] === "2" &&
                "Мужской")}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Дата выдачи паспорта
          </div>
          <div className="personInfo__textField">
            {passwordInfo.dateIssue}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Орган, выдавший паспорт
          </div>
          <div className="personInfo__textField">
            {passwordInfo.authority}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Дата окончания срока действия
          </div>
          <div className="personInfo__textField">
            {passwordInfo.dateExpiry}
          </div>
        </div>
        <div className="personInfo__item">
          <div className="personInfo__label">
            Номер телефона
          </div>
          {isEditPhone ? (
            <div className="personInfo__phone">
              <InputMask
                mask="+999 999 999 999"
                value={phone}
                className="personInfo__input"
                id="changePhone"
                onChange={(e: any) =>
                  setPhone(e.target.value)
                }
              />
              <button
                className="personInfo__phone-edit"
                onClick={() =>
                  changeClientPhone()
                }>
                {/* <SaveIcon /> */}
                Сохранить
              </button>
            </div>
          ) : (
            <div className="personInfo__phone">
              <InputMask
                mask="+999 999 999 999"
                value={phone}
                className="personInfo__input personInfo__input_nochange"
              />
              {getUserInfo?.roles ===
                "CompanyAdmin" && (
                <div style={{ display: "flex" }}>
                  <button
                    className="personInfo__phone-edit"
                    onClick={() =>
                      resetClientPhone()
                    }>
                    <RotateLeftIcon />
                    Сбросить
                  </button>
                  <button
                    className="personInfo__phone-edit"
                    onClick={() =>
                      changePhoneState()
                    }>
                    <EditIcon />
                    Изменить
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="passwordData__item">
          <div className="passwordData__label">
            Адрес прописки
          </div>
          <div className="passwordData__textField">
            {passwordInfo.registrationAddress}
          </div>
        </div>

        <div
          className="passwordData__item"
          style={{ fontWeight: "bold" }}>
          <div className="passwordData__label">
            Кодовое слово
          </div>
          {/*  @ts-ignore */}
          <div className="passwordData__textField">
            {passwordInfo.additionalInfo &&
              passwordInfo.additionalInfo
                .codeWord}
          </div>
        </div>
        {passwordInfo.additionalInfo && (
          <>
            <div className="passwordData__item">
              <div className="passwordData__label">
                Гражданство
              </div>
              {/*  @ts-ignore */}
              <div className="passwordData__textField">
                {
                  passwordInfo.additionalInfo
                    .nationality
                }
              </div>
            </div>
            <div className="passwordData__item">
              <div className="passwordData__label">
                ФИО на латинице
              </div>
              {/*  @ts-ignore */}
              <div className="passwordData__textField">
                {`${passwordInfo.additionalInfo.nameLat} ${passwordInfo.additionalInfo.surnameLat}`}
              </div>
            </div>
            {passwordInfo.residenceAddress && (
              <div className="passwordData__item">
                <div className="passwordData__label">
                  Фактическое место проживания
                </div>
                <div className="passwordData__textField">
                  {
                    passwordInfo.residenceAddress
                      .fullAddress
                  }
                </div>
              </div>
            )}
            <div className="passwordData__item">
              <div className="passwordData__label">
                Адрес электронной почты (e-mail)
              </div>
              <div className="passwordData__textField">
                {
                  passwordInfo.additionalInfo
                    .email
                }
              </div>
            </div>
            <div className="passwordData__item">
              <div className="passwordData__label">
                Семейное положение
              </div>
              <div className="passwordData__textField">
                {
                  passwordInfo.additionalInfo
                    .familyStatus
                }
              </div>
            </div>
            <div className="passwordData__item">
              <div className="passwordData__label">
                Место работы
              </div>
              <div className="passwordData__textField">
                {
                  passwordInfo.additionalInfo
                    .placeOfWork
                }
              </div>
            </div>
            <div className="passwordData__item">
              <div className="passwordData__label">
                Должность
              </div>
              <div className="passwordData__textField">
                {passwordInfo.additionalInfo.post}
              </div>
            </div>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <span style={{ fontSize: 14 }}>
                  Наличие бенефициарного
                  собственника
                </span>
              </FormLabel>
              <RadioGroup
                value={
                  passwordInfo.additionalInfo
                    .existenceOfBeneficialOwner
                }>
                <FormControlLabel
                  disabled={true}
                  name="ExistenceOfBeneficialOwnerYes"
                  control={
                    <Radio
                      size="small"
                      checked={
                        passwordInfo
                          .additionalInfo
                          .existenceOfBeneficialOwner
                      }
                    />
                  }
                  label="Да"
                />
                <FormControlLabel
                  disabled={true}
                  name="ExistenceOfBeneficialOwnerNo"
                  control={
                    <Radio
                      size="small"
                      checked={
                        !passwordInfo
                          .additionalInfo
                          .existenceOfBeneficialOwner
                      }
                    />
                  }
                  label="Нет"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <span style={{ fontSize: 14 }}>
                  Наличие гражданства/резидентства
                  США или Green
                </span>
                Card
              </FormLabel>
              <RadioGroup
                value={
                  passwordInfo.additionalInfo
                    .availabilityOfCitizenshipOrGreenCardUSA
                }>
                <FormControlLabel
                  disabled={true}
                  name="yes"
                  control={
                    <Radio
                      size="small"
                      checked={
                        passwordInfo
                          .additionalInfo
                          .availabilityOfCitizenshipOrGreenCardUSA
                      }
                    />
                  }
                  label="Да"
                />
                <FormControlLabel
                  disabled={true}
                  name="no"
                  control={
                    <Radio
                      size="small"
                      checked={
                        !passwordInfo
                          .additionalInfo
                          .availabilityOfCitizenshipOrGreenCardUSA
                      }
                    />
                  }
                  label="Нет"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <span style={{ fontSize: 14 }}>
                  Является ли Клиент ПДЛ
                </span>
              </FormLabel>
              <RadioGroup
                value={
                  passwordInfo.additionalInfo
                    .isPolitic
                }>
                <FormControlLabel
                  disabled={true}
                  value="isPoliticYes"
                  control={
                    <Radio
                      size="small"
                      checked={
                        passwordInfo
                          .additionalInfo
                          .isPolitic
                      }
                    />
                  }
                  label="Да"
                />
                <FormControlLabel
                  disabled={true}
                  value="isPoliticNo"
                  control={
                    <Radio
                      size="small"
                      checked={
                        passwordInfo
                          .additionalInfo
                          .isPolitic === false
                      }
                    />
                  }
                  label="Нет"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <span style={{ fontSize: 14 }}>
                  Является ли клиент Гос.
                  служащим?
                </span>
              </FormLabel>
              <RadioGroup
                value={
                  passwordInfo.additionalInfo
                    .stateEmployee
                }>
                <FormControlLabel
                  disabled={true}
                  value="isPoliticYes"
                  control={
                    <Radio
                      size="small"
                      checked={
                        passwordInfo
                          .additionalInfo
                          .stateEmployee
                      }
                    />
                  }
                  label="Да"
                />
                <FormControlLabel
                  disabled={true}
                  value="isPoliticNo"
                  control={
                    <Radio
                      size="small"
                      checked={
                        passwordInfo
                          .additionalInfo
                          .stateEmployee === false
                      }
                    />
                  }
                  label="Нет"
                />
              </RadioGroup>
            </FormControl>
            {pdf && (
              <div
                style={{
                  position: "absolute",
                  top: "-10000000000px",
                }}
                id="savePDF"
                dangerouslySetInnerHTML={{
                  __html: pdf,
                }}></div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <Button
                style={{ margin: "10px" }}
                color="primary"
                variant="outlined"
                onClick={() => seeMore()}>
                Карточка клиента
              </Button>
            </div>
            <SnackbarAlert
              notification={notification}
              setNotification={setNotification}
              message="Вы успешно сменили номер телефона пользователя!"
              severity="success"
              vertical="top"
              horizontal="center"
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  settings: state.admin.settings,
  getUserInfo: state.auth.getUserInfo,
});

export default connect(mapStateToProps)(
  PasswordData
);
