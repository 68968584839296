import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import "./PreviewDocument.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

const PreviewDocument = ({
  show,
  index,
  setIndex,
  send,
  preloader,
  toggleShow,
  html,
}) => {
  const showMarkup = () => {
    // eslint-disable-next-line no-useless-escape
    return {
      __html: html[index]
        ? html[index].documentTemplateHtml.replace(/[\[\]]/g, "")
        : `<div style="text-align: center">Данный документ пуст</div>`,
    };
  };
  return (
    <Dialog open={show} className="preview-document">
      <DialogContent>
        <h2>{html[index] && html[index].documentName} </h2>
        <div
          style={{
            fontFamily:
              "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
          }}
          dangerouslySetInnerHTML={showMarkup()}
        />
      </DialogContent>
      <DialogActions className="reduction__actions">
        <Button
          onClick={() => {
            if (!index) {
              toggleShow();
            } else {
              setIndex((prevState) => prevState - 1);
            }
          }}
          className="reduction__action-button">
          {!index ? "Отмена" : "Назад"}
        </Button>
        {html && (
          <Button
            onClick={() => {
              html.length - 1 === index
                ? send()
                : setIndex((prevState) => prevState + 1);
            }}
            disabled={preloader}
            className="reduction__action-button--green">
            {preloader ? (
              <CircularProgress size={24} />
            ) : html.length - 1 === index ? (
              "Отправить"
            ) : (
              "Далее"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDocument;
