import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AdminAPI } from '../../api/AdminAPI';
import Image from '../../img/obj.png'
import { RootState } from '../../store';
import {
    CircularProgress
} from '@material-ui/core';

type Props = ReturnType<typeof mapStateToProps> & {
    customerAccountId?: number
}

const ViewDocument: React.FC<Props> = ({sessionId, customerAccountId, contractSuccess}) => {
    const {requestId} = useParams<{ requestId?: string }>()
    const [videoLink, setVideoLink] = useState()
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        if(!!requestId){
            setLoading(true)
            AdminAPI.getClientRequestVideo(sessionId!, Number(requestId)).then(res => {
                setLoading(false)
                setVideoLink(res)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            }); 
         }
    },[])
    return (
        <div className="view-document-page">
            {}
            <p>Видеозапись не существует</p>
            <img src={Image} alt='video not found' />
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    contractSuccess: state.document.contractSuccess,
    getUserInfo: state.auth.getUserInfo
});

export default connect(mapStateToProps)(ViewDocument);