import React, { useState, Dispatch, useEffect } from "react";
import { connect } from "react-redux";
import { Action, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Grid, Button, TableBody } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
  DocumentIcon,
  DocumentEditIcon,
  BagIcon,
  BackDocumentArrowIcon,
  DocumentRemoveIcon,
  YeyesIcon,
} from "../../icons/icons";
import "./Services.scss";
import ReductionModal from "../ModalOfReductions/ModalOfReduction";
import { RootState } from "../../store";
import { getReductionActionSuccessActionCreator } from "../../store/Reduction/action";
import { Reduction } from "../../store/Reduction/types";
import { ReductionAPI } from "../../api/ReductionAPI";
import {
  addNameAndPath,
  addTags,
  addHtmlTemplate,
  changeOneDocument,
  getOneDocument,
  getCreatedDocuments,
  addOneDocumentToStore,
  clearOneDocument,
  removeOneDocumentAction,
} from "../../store/CreateDocument/action";
import {
  IDocument,
  PathAndName,
  IOneDocument,
} from "../../store/CreateDocument/types";
import CreateNewDocumentModal from "../CreateNewDocumentModal/CreateNewDocumentModal";
import CreateNewDocumentModalWithEditor from "../CreateNewDocumentModal/CreateNewDocumentWithEditor";
import CreateDocumentAPI from "../../api/CreateDocumentAPI";
import Box from "@material-ui/core/Box";
import { OrganizationAPI } from "../../api/OrganizationAPI";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { uuidv4 } from "../../utils/generateId";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Services: React.FC<Props> = ({
  settings,
  sessionId,
  getDataSuccess,
  removeOneDocumentAction,
  changeOneDocument,
  getOneDocument,
  clearOneDocument,
  addOneDocumentToStore,
  newDocument,
  addReduction,
  reductions,
  success,
  addNameAndPath,
  addHtmlTemplate,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(true);
  const [openCreateDocument, setOpenCreateDocument] = useState({
    first: false,
    second: false,
  });
  const [loading, setLoading] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState("");
  const [getRenderDoc, setRender] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState({
    documentTemplateId: 0,
    documentTemplateHtml: "",
    documentTags: [],
  });
  const [replaceDataDoc, setReplaceDataDoc] = useState([]);
  const [inputForEmptyTags, setInput] = useState<any>([]);
  const [departments, setDepartments] = useState([]);
  const [replaceDataDepartment, setReplaceDataDepartment] = useState([]);
  const [descriptionDoc, setDescriptionDoc] = useState("");
  // const [clientInfo, setUserInfo] = useState<any>({});
  const [previewDocument, setPreviewDocument] = useState(false);
  const [html, setHtml] = useState("");
  const [groupTag, setGroupTag] = useState<any>({});
  const [groupTagObj, setGroupObj] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const openClose = () => setOpen(!open);
  const [sortedReductions, setSortedReductions] = useState<any>([]);

  React.useEffect(() => {
    if (reductions.reduction && Boolean(reductions.reduction.length)) {
      setSortedReductions(
        reductions.reduction.sort((a, b) => {
          let tagA = a.tagKey.replace(/[^\w]/g, ""),
            tagB = b.tagKey.replace(/[^\w]/g, "");
          return tagA.charCodeAt(0) - tagB.charCodeAt(0);
        })
      );
    }
  });

  const openCloseCreateDocument = (obj: any) => {
    setOpenCreateDocument({
      ...openCreateDocument,
      ...obj,
    });
  };
  useEffect(() => {
    if (sessionId) {
      const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
      const newSessionId =
        settings && settings.name !== "Avcon" ? sessionId : key;
      OrganizationAPI.getOrganization(newSessionId).then((res) => {
        setDepartments(res);
        setReplaceDataDepartment(res[0]);
      });
    }
  }, [sessionId]);
  const getRequestOneDocument = (id: number) => {
    setIsNew(false);
    setLoading(true);
    if (sessionId) {
      const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
      const newSessionId =
        settings && settings.name !== "Avcon" ? sessionId : key;
      CreateDocumentAPI.getOneDocument(newSessionId, id)
        .then((res) => {
          getOneDocument(res);
          setLoading(false);
          openCloseCreateDocument({ second: true, first: false });
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function replaceAll(str: any, map: any, obj?: any) {
    for (let key in groupTagObj) {
      str = str.replace(key, groupTagObj[key]);
    }
    for (let { tagKey, tagValue } of map) {
      str = str.replaceAll(tagKey, tagValue);
    }
    if (obj) {
      for (let key in obj) {
        str = str.replaceAll(key, obj[key]);
      }
    }
    return str;
  }

  useEffect(() => {
    if (previewDocument) {
      setHtml(
        replaceAll(
          selectedDocument.documentTemplateHtml,
          replaceDataDoc,
          replaceDataDepartment
        )
      );
    }
  }, [
    replaceAll,
    selectedDocument.documentTemplateHtml,
    replaceDataDoc,
    replaceDataDepartment,
    previewDocument,
  ]);

  const groupArray = (array?: any) => {
    //@ts-ignore
    const data = array.reduce((doc: any, elem: any) => {
      if (!doc[elem.tagDescription]) {
        doc[elem.tagDescription] = [];
      }
      doc[elem.tagDescription].push(elem);
      return doc;
    }, {});
    setGroupTag(data);
  };
  const groupTags = Object.keys(groupTag).map((title: string) => {
    return {
      title,
      id: uuidv4(),
      tags: groupTag[title],
    };
  });
  useEffect(() => {
    const fetchData = async () => {
      if (sessionId) {
        try {
          const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
          const newSessionId =
            settings && settings.name !== "Avcon" ? sessionId : key;
          let tags = await OrganizationAPI.getTags(newSessionId);
          //@ts-ignore
          const countMap = new Map<string>();
          for (let key of selectedDocument.documentTags) {
            //@ts-ignore
            const count = countMap.get(key) ?? 0;
            //@ts-ignore
            countMap.set(key, count + 1);
          }
          //@ts-ignore
          let selectedTag = tags.filter(
            //@ts-ignore
            (t: any) => (countMap.get(t.tagKey) || 0) > 1
          );
          groupArray(selectedTag);
          //@ts-ignore
          const countTag = new Map<any>();
          for (let key of selectedDocument.documentTags) {
            //@ts-ignore
            const count = countTag.get(key) ?? 0;
            //@ts-ignore
            countTag.set(key, count + 1);
          }
          //@ts-ignore
          const tagArray = tags.filter(
            (tg: any) => countTag.get(tg.tagKey) || 0
          );
          setReplaceDataDoc(tagArray);
          const emptyArr = tagArray.filter((tg: any) => !tg.tagValue);
          setInput(emptyArr);
        } catch (e) {}
      }
    };
    fetchData();
  }, [selectedDocument, sessionId, showModal]);

  const removeDocument = (id: number) => () => {
    setIsNew(false);
    if (window.confirm("Вы уверены что хотите удалить ?")) {
      removeOneDocumentAction(id);
      if (sessionId) {
        const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
        const newSessionId =
          settings && settings.name !== "Avcon" ? sessionId : key;
        CreateDocumentAPI.removeDoc(newSessionId, id).then((res) => {
          if (res.success) {
            setRender(!getRenderDoc);
          }
        });
      }
    }
  };
  useEffect(() => {
    if (sessionId && documentId !== 0) {
      const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
      const newSessionId =
        settings && settings.name !== "Avcon" ? sessionId : key;
      OrganizationAPI.getDocumentTemplate(newSessionId, documentId)
        .then((res) => {
          setSelectedDocument(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [documentId, sessionId]);
  useEffect(() => {
    if (sessionId) {
      const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
      const newSessionId =
        settings && settings.name !== "Avcon" ? sessionId : key;
      ReductionAPI.getReductions(newSessionId)
        .then((res) => addReduction(res))
        .catch((e) => console.error(e));
      CreateDocumentAPI.getDocuments(newSessionId)
        .then((res) => {
          getDataSuccess(res);
        })
        .catch((e) => console.error(e));
    }
  }, [sessionId, success, getRenderDoc, addReduction, getDataSuccess]);

  const postData = (doc: any) => {
    if (sessionId) {
      const key = "AB85D9DA-7314-42C1-8A44-4F94F31127BC";
      const newSessionId =
        settings && settings.name !== "Avcon" ? sessionId : key;
      CreateDocumentAPI.postDocument(newSessionId, doc)
        .then(() => {
          CreateDocumentAPI.getDocuments(newSessionId)
            .then((res) => getDataSuccess(res))
            .catch((e) => console.error(e));
        })
        .catch((e) => console.error(e));
    }
  };
  const getItemClick = (elem: any, name: string) => () => {
    addOneDocumentToStore(elem);
    setBreadcrumbs(name);
  };
  const backToAllDocument = () => {
    clearOneDocument();
    setBreadcrumbs("");
  };

  const handleChangeTag = ({ target: { name: tagId, value } }: any) => {
    setGroupObj({ ...groupTagObj, [tagId]: value });
  };
  const checkEmptyIsArray = () => {
    if (inputForEmptyTags.length) return true;
    return false;
  };
  const togglePreviewOpen = () => {
    setPreviewDocument(true);
  };
  const togglePreviewClose = () => {
    setPreviewDocument(false);
  };
  const closeModal = () => {
    setShowModal(false);
    setReplaceDataDoc([]);
    setGroupObj({});
  };
  const handleChangeValue = (text: string, index: number) => {
    //@ts-ignore
    setReplaceDataDoc((prevState) =>
      [...prevState].map((item: any) => {
        if (index === item.documentTagId) {
          item.tagValue = !item.tagValue && !text ? " " : text;
        }
        return item;
      })
    );
  };
  const getInfoDoc = (id: number, docmentName: string) => () => {
    setDocumentId(id);
    setDescriptionDoc(docmentName);
    setShowModal(true);
  };
  const showMarkup = () => {
    // eslint-disable-next-line no-useless-escape
    return {
      __html: html
        ? html.replace(/[\[\]]/g, "")
        : `<div style="text-align: center">Данный документ пуст</div>`,
    };
  };
  const classes = useStyles();

  return (
    <>
      <div className="content" style={{ paddingLeft: "46px" }}>
        {loading && (
          <div className={`main-preloader fixed`}>
            <div className="pulse"></div>
          </div>
        )}
        <Grid container spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={"auto"}>
              <div className="documentBox" onClick={openClose}>
                <div className="document-display">
                  <DocumentIcon />
                  <span>Сокращения</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="services services-wrap">
          <Grid item xs={12} className="services__title-wrap">
            <Typography variant="h2" component="h2" className="title">
              Услуги
            </Typography>
            {!breadcrumbs ? (
              <Button
                className="services__add-button"
                onClick={() => {
                  setIsNew(true);
                  openCloseCreateDocument({ first: true });
                }}>
                Добавить
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {newDocument.oneDocuments.length > 0 && (
              <Box display="flex">
                <BackDocumentArrowIcon
                  style={{ margin: "0 10px", cursor: "pointer" }}
                  onClick={backToAllDocument}
                />
                <Typography color="textPrimary">{breadcrumbs}</Typography>
              </Box>
            )}
          </Grid>
          {newDocument.oneDocuments.length > 0
            ? newDocument.oneDocuments.map((item: IDocument, key: number) => (
                <div
                  key={key}
                  className="documentBox"
                  style={{ marginBottom: 20 }}>
                  <div className="documentBox__icons">
                    {/*<DocumentRemoveIcon/>*/}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={removeDocument(item.documentTemplateId)}>
                      <DocumentRemoveIcon />
                    </span>
                    <Box display="flex">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          getRequestOneDocument(item.documentTemplateId)
                        }>
                        <DocumentEditIcon />
                      </span>
                      <Box
                        onClick={getInfoDoc(
                          item.documentTemplateId,
                          item.documentName
                        )}
                        className="viewDoc">
                        <span>
                          <YeyesIcon />
                        </span>
                      </Box>
                    </Box>
                  </div>
                  <div className="document-display">
                    <DocumentIcon />
                    <span>{item.documentName}</span>
                  </div>
                </div>
              ))
            : Object.keys(newDocument.documents).map(
                (item: string, key: number) => {
                  return (
                    <Grid
                      key={key}
                      item
                      xs={3}
                      className="services__item"
                      onClick={getItemClick(
                        newDocument.documents[item as any],
                        item
                      )}>
                      <Typography
                        className="services__item-title"
                        component={"p"}
                        variant={"h6"}>
                        {item}
                      </Typography>
                      <div className="services__item-body">
                        <BagIcon className="services__item-icon" />
                        <span>
                          {newDocument.documents[item as any].length} документов
                        </span>
                      </div>
                    </Grid>
                  );
                }
              )}
        </Grid>
        <ReductionModal
          data={sortedReductions}
          open={open}
          openClose={openClose}
        />
        <CreateNewDocumentModal
          settings={settings}
          options={Object.keys(newDocument.documents)}
          addNameAndPath={addNameAndPath}
          open={openCreateDocument.first}
          setOpen={openCloseCreateDocument}
        />
        <CreateNewDocumentModalWithEditor
          values={newDocument.documentForEditing}
          postNew={isNew}
          postData={
            isNew
              ? () => postData(newDocument)
              : () => postData(newDocument.documentForEditing)
          }
          addHtmlTemplate={isNew ? addHtmlTemplate : changeOneDocument}
          open={openCreateDocument.second}
          setOpen={openCloseCreateDocument}
        />
      </div>
      <Modal
        className={classes.modal}
        open={showModal}
        onClose={closeModal}
        closeAfterTransition>
        <Fade in={showModal}>
          <div className="modal-paper">
            <h3>{descriptionDoc}</h3>
            {departments.length ? (
              <List className="document-list">
                <FormControl>
                  <h3>Выберите подразделение</h3>
                  <Select
                    value={replaceDataDepartment ? replaceDataDepartment : ""}
                    onChange={(e: any) =>
                      setReplaceDataDepartment(e.target.value)
                    }>
                    {departments.map((elem: any) => {
                      return (
                        <MenuItem key={elem.departmentId} value={elem}>
                          {elem.departmentName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </List>
            ) : null}
            <Box width={1}>
              {groupTags.length
                ? groupTags.map(({ tags, title, id }: any, i: number) => (
                    <div key={id}>
                      <div className="selectedName">{title}</div>
                      <Select
                        className="select-tag"
                        value={groupTagObj[tags[0].tagKey]}
                        name={tags[0].tagKey}
                        onChange={handleChangeTag}>
                        {tags.map(({ tagValue }: any) => (
                          <MenuItem value={tagValue}>{tagValue}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  ))
                : null}
            </Box>
            <Box>
              {checkEmptyIsArray() && <h3>Заполните значения</h3>}
              {inputForEmptyTags
                .sort((a: any, b: any) => {
                  let textA = a.tagKey.toUpperCase();
                  let textB = b.tagKey.toUpperCase();
                  return textA > textB ? -1 : textA < textB ? 1 : 0;
                })
                .map((tag: any) => {
                  return (
                    <Box
                      key={tag.documentTagId}
                      display="flex"
                      className="empty-tags__input">
                      <TextField
                        name={tag.tagKey}
                        label={tag.tagDescription.slice(0, 40)}
                        onChange={(e) =>
                          handleChangeValue(e.target.value, tag.documentTagId)
                        }
                      />
                    </Box>
                  );
                })}
            </Box>
            <Box mb="20px" className="doc-button-wrap">
              <Button
                className="green-button tags-modal"
                variant="contained"
                onClick={togglePreviewOpen}>
                Посмотреть
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Dialog open={previewDocument} className="preview-document">
        <DialogContent>
          <div dangerouslySetInnerHTML={showMarkup()} />
        </DialogContent>
        <DialogActions className="reduction__actions">
          <Button
            onClick={togglePreviewClose}
            className="reduction__action-button">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:focus": {
        outline: "none",
      },
    },
  })
);
const mapStateToProps = (store: RootState) => ({
  reductions: store.reduction,
  success: store.reduction.addReductionSuccess,
  sessionId: store.auth.sessionId,
  newDocument: store.createDocumentReducer,
  settings: store.admin.settings,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  addReduction: (reduction: Reduction[]) =>
    dispatch(getReductionActionSuccessActionCreator(reduction)),
  addNameAndPath: (obj: PathAndName) => dispatch(addNameAndPath(obj)),
  addTags: (arrayOfStrings: string[]) => dispatch(addTags(arrayOfStrings)),
  addHtmlTemplate: (str: string) => dispatch(addHtmlTemplate(str)),
  getDataSuccess: (obj: IDocument[]) => dispatch(getCreatedDocuments(obj)),
  addOneDocumentToStore: (arr: any) => dispatch(addOneDocumentToStore(arr)),
  clearOneDocument: () => dispatch(clearOneDocument()),
  getOneDocument: (obj: IOneDocument) => dispatch(getOneDocument(obj)),
  changeOneDocument: (str: string) => dispatch(changeOneDocument(str)),
  removeOneDocumentAction: (id: number) =>
    dispatch(removeOneDocumentAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
