import React from 'react';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {useHistory} from "react-router-dom"
const DocumentPanel = () => {
    const history = useHistory();
    return (
        <Grid container justify="space-between">
            <Grid item>

            </Grid>
            <Grid item>
                <Box mt="25px" mr="25px">
                    <Button variant="outlined" onClick={() => history.push('/add/document')} color="primary">Новый документ</Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DocumentPanel;
