import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { ModerationApi } from "../../api/ModerationApi";
import { useHistory } from "react-router-dom";
import { ActiveRequest } from "../../store/Moderation/types";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    openConfirm: boolean;
    confirmClickClose: () => void;
    text: string;
    buttonText: string;
    cinfirm: boolean;
    status?: string;
  };

const useStyles = makeStyles({
  container: {
    background: "red!important",
  },

  containerGreen: {
    background: "#31c85a !important",
  },

  label: {
    color: "white!important",
  },
});

const ConfirmModal: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    openConfirm,
    confirmClickClose,
    text,
    buttonText,
    cinfirm,
    sessionId,
    registerUserTextAction,
    activeRequestAction,
    activeRequest,
    requestId,
  } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [reason, setReason] = useState("");
  const registerUserClick = () => {
    setLoading(true);
    if (sessionId && requestId) {
      ModerationApi.registerUser(sessionId, parseInt(requestId))
        .then((data) => {
          if (data.success && activeRequest) {
            activeRequest.customerAccountId = data.userAccountId;
            activeRequestAction(activeRequest);
            setLoading(false);
            confirmClickClose();

            // return history.push('/');
          }
        })
        .catch(({ response }) => {
          setLoading(false);
          alert(response.data.message);
          return history.push("/");
        });
    }
  };

  const refuseUserRegistrationClick = () => {
    if (sessionId && requestId) {
      ModerationApi.refuseUserRegistration(
        sessionId,
        parseInt(requestId),
        reason
      )
        .then((data) => {
          if (data.success) {
            registerUserTextAction("Вы отклонили регистрацию");
            confirmClickClose();
            history.push("/");
            window.location.reload();
          }
        })
        .catch(({ response }) => console.error(response.data.message));
    }
  };

  return (
    <Dialog open={openConfirm} className="modal confirm">
      <DialogTitle>Заявка</DialogTitle>
      <DialogContent>
        <p className="modal__text">{text}</p>
        {!cinfirm && (
          <TextField
            label="Причина отклонения"
            value={reason}
            onChange={(event) => setReason(event.target.value)}
            multiline
            rows={4}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions className="modal__actions">
        <Button onClick={confirmClickClose} color="primary">
          Отмена
        </Button>
        <Button
          variant="contained"
          classes={
            !cinfirm
              ? { contained: classes.container, label: classes.label }
              : { contained: classes.containerGreen, label: classes.label }
          }
          disableElevation
          disabled={loading}
          onClick={cinfirm ? registerUserClick : refuseUserRegistrationClick}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            buttonText
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  activeRequest: state.moderation.activeRequest,
  requestId: state.moderation.requestId,
  settings: state.admin.settings,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  registerUserTextAction: (registerUserText: string) =>
    dispatch(actions.moderation.registerUserTextAction(registerUserText)),
  activeRequestAction: (activeRequest: ActiveRequest) =>
    dispatch(actions.moderation.activeRequestAction(activeRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
