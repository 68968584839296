import React, {useEffect, useMemo, useRef, useState} from "react";
import "./Editor.scss";
import JoditEditor from "jodit-react";
import {connect} from "react-redux";

const Editor = ({html,propsValue}) => {
    const [value, setValue] = useState('');
    let ref = useRef(null);

    useEffect(() => {
        if(propsValue) {
            if(document.querySelector('.jodit-placeholder')) {
                document.querySelector('.jodit-placeholder').style.display = 'none'
            }
            document.querySelector('.jodit-wysiwyg').innerHTML = propsValue.documentTemplateHtml
        }
    }, [propsValue]);

    const config = {
    language: "ru",
    enter: "P",
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    defaultActionOnPaste: "insert_only_text",
    buttons: "source, undo, redo, table, image, bold,italic,underline,eraser,ul,ol,indent,outdent,left,font,fontsize,paragraph,classSpan,brush, image, cut,copy"
    };
    
    const onBlur = () => {
        if (html) {
            html(value)
        }
    };
    
    return (
        <div onBlur={onBlur}>
            {useMemo(() => (
                <JoditEditor
                    ref={ref}
                    value={value}
                    config={config}
                    tabIndex={1} // tabIndex of textarea/ preferred to use only tion to update the content for performance reasons
                    onChange={temp => setValue(temp)}
                />
                // eslint-disable-next-line react-hooks/exhaustive-deps
            ), [])}
        </div>
    );
};

const mapStateToProps = (state) => ({
    sessionId: state.auth.sessionId,
});
export default connect(mapStateToProps)(Editor);
