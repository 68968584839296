import {
  ISearchHistory,
  IPasswordInfo,
} from "../store/Moderation/types";
import { InstanceHead } from "./InstanceHead";

export class ModerationApi {
  public static async getActiveRequests(
    sessionId: string
  ) {
    return await InstanceHead.instance
      .get(
        `Moderation/GetActiveRequests/${sessionId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async sendPdf(
    sessionId: string,
    requestId: number,
    pdf: any
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/RequestRegistrationForGuest/${sessionId}/${requestId}`,
        pdf
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async getMissingRequests(
    sessionId: string
  ) {
    return await InstanceHead.instance
      .get(
        `Moderation/GetMissingRequests/${sessionId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async startVideoCall(
    sessionId: string,
    requestId: number
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/StartVideoCall/${sessionId}/${requestId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async startVideoCallback(
    sessionId: string,
    requestId: number
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/StartVideoCallBack/${sessionId}/${requestId}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public static async getPassportInfo(
    sessionId: string,
    requestId: number
  ) {
    return await InstanceHead.instance
      .get(
        `Moderation/GetPassportInfo/${sessionId}/${requestId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async registerUser(
    sessionId: string,
    requestId: number
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/RegisterUser/${sessionId}/${requestId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async refuseUserRegistration(
    sessionId: string,
    requestId: number,
    moderationComment: string
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/RefuseUserRegistration/${sessionId}/${requestId}/${moderationComment}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async searchHistory(
    sessionId: string,
    history: ISearchHistory
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/SearchHistory/${sessionId}`,
        history
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async changePassportInfo(
    sessionId: string,
    requestId: number,
    passwordInfo: IPasswordInfo
  ) {
    console.log(
      passwordInfo.additionalInfo
        ?.actualPlaceOfResidence
    );

    for (let key in passwordInfo) {
      //@ts-ignore
      if (passwordInfo[key] === "yes") {
        //@ts-ignore
        passwordInfo[key] = true;
      }
      //@ts-ignore
      if (passwordInfo[key] === "no") {
        //@ts-ignore
        passwordInfo[key] = false;
      }
    }
    return await InstanceHead.instance
      .post(
        `Moderation/ChangePassportInfo/${sessionId}/${requestId}`,
        passwordInfo
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async redirectVideoCall(
    sessionId: string,
    requestId: number,
    departmentId: number
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/RedirectVideoCall/${sessionId}/${requestId}/${departmentId}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async requestRegistrationForGuest(
    sessionId: string,
    requestId: number,
    pdf?: any
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/RequestRegistrationForGuest/${sessionId}/${requestId}`,
        pdf
      )
      .then((res) => {
        return res.data;
      });
  }
  public static async verifyPdf(
    sessionId: string,
    requestId: number,
    pdf: any
  ) {
    return await InstanceHead.instance
      .post(
        `Moderation/VerifyUserData/${sessionId}/${requestId}`,
        pdf
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async checkInBlackList(
    sessionId: string,
    userName: any
  ) {
    return await InstanceHead.instance
      .get(
        `Moderation/CheckUserInBlackList/${sessionId}/${userName}`
      )
      .then((res) => {
        return res.data;
      });
  }

  public static async checkInBlackListBanks(
    sessionId: string,
    userName: any
  ) {
    return await InstanceHead.instance
      .get(
        `Moderation/CheckUserInBlackList/${sessionId}/${userName}`
      )
      .then((res) => {
        return res.data;
      });
  }
}
