import React, { Dispatch, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import AppStack from "../AppStack/AppStack";
import DocumentPreview from "../DocumentPreview";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect } from "react-redux";
import { actions, RootState } from "../../store";
import Axios from "axios";
import {
  InstanceCreditHead,
  InstanceDocumentHead,
  InstanceHead,
} from "../../api/InstanceHead";
import { SessionAPI } from "../../api/SessionAPI";
import { Action } from "typesafe-actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { GetUserInfo } from "../../store/Auth/types";
import { ISettings } from "../../store/Admin/types";
import platform from "platform";
import { Dialog, DialogTitle } from "@material-ui/core";
import "react-virtualized/styles.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
  },
  palette: {
    primary: { main: "#2290E0", contrastText: "#fff" },
  },
});

function App(props: any) {
  const {
    sessionId,
    getUserInfoAction,
    setLoaderAction,
    settingsAction,
    logoutAction,
  } = props;
  const [openBlocker, setOpenBlocker] = useState({
    open: false,
    browserName: "",
  });

  React.useLayoutEffect(() => {
    const settingsUrl = `/companiesSettings/${window.location.hostname}/settings.json`;
    const browserName = platform.name;

    if (browserName !== "Chrome") {
      setOpenBlocker({ open: true, browserName: browserName });
    }
    Axios.get(settingsUrl).then((res) => {
      settingsAction(res.data);
      InstanceDocumentHead.init(res.data.apiUrl);
      InstanceHead.init(res.data.apiKey);
      SessionAPI.init(res.data.apiKey);
      InstanceCreditHead.init(
        "https://creditbureau.onoi.kg/api/",
        res.data.apiKey
      );
      if (sessionId) {
        setLoaderAction(true);
        SessionAPI.getUserInfo(sessionId)
          .then((data) => {
            const getUserInfo = {
              fullName: data.fullName,
              inn: data.inn,
              innOrganisation: data.innOrganisation,
              roles: data.roles,
            };
            getUserInfoAction(getUserInfo, true);
            setLoaderAction(false);
          })
          .catch((response) => console.log(response));
      }
    });
  }, [
    settingsAction,
    sessionId,
    getUserInfoAction,
    setLoaderAction,
    logoutAction,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={openBlocker.open}>
        <DialogTitle style={{ textAlign: "center" }}>
          Внимание!
          <br /> Вам необходимо открыть этот сайт в браузере Chrome.
        </DialogTitle>
      </Dialog>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Switch>
          <Route path="/document-preview/:id" component={DocumentPreview} />
          <Route path="/" component={AppStack} />
        </Switch>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  isAuthorized: state.auth.isAuthorized,
  loading: state.auth.loading,
  getUserInfo: state.auth.getUserInfo,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  getUserInfoAction: (getUserInfo: GetUserInfo, isAuthorized: boolean) =>
    dispatch(actions.auth.getUserInfoAction(getUserInfo, isAuthorized)),
  setLoaderAction: (loading: boolean) =>
    dispatch(actions.auth.setLoaderAction(loading)),
  settingsAction: (settings: ISettings) =>
    dispatch(actions.admin.settingsAction(settings)),
  logoutAction: () => dispatch(actions.auth.logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
