// @ts-nocheck
import React, { Dispatch, useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { TextField, Button, InputAdornment, IconButton, Tooltip, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ClientAPI, IBankAccounts } from '../../api/ClientAPI';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import AddBankAccount from './AddBankAccount';
import DeleteIcon from '@material-ui/icons/Delete';
import SnackbarAlert from './SnackbarAlert';
import { DownloadIcon, EditOrganizationIcon } from '../../icons/icons';
import { CreditAPI } from '../../api/CreditAPI'
import { useParams } from 'react-router-dom'

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    customerAccountId: number
}

const BankAccounts:React.FC<Props> = (props) => {
    const { sessionId, customerAccountId, bankAccountSuccess, bankAccountSuccessAction } = props;
    const [edit, setEdit] = useState(false);
    const [bankAccounts, setBankAccounts] = useState<IBankAccounts[]>([]);
    const [bankAccountOpen, setBankAccountOpen] = useState(false);
    const [notification, setNotification] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null)
    const [errorMessage, setErrorMessage] = useState({
        error: false,
        message: '',
    });
    const [success, setSuccess] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    const [clientAccountId, setClientAccountId] = useState(null)
    const [userReports, setUserReports] = useState(null)
    const [creditReportLoading, setCreditReportLoading] = useState(false)
    const [salaryReportLoading, setSalaryReportLoading] = useState(false)
    const [getCustomerError, setGetCustomerError] = useState(false)

    useEffect(() => {
        setGetCustomerError(false)

        CreditAPI
            .getSmartIdCustomer(sessionId, customerAccountId)
            .then(res => {
                if (res) {
                    setClientAccountId(res.customerId)
                    return CreditAPI.getUserReports(sessionId, res.customerId)
                }

                return new Error('getUserReports error')
            })
            .then(setUserReports)
            .catch(() => setGetCustomerError(true))
    }, [])

    const requestReport = reportType => () => {
        if (reportType === 'Ishenim' && !window.confirm('Запросы в КИБ "Ишеним" платные')) return

        const setLoading = loadingStatus => 
            reportType === 'Ishenim' ? 
                setCreditReportLoading(loadingStatus) :
                setSalaryReportLoading(loadingStatus)
        
        setLoading(true)

        CreditAPI
            .requestReport(sessionId, clientAccountId, reportType)
            .then(res => {
                if (res.resultType === 'Success')
                    return CreditAPI.getUserReports(sessionId, clientAccountId)
                
                throw new Error('Что-то пошло не так при загрузке отчета, попробуйте позже')
            })
            .then(setUserReports)
            .catch(() => console.log('Данные не найдены'))
            .finally(() => setLoading(false))
    }

    const getCreditReportPdf = event => {
        event.preventDefault()
        window.open(`https://creditbureau.onoi.kg/api/Pdf/GetIshenimReportPdf/?sessionId=${sessionId}&customerId=${clientAccountId}`, '_blank')
    }

    const getSalaryReportPdf = event => {
        event.preventDefault()
        window.open(`https://creditbureau.onoi.kg/api/Pdf/GetSettlementsServiceReportPdf?sessionId=${sessionId}&customerId=${clientAccountId}`, '_blank')
    }

        


    // @ts-ignore
    const editAccount = account => {
        setShowEditModal(true)
        setCurrentAccount(account)
    }

    const catchError = (message: string) => {
        setNotification(true);
        setErrorMessage({error: true, message});
    }

    const bankAccountClickOpen = () => {
        setBankAccountOpen(true);
    }
    const bankAccountClickClose = () => {
        setBankAccountOpen(false);
    }

    useEffect(() => {
        if(sessionId) {
            ClientAPI.getBankAccounts(sessionId, customerAccountId).then(data => {
                setBankAccounts(data);
            }).catch(({response}) => console.error(response.data.message));
        }
    }, [sessionId, customerAccountId, success]);

    const bankAccountChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, bankAccount: IBankAccounts) => {
        const idx = bankAccounts.findIndex((item) => item.accountValue === bankAccount.accountValue);
        const oldItem = bankAccounts[idx];
        const newItem = {...oldItem, accountValue: event.target.value};
        const newBankAccounts = [
            ...bankAccounts.slice(0, idx),
            newItem,
            ...bankAccounts.slice(idx + 1)
        ];
        setBankAccounts(newBankAccounts);
    }

    const clearClick = (accountValue: string) => {
        const idx = bankAccounts.findIndex((item) => item.accountValue === accountValue);
        const oldItem = bankAccounts[idx];
        const newItem = {...oldItem, accountValue: ""};
        const newBankAccounts = [
            ...bankAccounts.slice(0, idx),
            newItem,
            ...bankAccounts.slice(idx + 1)
        ];
        setBankAccounts(newBankAccounts);
    }

    const deleteClick = (accountValue: string, event: any) => {
        event.stopPropagation()

        const deleteConfirm = window.confirm('Удалить?')

        if (!deleteConfirm) return

        const idx = bankAccounts.findIndex((item) => item.accountValue === accountValue);
        const newBankAccounts = [
            ...bankAccounts.slice(0, idx),
            ...bankAccounts.slice(idx + 1)
        ];
        setBankAccounts(newBankAccounts);
        bankAccountSave(newBankAccounts)
    }

    const cencelClick = () => {
        forceUpdate();
        setEdit(false);
    }

    const bankAccountSave = (accounts: any) => {
        if(sessionId) {
            if (currentAccount) {
                // @ts-ignore
                ClientAPI.setBankAccounts(sessionId, customerAccountId, [currentAccount]).then(data => {
                    console.log(data)
                    if (data.success === true) {
                        bankAccountSuccessAction(!bankAccountSuccess);
                        setNotification(true);
                        bankAccountClickClose();
                        setErrorMessage({ error: false, message: 'Вы успешно отредактировали банковский счет!' });
                    }
                }).catch(({ response }) => catchError(response.data.message));
            } else {
                ClientAPI.setBankAccounts(sessionId, customerAccountId, accounts ? accounts : bankAccounts).then(data => {
                    console.log(data)
                    if (data.success === true) {
                        bankAccountSuccessAction(!bankAccountSuccess);
                        setNotification(true);
                        bankAccountClickClose();
                        setErrorMessage({ error: false, message: 'Вы успешно отредактировали банковский счет!' });
                    }
                }).catch(({ response }) => catchError(response.data.message));
            }
        }
    }

    const reRender = () => {
        setSuccess(!success)
    }
     return (
         <div className="bankData">
             <div className="bankData__heading">
                <h4 className="bankData__title">Банковские счета</h4>
                <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    startIcon={<AddIcon />}
                    onClick={bankAccountClickOpen}
                    className="bankData__add"
                >
                    Добавить cчет
                </Button>
             </div>
            {bankAccounts.map(bankAccount => (
                <TextField
                    onClick={() => editAccount(bankAccount)}
                    key={'textField' + bankAccount.accountValue}
                    disabled={!edit}
                    label={bankAccount.accountType}
                    value={bankAccount.accountValue}
                    onChange={(event) => bankAccountChange(event, bankAccount)}
                    fullWidth
                    InputProps={{
                        endAdornment:
                        <InputAdornment position="start">
                            <Tooltip title="Редактировать" placement="top">
                                <IconButton><EditOrganizationIcon color="green" /></IconButton>
                            </Tooltip>
                            <Tooltip title="Удалить" placement="top">
                                <IconButton onClick={event => deleteClick(bankAccount.accountValue, event)}><DeleteIcon /></IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }}/>
            ))}


             <div className="credit-history">
                 <div className="bankData__heading">
                    <h4 className="bankData__title">Кредитная история</h4>
                    <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        className="bankData__add"
                        onClick={requestReport('Ishenim')}
                    >
                        Запросить
                    </Button>
                </div>

                <ul className="credit-history__list">
                    {
                        creditReportLoading ?
                            'Загрузка...' :

                            getCustomerError ?
                                <li>Данные не найдены</li> :
                                
                                userReports ?
                                    userReports.ishenimReport ?
                                        <li>
                                            <span>Запрос от: {new Date(userReports.ishenimReport.requestDate).toLocaleString()}</span>
                                            <a href="/getCreditReportPdf" onClick={getCreditReportPdf}><DownloadIcon /> Скачать</a>
                                        </li> :
                                    <li>Данные не найдены</li> :

                                <li>Не запрашивалось</li>
                    }
                </ul>
             </div>

             <div className="credit-history credit-history--last">
                 <div className="bankData__heading">
                    <h4 className="bankData__title">Информация о заработной плате</h4>
                    <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        className="bankData__add"
                        onClick={requestReport('SettlementsService')}
                    >
                        Запросить
                    </Button>
                </div>

                <ul className="credit-history__list">
                    {
                        salaryReportLoading ?
                            'Загрузка...' :

                            getCustomerError ?
                                <li>Данные не найдены</li> :
                                
                                userReports ?
                                    userReports.settlementsServiceReport ?
                                        <li>
                                            <span>Запрос от: {new Date(userReports.settlementsServiceReport.requestDate).toLocaleString()}</span>
                                            <a href="/getSalaryReportPdf" onClick={getSalaryReportPdf}><DownloadIcon /> Скачать</a>
                                        </li> :
                                    <li>Данные не найдены</li> :

                                <li>Не запрашивалось</li>
                    }
                </ul>
             </div>

            <div className="bankData__row">
                {edit ?
                    <>
                        <Button onClick={cencelClick} color="primary">Отмена</Button>
                        <Button
                            variant="contained"
                            color="primary" disableElevation
                            startIcon={<DoneIcon />}
                            onClick={bankAccountSave}>
                            Сохранить
                        </Button>
                    </> :
                    <>
              
                        {/* <Button
                            variant="contained"
                            color="primary" disableElevation
                            startIcon={<EditIcon />}
                            onClick={() => setEdit(true)}>
                            Редактировать</Button> */}
                    </>
                }
            </div>
            <AddBankAccount
                bankAccountOpen={bankAccountOpen}
                bankAccountClickClose={bankAccountClickClose}
                customerAccountId={customerAccountId}
                reRender={reRender}
                bankAccounts={bankAccounts} />
            <SnackbarAlert
                notification={notification}
                setNotification={setNotification}
                message={errorMessage.message}
                severity={errorMessage.error ? "error" : "success"}
                vertical="top"
                horizontal="center" />

            <Dialog open={showEditModal} onClose={() => {
                setShowEditModal(false)
                setCurrentAccount(null)
            }}>
                {currentAccount ? 
                <>
                 <DialogContent>
                    <TextField
                        label="Наименование банка"
                         // @ts-ignore
                        value={currentAccount.bankName}
                         // @ts-ignore
                        onChange={(event) => setCurrentAccount({...currentAccount, bankName: event.target.value})}
                        fullWidth />
                     <TextField
                         label="Наименование счета"
                         // @ts-ignore
                         value={currentAccount.accountType}
                         // @ts-ignore
                         onChange={(event) => setCurrentAccount({ ...currentAccount, accountType: event.target.value })}
                         fullWidth />
                     <TextField
                         label="Номер счета"
                         // @ts-ignore
                         value={currentAccount.accountValue}
                         // @ts-ignore
                         onChange={(event) => setCurrentAccount({ ...currentAccount, accountValue: event.target.value })} />
                    <TextField
                        label="БИК Банка"
                         // @ts-ignore
                        value={currentAccount.bik}
                         // @ts-ignore
                        onChange={(event) => setCurrentAccount({...currentAccount, bik: event.target.value})}
                        fullWidth />
                    <TextField
                        label="Swift"
                         // @ts-ignore
                        value={currentAccount.swift}
                         // @ts-ignore
                        onChange={(event) => setCurrentAccount({...currentAccount, swift: event.target.value})}
                        fullWidth />
                    <TextField
                        label="Корр. счет Банка в ин. валюте"
                         // @ts-ignore
                        value={currentAccount.korrAccount}
                         // @ts-ignore
                        onChange={(event) => setCurrentAccount({...currentAccount, korrAccount: event.target.value})}
                        fullWidth />
                    <TextField
                        label="Дополнительные реквизиты"
                         // @ts-ignore
                        value={currentAccount.addinitionalReqs}
                         // @ts-ignore
                        onChange={(event) => setCurrentAccount({...currentAccount, addinitionalReqs: event.target.value})}
                        fullWidth />                        
                 </DialogContent>
                <DialogActions className="modal__actions">
                    <Button onClick={() => {
                        setShowEditModal(false)
                        setCurrentAccount(null)
                     }} color="primary">Отмена</Button>
                    <Button
                        onClick={bankAccountSave} variant="contained"
                        color="primary" disableElevation>Сохранить</Button>
                </DialogActions>
                </>
                : null}
             </Dialog>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    bankAccountSuccess: state.admin.bankAccountSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setLoaderAction: (loading: boolean) => dispatch(actions.auth.setLoaderAction(loading)),
    bankAccountSuccessAction: (bankAccountSuccess: boolean) => dispatch(actions.admin.bankAccountSuccessAction(bankAccountSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
