import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { ClientDocumentsAPI } from "../../api/ClientDocumentsAPI";
import DateRange from "../bricks/DateRange";
import { DocumentState } from "../../store/Moderation/types";
import Moment from "react-moment";
import Box from "@material-ui/core/Box";
import ContractModal from "../bricks/ContractModal";
import PaymentOrderView from "../bricks/PaymentOrderView";
import NativeSelect from "@material-ui/core/NativeSelect";
import LinearProgress from "@material-ui/core/LinearProgress";
import jwt_decode from "jwt-decode";
import { OrganizationAPI } from "../../api/OrganizationAPI";
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
} from "react-virtualized";
import { makeStyles } from "@material-ui/styles";

type Props = ReturnType<typeof mapStateToProps>;

// In this example, average cell height is assumed to be about 50px.
// This value will be used for the initial `Grid` layout.
// Width is not dynamic.
const cache = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true,
});

const DocumentTable: React.FC<Props> = ({
  sessionId,
  startDate,
  endDate,
  appSettings,
}) => {
  const classes = useStyles();
  const tableContainer = React.createRef<HTMLDivElement>();
  const [tableWidth, setTableWidth] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState<any>(null);
  const [paymentOrder, setPaymentOrder] = useState<any>();
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [contractOpen, setContractOpen] = useState(false);
  const [contract, setContract] = useState("");
  const [requestDoc, setRequestDoc] = useState(false);
  const [filterDocument, setFilterDocument] = useState("Все");
  const [decode, setDecode] = useState({});
  const [departmentId, setDepartmentId] = useState(null);
  const [departmentInfo, setDepartmentInfo] = useState({});
  const [successDoc, setSuccessDoc] = useState(false);
  const [search, setSearch] = useState("");

  React.useEffect(() => {
    if (!loading && tableContainer.current) {
      setTableWidth(tableContainer.current.offsetWidth);
    }
  }, [loading, tableContainer]);
  useEffect(() => {
    if (sessionId) {
      setLoading(true);
      if (startDate && endDate) {
        let filter = filterDocument === "Все" ? "" : filterDocument;

        appSettings && appSettings.name !== "Avcon"
          ? ClientDocumentsAPI.getDocumentForAdmin(
              sessionId,
              null,
              "Signed",
              filter,
              startDate,
              endDate
            )
              .then((data) => {
                setDocuments(data.reverse());
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
              })
          : // Нужно передавать key!!! изменить как бэк будет готов
            ClientDocumentsAPI.getDocumentForAvconAdmin(
              null,
              "Signed",
              filter,
              startDate,
              endDate
            )
              .then((data) => {
                setDocuments(data.reverse());
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
              });
      }
    }
  }, [endDate, startDate, sessionId, filterDocument, successDoc, appSettings]);
  useEffect(() => {
    if (sessionId) {
      setRequestDoc(true);
      if (doc) {
        //@ts-ignore
        ClientDocumentsAPI.getDocument(sessionId, doc.documentId).then(
          (res) => {
            setDepartmentId(res.departmentId);
            res.documentSignature &&
              setDecode(jwt_decode(res.documentSignature));
            //@ts-ignore
            doc.documentType === "PaymentOrder"
              ? setPaymentOrder(res)
              : setContract(res.documentHtml);
            setRequestDoc(false);
          }
        );
      }
    }
  }, [sessionId, doc]);
  useEffect(() => {
    if (sessionId) {
      //@ts-ignore
      if (departmentId) {
        OrganizationAPI.getDepartmentDetails(sessionId, departmentId).then(
          (res) => {
            setDepartmentInfo(res);
          }
        );
      }
    }
  }, [sessionId, departmentId]);
  const getDocument = (document: any) => () => {
    setDoc(document);
    if (document.documentType === "PaymentOrder") {
      return setPaymentOpen(true);
    }
    setContractOpen(true);
  };
  const contractClickClose = () => {
    setContractOpen(false);
  };
  const paymentClickClose = () => {
    setPaymentOpen(false);
  };
  const handleChangeFilter = (e: any) => {
    setFilterDocument(e.target.value);
  };
  const filter = documents.filter(
    (x: any) =>
      (x.userName && x.userName.toLowerCase().includes(search.toLowerCase())) ||
      (x.employeeName &&
        x.employeeName.toLowerCase().includes(search.toLowerCase()))
  );
  const cellLabelStyles = {
    lineHeight: 1.43,
    color: "#000000de",
    fontSize: 16,
    fontWeight: 400,
    padding: 16,
  };
  const rowRenderer = (props: ListRowProps) => {
    let { index, key, parent, style } = props,
      doc: any = filter[index];
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}>
        <Grid
          item
          xs={12}
          container
          style={{
            ...style,
          }}
          className={classes.rowItem}
          onClick={getDocument(doc)}>
          <Grid item xs={1} style={cellLabelStyles}>
            {index + 1}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              ...cellLabelStyles,
              color: doc.executionStatus === "New" ? "red" : "green",
            }}>
            <Box display="flex" className="date-document-table">
              <span>
                <Moment format="DD.MM.YYYY">{doc.createdDate}</Moment>
              </span>
              <span>
                <Moment format="hh:mm:ss">{doc.createdDate}</Moment>
              </span>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              ...cellLabelStyles,
              color: doc.executionStatus === "New" ? "red" : "green",
            }}>
            {doc.employeeName}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              ...cellLabelStyles,
              color: doc.executionStatus === "New" ? "red" : "green",
            }}>
            {doc.userName}
          </Grid>
          <Grid xs={3} item style={cellLabelStyles}>
            <Box className="tableLinkBlue pointer">
              {doc.documentDescription ||
                (doc.documentType === "PaymentOrder" &&
                  !doc.documentDescription &&
                  "Платежное поручение")}
            </Box>
          </Grid>
        </Grid>
      </CellMeasurer>
    );
  };
  const headLabelStyles = {
    fontSize: 14,
    fontWeight: 600,
    color: "#9296a1",
    padding: "8px 16px",
  };
  return (
    <div className="mainContent">
      <div className="topRow">
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <div className="title">Документы</div>
          </Grid>
          <Grid item>
            <TextField
              placeholder="Поиск"
              onChange={({ target: { value } }: any) => setSearch(value)}
            />
          </Grid>
          <Grid item>
            <NativeSelect value={filterDocument} onChange={handleChangeFilter}>
              <option value="Все">Все</option>
              <option value="PaymentOrder">Платёжные поручения</option>
              <option value="DocumentByTemplate">Документы</option>
            </NativeSelect>
          </Grid>
          <Grid item>
            <DateRange />
          </Grid>
        </Grid>
      </div>
      <div
        className="DocumentTable"
        ref={tableContainer}
        style={{ width: "100%" }}>
        <div className="tableContainer" style={{ overflow: "hidden" }}>
          <Grid
            item
            container
            xs={12}
            style={{
              paddingRight: 20,
              backgroundColor: "#f9fafc",
              borderBottom: "1px solid #e0e0e0",
            }}>
            <Grid item xs={1} style={headLabelStyles}>
              №
            </Grid>
            <Grid item xs={2} style={headLabelStyles}>
              Дата и время
            </Grid>
            <Grid item xs={3} style={headLabelStyles}>
              Сотрудник
            </Grid>
            <Grid item xs={3} style={headLabelStyles}>
              Клиент
            </Grid>
            <Grid item xs={3} style={headLabelStyles}>
              Документ
            </Grid>
          </Grid>
          {!loading && tableWidth ? (
            <List
              width={tableWidth}
              height={window.innerHeight}
              rowCount={filter.length}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRenderer}
            />
          ) : (
            <LinearProgress />
          )}
        </div>
        <div className="justify-center">
          {/*{foundCustomers > 9 &&*/}
          {/*<Pagination count={currentCount} page={page} onChange={paginationChange} shape="rounded" className="paginations" />*/}
          {/*}*/}
        </div>
      </div>
      <ContractModal
        successDoc={successDoc}
        setSuccess={setSuccessDoc}
        departmentInfo={departmentInfo}
        decode={decode}
        doc={doc}
        request={requestDoc}
        contractOpen={contractOpen}
        contractClickClose={contractClickClose}
        documentId={contract}
      />
      {paymentOrder && (
        <PaymentOrderView
          departmentInfo={departmentInfo}
          decode={decode}
          doc={doc}
          request={requestDoc}
          paymentOpen={paymentOpen}
          paymentClickClose={paymentClickClose}
          paymentOrder={paymentOrder}
          customerAccountId={doc.userAccountId}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  rowItem: {
    borderBottom: "1px solid #e0e0e0",
    backgroundColor: "#FFF",
    cursor: "pointer",
    "&:hover": { backgroundColor: "#F1F6F8" },
  },
}));

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  startDate: state.moderation.startDate,
  endDate: state.moderation.endDate,
  appSettings: state.admin.settings,
});
export default connect(mapStateToProps)(DocumentTable);
