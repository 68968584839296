import { InstanceDocumentHead } from "./InstanceHead";

export const OrganizationAPI = {
  async setOrganization(
    sessionId: string,
    organizationName: string,
    organizationInn: string,
    organizationAddress: string,
    bank: string,
    bik: string,
    paymentAccount: string
  ) {
    return await InstanceDocumentHead.instance
      .post(`/references/SetOrganizationInfo/${sessionId}`, {
        organizationName,
        organizationInn,
        organizationAddress,
        bank,
        bik,
        paymentAccount,
      })
      .then((res) => {
        return res.data;
      });
  },
  async setDepartment(
    sessionId: string,
    departmentName: string,
    departmentHeadFullName: string,
    departmentHeadPosition: string,
    departmentAppointment: string,
    departmentAddress: string,
    departmentBIK: string,
    departmentINN: string,
    departmentOKPO: string,
    departmentPhone: string,
    departmentDateAppointment: string,
    departmentHeadAssignedByWhom: string,
    departmentId: number,
    departmentPaymentAccount: string,
    departmentBank: string,
    filialCode: string,
    sberkassa: string,
    swift: string
  ) {
    return await InstanceDocumentHead.instance
      .post(`/references/SetDepartment/${sessionId}`, {
        departmentName,
        departmentHeadFullName,
        departmentHeadPosition,
        departmentAppointment,
        departmentAddress,
        departmentBIK,
        departmentINN,
        departmentOKPO,
        departmentPhone,
        departmentDateAppointment,
        departmentHeadAssignedByWhom,
        departmentId,
        departmentPaymentAccount,
        departmentBank,
        filialCode,
        sberkassa,
        swift,
      })
      .then((res) => {
        return res.data;
      });
  },
  async getOrganization(sessionId: string) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetDepartments/${sessionId}`)
      .then((res) => {
        return res.data;
      });
  },
  async getOrganizationInfo(sessionId: string) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetOrganizationInfo/${sessionId}`)
      .then((res) => {
        return res.data;
      });
  },
  async deleteDepartments(sessionId: string, departmentId: number) {
    return await InstanceDocumentHead.instance
      .post(`/references/DeleteDepartment/${sessionId}/${departmentId}`)
      .then((res) => {
        return res.data;
      });
  },

  async getDocumentShortTemplate(sessionId: string) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetDocumentShortTemplates/${sessionId}`)
      .then((res) => {
        return res.data;
      });
  },
  async getDepartmentDetails(sessionId: string, departmentId: number | null) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetDepartment/${sessionId}/${departmentId}`)
      .then((res) => {
        return res.data;
      });
  },
  async getTags(sessionId: string) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetTags/${sessionId}`)
      .then((res) => {
        return res.data;
      });
  },

  async getDocumentTemplate(sessionId: string, documentTemplateId: number) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetDocumentTemplate/${sessionId}/${documentTemplateId}`)
      .then((res) => {
        return res.data;
      });
  },
  async getDocumentAvconTemplate(key: string, documentTemplateId: number) {
    return await InstanceDocumentHead.instance
      .get(`/references/GetDocumentTemplate/${key}/${documentTemplateId}`)
      .then((res) => {
        return res.data;
      });
  },
  async addDocument(
    requestId: number,
    sessionId: string,
    userAccountId: number,
    documentDescription: string,
    documentTemplateId: any,
    documentHtml: string,
    departmentId: number,
    childDocuments: any,
    useQualifiedSignature: boolean,
    addWordDocument: boolean
  ) {
    // eslint-disable-next-line no-useless-escape
    documentHtml = documentHtml.replace(/[\[\]]/g, "");
    return addWordDocument
      ? await InstanceDocumentHead.instance
          .post(`/documents/AddWordDocument/${sessionId}`, {
            userAccountId,
            documentDescription,
            wordDocumentBase64: documentTemplateId,
            departmentId,
            requestId,
            useQualifiedSignature,
            // eslint-disable-next-line no-useless-escape
            childDocuments: childDocuments,
          })
          .then((res) => {
            return res.data;
          })
      : await InstanceDocumentHead.instance
          .post(`/documents/AddDocument/${sessionId}`, {
            requestId,
            userAccountId,
            documentDescription,
            documentTemplateId,
            documentHtml,
            departmentId,
            useQualifiedSignature,
            // eslint-disable-next-line no-useless-escape
            childDocuments: childDocuments
              .filter((x: any) => x.documentTemplateId !== documentTemplateId)
              .map((x: any) => ({
                ...x,
                documentDescription: x.documentName,
                documentHtml: x.documentTemplateHtml.replace(/[\[\]]/g, ""),
              })),
          })
          .then((res) => {
            return res.data;
          });
  },
};
