import React from 'react'
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
type Props = {
  search: string
  setSearch: any
  placeholder?: string
}
const Search: React.FC<Props> = ({
  search,
  setSearch,
  placeholder = 'Поиск',
}) => {
  return (
    <FormControl variant="outlined" fullWidth size="small" className="search">
      <OutlinedInput
        placeholder={placeholder}
        value={search}
        onChange={e => setSearch(e.target.value)}
        style={{
          borderRadius: 4,
        }}
        endAdornment={
          <InputAdornment position="end">
            {search ? (
              <IconButton
                edge="end"
                onClick={() => {
                  console.log('CLICK')
                  setSearch('')
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton edge="end" onClick={() => setSearch('')}>
                <SearchIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default Search
