import React, {
  Dispatch,
  useState,
  useEffect,
  useRef,
} from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Icon,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
  MenuItem,
} from "@material-ui/core";
import {
  BackIcon,
  DeleteIcon,
  PrevIcon,
  SuccessIcon,
  WarningIcon,
} from "../../icons/icons";
import ConfirmModal from "../bricks/ConfirmModal";
import ImageModal from "../bricks/ImageModal";
import { useHistory } from "react-router-dom";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { ModerationApi } from "../../api/ModerationApi";
import { baseUrl } from "../../utils/baseUrl";
import { ClientAPI } from "../../api/ClientAPI";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DocumentModal from "../bricks/DocumentModal";
import {
  ShareIcon,
  DocIcon,
  DataIcon,
} from "../../icons/icons";
import Axios from "axios";
import { IDepartment } from "../../api/AdminAPI";
import { InstanceHead } from "../../api/InstanceHead";
import { icons } from "../../utils/icons";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import {
  IPasswordInfo,
  MissingRequest,
  ActiveRequest,
} from "../../store/Moderation/types";
import PasswordData from "../bricks/PasswordData";
import BankAccounts from "../bricks/BankAccounts";
import CallTransferDialog from "../bricks/CallTransferDialog";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ClientRegistration from "../bricks/ClientRegistration";
import { StepRegistration } from "../../store/Admin/types";
import { sessionStorageRemoveItem } from "../../utils/storage";
import ProcessDialog from "../bricks/ProcessDialog";
import SnackbarAlert from "../bricks/SnackbarAlert";

type Props = ReturnType<
  typeof mapDispatchToProps
> &
  ReturnType<typeof mapStateToProps>;

const VideoCall: React.FC<Props> = (props) => {
  const {
    sessionId,
    conferenceJoinLink,
    activeRequest,
    registerUserText,
    settings,
    stepRegistrationAction,
    stepRegistration,
    missingRequest,
    requestId,
    missingRequestAction,
    activeRequestAction,
    registerUserTextAction,
  } = props;

  let history = useHistory();
  const [openConfirm, setOpenConfirm] =
    useState(false);
  const [openReject, setOpenReject] =
    useState(false);
  const [openImage, setOpenImage] =
    useState(false);
  const [passwordInfo, setPasswordInfo] =
    useState<IPasswordInfo | null>(null);
  const [tabValue, setTabValue] =
    React.useState(0);
  const [modalOpen, setModalOpen] = useState({
    password: false,
    document: false,
  });
  const heightEl = useRef<HTMLDivElement | null>(
    null
  );
  const redirectList =
    useRef<HTMLDivElement | null>(null);
  const [fixedHeight, setFixedHeight] =
    useState<number>(0);
  const [
    redirectListHeight,
    setRedirectListHeight,
  ] = useState<number>(0);
  const [departments, setDepartments] = useState<
    IDepartment[]
  >([]);
  const [disabled, setDisabled] = useState(true);
  const [callOpen, setCallOpen] = useState(false);
  const [department, setDepartment] =
    useState<IDepartment | null>(null);
  const [clientRegOpen, setclientRegOpen] =
    useState(false);
  const [processOpen, setProcessOpen] =
    useState(false);
  const [idxImg, setIdxImg] = useState<number>(0);
  const [status, setStatus] = useState("");
  const [idUser, setIdUser] = useState(0);
  const [pdf, setPdf] = useState<any>("");
  const [checkRequest, setCheckRequest] =
    useState(false);
  const [
    userInfoForDocument,
    setUserInfoForDocument,
  ] = useState<any>({});
  const [checkData, setCheckData] = useState<any>(
    {
      checkDateBirth: true,

      checkDateExpiry: true,
      checkActualPlaceOfResidence: true,
      checkDateIssue: true,

      checkFamilyStatus: true,
      checkEmail: true,
      checkInn: true,
      checkUserInn: true,
      checkName: true,

      checkNation: true,
      checkAuthority: true,
      checkPassportNumber: true,

      checkPatronymic: true,
      checkPhoto: true,
      checkRegistrationAddress: true,
      checkSurname: true,
      desiredService: true,
    }
  );
  const [registred, setRegistred] =
    useState(false);
  const [blacklistItems, setBlacklistItem] =
    useState<any>("Загрузка...");
  const [errorMessage, setErrorMessage] =
    useState({
      error: false,
      message: "",
    });
  const [notification, setNotification] =
    useState(false);
  const [
    approvingDisabled,
    setApprovingDisabled,
  ] = useState(false);

  const processClickClose = () => {
    setProcessOpen(false);
  };
  useEffect(() => {
    if (status === "waitingOperator") {
      setProcessOpen(false);
    }
  }, [status]);
  const clientRegClickOpen = () => {
    if (
      settings!.name === "Бакай Банк" ||
      settings!.name === "ОАО «Кыргызкоммерцбанк»"
    ) {
      if (sessionId && activeRequest) {
        ModerationApi.requestRegistrationForGuest(
          sessionId,
          activeRequest.requestId
        )
          .then((data) => {
            if (data.success === true) {
              setProcessOpen(true);
            }
          })
          .catch(({ response }) =>
            alert(response.data.message)
          );
      }
    } else {
      if (stepRegistration === "") {
        setclientRegOpen(true);
      } else {
        setProcessOpen(true);
      }
    }
  };

  const clientRegClickClose = () => {
    setclientRegOpen(false);
  };

  const documentModalClose = () => {
    setModalOpen((prevState) => ({
      ...prevState,
      document: false,
    }));
  };

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const callClickClose = () => {
    setCallOpen(false);
  };

  const confirmClickOpen = () => {
    if (approvingDisabled) {
      setErrorMessage({
        error: true,
        message:
          "Клиент находится в Черном списке",
      });
      setNotification(true);
    } else {
      setOpenConfirm(true);
      if (
        sessionId &&
        requestId &&
        passwordInfo
      ) {
        ModerationApi.changePassportInfo(
          sessionId,
          parseInt(requestId),
          passwordInfo
        )
          .then((data) => {
            console.log(data);
          })
          .catch(({ response }) =>
            console.error(response.data.message)
          );
      }
    }
  };

  const confirmClickClose = () => {
    setOpenConfirm(false);
    setRegistred(true);
  };

  const rejectClickOpen = () => {
    setOpenReject(true);
  };

  const rejectClickClose = () => {
    setOpenReject(false);
  };

  const imageClickOpen = (index?: number) => {
    setOpenImage(true);
    //@ts-ignore
    setIdxImg(index);
  };

  const imageClickClose = (value: boolean) => {
    setOpenImage(value);
  };

  const redirectVideoCall = (
    department: IDepartment
  ) => {
    setDepartment(department);
    setCallOpen(true);
  };

  useEffect(() => {
    if (
      activeRequest &&
      activeRequest.customerAccountId &&
      sessionId
    ) {
      ClientAPI.getPassportInfo(
        sessionId,
        activeRequest.customerAccountId
      ).then((data) => {
        setPasswordInfo(data);
        setUserInfoForDocument({
          clientName: data.userName,
          clientFirstName:
            data.userName.split(" ")[0],
          clientLastName: data.userName
            .split(" ")
            .slice(
              1,
              data.userName.split(" ").length - 1
            )
            .join(" "),
          clientPatronymic:
            data.userName.split(" ")[
              data.userName.split(" ").length - 1
            ],
          clientLatName: data.additionalInfo
            ? data.additionalInfo.userNameLat
            : "",
          clientLatFirstName: data.additionalInfo
            ? data.additionalInfo.nameLat
            : "",
          clientLatLastName: data.additionalInfo
            ? data.additionalInfo.surnameLat
            : "",
          clientPassportNumber:
            data.passportNumber,
          clientInn: data.userInn,
          clientDateBirth: data.dateBirth
            ? data.dateBirth
            : "",
          clientDateExpiry: data.dateExpiry,
          clientDateIssue: data.dateIssue,
          clientAuthority: data.authority,
          clientRegistrationAddress:
            data.registrationAddress,
          clientActualPlaceOfResidence:
            data.additionalInfo
              ? data.additionalInfo
                  .actualPlaceOfResidence
              : "",
          clientPhone: data.phone,
          clientEmail: data.additionalInfo
            ? data.additionalInfo.email
            : "",
          clientFamilyStatus: data.additionalInfo
            ? data.additionalInfo.familyStatus
            : "",
          clientPlaceOfWork: data.additionalInfo
            ? data.additionalInfo.placeOfWork
            : "",
          clientPost: data.additionalInfo
            ? data.additionalInfo.post
            : "",
          clientCodeWord: data.additionalInfo
            ? data.additionalInfo.codeWord
            : "",
          clientCitizenship: data.additionalInfo
            ? data.additionalInfo.nationality
            : "",
          clientExistenceOfBeneficialOwner:
            data.additionalInfo
              ? data.additionalInfo
                  .existenceOfBeneficialOwner
              : "",
          clientAvailabilityOfCitizenshipOrGreenCardUSA:
            data.additionalInfo
              ? data.additionalInfo
                  .availabilityOfCitizenshipOrGreenCardUSA
              : "",
          clientIsPolitic: data.additionalInfo
            ? data.additionalInfo.isPolitic
            : "",
          clientStateEmployee: data.additionalInfo
            ? data.additionalInfo.stateEmployee
            : "",
          clientSex:
            (data.userInn[0] === "1" &&
              "Женский") ||
            (data.userInn[0] === "2" &&
              "Мужской"),
          clientPassport:
            "Паспорт: " +
            data.passportNumber +
            ", выдан " +
            data.authority +
            ", дата выдачи: " +
            data.dateIssue,
          clientAddress: data.registrationAddress
            ? data.registrationAddress
            : "",
          clientNowAddress: data.additionalInfo
            ? data.additionalInfo
                .actualPlaceOfResidence
            : "good",
          clientNationality: data.additionalInfo
            .nationality
            ? data.additionalInfo.nationality
            : "",
          clientNation: data.additionalInfo
            ? data.additionalInfo.nation
            : "",
          inBlackList: false,
          inBanksBlackList: false,
        });
      });
    }
  }, [activeRequest, sessionId]);
  useEffect(() => {
    //@ts-ignore
    let getVideoModerationStatusInterval;
    if (sessionId) {
      if (activeRequest?.customerAccountId) {
        ClientAPI.getPassportInfo(
          sessionId,
          activeRequest.customerAccountId
        ).then((data) => {
          if (!passwordInfo) {
            setPasswordInfo(data);
          }
          setUserInfoForDocument({
            clientName: data.userName,
            clientFirstName:
              data.userName.split(" ")[0],
            clientLastName: data.userName
              .split(" ")
              .slice(
                1,
                data.userName.split(" ").length -
                  1
              )
              .join(" "),
            clientPatronymic:
              data.userName.split(" ")[
                data.userName.split(" ").length -
                  1
              ],
            clientLatName: data.additionalInfo
              ? data.additionalInfo.userNameLat
              : "",
            clientLatFirstName:
              data.additionalInfo
                ? data.additionalInfo.nameLat
                : "",
            clientLatLastName: data.additionalInfo
              ? data.additionalInfo.surnameLat
              : "",
            clientPassportNumber:
              data.passportNumber,
            clientInn: data.userInn,
            clientDateBirth: data.dateBirth
              ? data.dateBirth
              : "",
            clientDateExpiry: data.dateExpiry,
            clientDateIssue: data.dateIssue,
            clientAuthority: data.authority,
            clientRegistrationAddress:
              data.registrationAddress,
            clientActualPlaceOfResidence:
              data.additionalInfo
                ? data.additionalInfo
                    .actualPlaceOfResidence
                : "",
            clientPhone: data.phone,
            clientEmail: data.additionalInfo
              ? data.additionalInfo.email
              : "",
            clientFamilyStatus:
              data.additionalInfo
                ? data.additionalInfo.familyStatus
                : "",
            clientPlaceOfWork: data.additionalInfo
              ? data.additionalInfo.placeOfWork
              : "",
            clientPost: data.additionalInfo
              ? data.additionalInfo.post
              : "",
            clientCodeWord: data.additionalInfo
              ? data.additionalInfo.codeWord
              : "",
            clientCitizenship: data.additionalInfo
              ? data.additionalInfo.nationality
              : "",
            clientExistenceOfBeneficialOwner:
              data.additionalInfo
                ? data.additionalInfo
                    .existenceOfBeneficialOwner
                : "",
            clientAvailabilityOfCitizenshipOrGreenCardUSA:
              data.additionalInfo
                ? data.additionalInfo
                    .availabilityOfCitizenshipOrGreenCardUSA
                : "",
            clientIsPolitic: data.additionalInfo
              ? data.additionalInfo.isPolitic
              : "",
            clientStateEmployee:
              data.additionalInfo
                ? data.additionalInfo
                    .stateEmployee
                : "",
            clientSex:
              (data.userInn[0] === "1" &&
                "Женский") ||
              (data.userInn[0] === "2" &&
                "Мужской"),
            clientPassport:
              "Паспорт: " +
              data.passportNumber +
              ", выдан " +
              data.authority +
              ", дата выдачи: " +
              data.dateIssue,
            clientAddress:
              data.registrationAddress
                ? data.registrationAddress
                : "",
            clientNowAddress: data.additionalInfo
              ? data.additionalInfo
                  .actualPlaceOfResidence
              : "good",
            clientNationality: data.additionalInfo
              .nationality
              ? data.additionalInfo.nationality
              : "",
            clientNation: data.additionalInfo
              ? data.additionalInfo.nation
              : "",
            inBlackList: false,
            inBanksBlackList: false,
          });
        });
      } else if (requestId) {
        ModerationApi.getPassportInfo(
          sessionId,
          parseInt(requestId)
        ).then((data) => {
          if (!passwordInfo) {
            setPasswordInfo({
              ...data,
              inBlackList: false,
              inBlackListOfBanks: false,
            });
            ModerationApi.checkInBlackList(
              sessionId,
              `${data.name} ${data.surname} ${data.patronymic}`
            )
              .then((inBlackListRes) => {
                if (
                  settings?.name ===
                    "Бакай Банк" &&
                  inBlackListRes.haveOnBlacklist
                ) {
                  setApprovingDisabled(true);
                } else {
                  setApprovingDisabled(false);
                }
                if (
                  Boolean(
                    inBlackListRes.items.length
                  )
                ) {
                  type blacklistItem = {
                    id: number;
                    name: string;
                    prc: number;
                  };
                  let newItems: blacklistItem[] =
                    [];
                  inBlackListRes.items.forEach(
                    (item: blacklistItem) => {
                      let name = item.name;
                      item.name = name.replaceAll(
                        "<br/>",
                        ""
                      );
                      newItems.push(item);
                    }
                  );
                  setBlacklistItem(newItems);
                } else {
                  setBlacklistItem("Не найдено");
                }
              })
              .catch(() => {
                setBlacklistItem(
                  "Не получилось загрузить черный список"
                );
                setNotification(true);
                setErrorMessage({
                  error: false,
                  message:
                    "Не получилось загрузить черный список",
                });
              });
          }
          setUserInfoForDocument({
            clientName: data.userName,
            clientFirstName:
              data.userName.split(" ")[0],
            clientLastName: data.userName
              .split(" ")
              .slice(
                1,
                data.userName.split(" ").length -
                  1
              )
              .join(" "),
            clientPatronymic:
              data.userName.split(" ")[
                data.userName.split(" ").length -
                  1
              ],
            clientLatName: data.additionalInfo
              ? data.additionalInfo.userNameLat
              : "",
            clientLatFirstName:
              data.additionalInfo
                ? data.additionalInfo.nameLat
                : "",
            clientLatLastName: data.additionalInfo
              ? data.additionalInfo.surnameLat
              : "",
            clientPassportNumber:
              data.passportNumber,
            clientInn: data.userInn,
            clientDateBirth: data.dateBirth
              ? data.dateBirth
              : "",
            clientDateExpiry: data.dateExpiry,
            clientDateIssue: data.dateIssue,
            clientAuthority: data.authority,
            clientRegistrationAddress:
              data.registrationAddress,
            clientActualPlaceOfResidence:
              data.additionalInfo
                ? data.additionalInfo
                    .actualPlaceOfResidence
                : "",
            clientPhone: data.phone,
            clientEmail: data.additionalInfo
              ? data.additionalInfo.email
              : "",
            clientFamilyStatus:
              data.additionalInfo
                ? data.additionalInfo.familyStatus
                : "",
            clientPlaceOfWork: data.additionalInfo
              ? data.additionalInfo.placeOfWork
              : "",
            clientPost: data.additionalInfo
              ? data.additionalInfo.post
              : "",
            clientCodeWord: data.additionalInfo
              ? data.additionalInfo.codeWord
              : "",
            clientCitizenship: data.additionalInfo
              ? data.additionalInfo.nationality
              : "",
            clientExistenceOfBeneficialOwner:
              data.additionalInfo
                ? data.additionalInfo
                    .existenceOfBeneficialOwner
                : "",
            clientAvailabilityOfCitizenshipOrGreenCardUSA:
              data.additionalInfo
                ? data.additionalInfo
                    .availabilityOfCitizenshipOrGreenCardUSA
                : "",
            clientIsPolitic: data.additionalInfo
              ? data.additionalInfo.isPolitic
              : "",
            clientStateEmployee:
              data.additionalInfo
                ? data.additionalInfo
                    .stateEmployee
                : "",
            clientSex:
              (data.userInn[0] === "1" &&
                "Женский") ||
              (data.userInn[0] === "2" &&
                "Мужской"),
            clientPassport:
              "Паспорт: " +
              data.passportNumber +
              ", выдан " +
              data.authority +
              ", дата выдачи: " +
              data.dateIssue,
            clientAddress:
              data.registrationAddress
                ? data.registrationAddress
                : "",
            clientNowAddress: data.additionalInfo
              ? data.additionalInfo
                  .actualPlaceOfResidence
              : "good",
            clientNationality: data.additionalInfo
              .nationality
              ? data.additionalInfo.nationality
              : "",
            clientNation: data.additionalInfo
              ? data.additionalInfo.nation
              : "",
            inBlackList: false,
            inBanksBlackList: false,
          });
        });
      }

      if (
        window.location.pathname ===
          "/video-call" &&
        requestId
      ) {
        getVideoModerationStatusInterval =
          setInterval(() => {
            ClientAPI.getVideoCallStatus(
              sessionId,
              parseInt(requestId)
            )
              .then((data) => {
                setIdUser(data.customerAccountId);
                setStatus(data.status);
                if (
                  data.status ===
                    "conversationIsOver" ||
                  data.status ===
                    "responseTimeout"
                ) {
                  // history.push('/');
                  //@ts-ignore
                  return () =>
                    clearInterval(
                      //@ts-ignore
                      getVideoModerationStatusInterval
                    );
                }

                if (data.action) {
                  stepRegistrationAction(
                    data.action
                  );
                  if (
                    data.action ===
                    "registration_WaitModeration"
                  ) {
                    status !==
                      "userIsRegistered" &&
                      ModerationApi.getPassportInfo(
                        sessionId,
                        parseInt(requestId)
                      ).then((data) => {
                        if (!passwordInfo) {
                          setPasswordInfo(data);
                          ModerationApi.checkInBlackList(
                            sessionId,
                            `${data.name} ${data.surname} ${data.patronymic}`
                          )
                            .then(
                              (
                                inBlackListRes
                              ) => {
                                if (
                                  settings?.name ===
                                    "Бакай Банк" &&
                                  inBlackListRes.haveOnBlacklist
                                ) {
                                  setApprovingDisabled(
                                    true
                                  );
                                } else {
                                  setApprovingDisabled(
                                    false
                                  );
                                }
                                if (
                                  Boolean(
                                    inBlackListRes
                                      .items
                                      .length
                                  )
                                ) {
                                  type blacklistItem =
                                    {
                                      id: number;
                                      name: string;
                                      prc: number;
                                    };
                                  let newItems: blacklistItem[] =
                                    [];
                                  inBlackListRes.items.forEach(
                                    (
                                      item: blacklistItem
                                    ) => {
                                      let name =
                                        item.name;
                                      item.name =
                                        name.replaceAll(
                                          "<br/>",
                                          ""
                                        );
                                      newItems.push(
                                        item
                                      );
                                    }
                                  );
                                  setBlacklistItem(
                                    newItems
                                  );
                                } else {
                                  setBlacklistItem(
                                    "Не найдено"
                                  );
                                }
                              }
                            )
                            .catch(() => {
                              setBlacklistItem(
                                "Не получилось загрузить черный список"
                              );
                              setNotification(
                                true
                              );
                              setErrorMessage({
                                error: false,
                                message:
                                  "Не получилось загрузить черный список",
                              });
                            });
                        }
                        setUserInfoForDocument({
                          clientName:
                            data.userName,
                          clientFirstName:
                            data.userName.split(
                              " "
                            )[0],
                          clientLastName:
                            data.userName
                              .split(" ")
                              .slice(
                                1,
                                data.userName.split(
                                  " "
                                ).length - 1
                              )
                              .join(" "),
                          clientPatronymic:
                            data.userName.split(
                              " "
                            )[
                              data.userName.split(
                                " "
                              ).length - 1
                            ],
                          clientLatName:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .userNameLat
                              : "",
                          clientLatFirstName:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .nameLat
                              : "",
                          clientLatLastName:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .surnameLat
                              : "",
                          clientPassportNumber:
                            data.passportNumber,
                          clientInn: data.userInn,
                          clientDateBirth:
                            data.dateBirth
                              ? data.dateBirth
                              : "",
                          clientDateExpiry:
                            data.dateExpiry,
                          clientDateIssue:
                            data.dateIssue,
                          clientAuthority:
                            data.authority,
                          clientRegistrationAddress:
                            data.registrationAddress,
                          clientActualPlaceOfResidence:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .actualPlaceOfResidence
                              : "",
                          clientPhone: data.phone,
                          clientEmail:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .email
                              : "",
                          clientFamilyStatus:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .familyStatus
                              : "",
                          clientPlaceOfWork:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .placeOfWork
                              : "",
                          clientPost:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .post
                              : "",
                          clientCodeWord:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .codeWord
                              : "",
                          clientCitizenship:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .nationality
                              : "",
                          clientExistenceOfBeneficialOwner:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .existenceOfBeneficialOwner
                              : "",
                          clientAvailabilityOfCitizenshipOrGreenCardUSA:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .availabilityOfCitizenshipOrGreenCardUSA
                              : "",
                          clientIsPolitic:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .isPolitic
                              : "",
                          clientStateEmployee:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .stateEmployee
                              : "",
                          clientSex:
                            (data.userInn[0] ===
                              "1" &&
                              "Женский") ||
                            (data.userInn[0] ===
                              "2" &&
                              "Мужской"),
                          clientPassport:
                            "Паспорт: " +
                            data.passportNumber +
                            ", выдан " +
                            data.authority +
                            ", дата выдачи: " +
                            data.dateIssue,
                          clientAddress:
                            data.registrationAddress
                              ? data.registrationAddress
                              : "",
                          clientNowAddress:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .actualPlaceOfResidence
                              : "good",
                          clientNationality: data
                            .additionalInfo
                            .nationality
                            ? data.additionalInfo
                                .nationality
                            : "",
                          clientNation:
                            data.additionalInfo
                              ? data
                                  .additionalInfo
                                  .nation
                              : "",
                          inBlackList: false,
                          inBanksBlackList: false,
                        });
                      });
                  }
                } else {
                  stepRegistrationAction("");
                }
              })
              .catch(({ response }) =>
                console.error(response)
              );
          }, 5000);
        //@ts-ignore
        return () =>
          //@ts-ignore
          clearInterval(
            //@ts-ignore
            getVideoModerationStatusInterval
          );
      }
    }
  }, [
    passwordInfo,
    status,
    sessionId,
    requestId,
    history,
    stepRegistrationAction,
    missingRequest,
    activeRequest,
    settings,
  ]);
  useEffect(() => {
    const settingsUrl = `/companiesSettings/${window.location.hostname}/settings.json`;
    Axios.get(settingsUrl).then((res) => {
      InstanceHead.init(res.data.apiKey);
      if (sessionId) {
        ClientAPI.getDepartments(sessionId)
          .then((data) => {
            setDepartments(data);
          })
          .catch(({ response }) =>
            console.error(response)
          );
      }
    });
  }, [sessionId]);

  const monitorHeight =
    document.documentElement.clientHeight;
  useEffect(() => {
    setTimeout(() => {
      if (heightEl.current) {
        setFixedHeight(
          heightEl.current.scrollHeight
        );
      }
      if (redirectList.current) {
        setRedirectListHeight(
          redirectList.current.scrollHeight
        );
      }
    }, 700);
  }, [fixedHeight]);

  const addScroll = fixedHeight > monitorHeight;
  const addRedirectScroll =
    redirectListHeight > 600;

  const goBackClick = () => {
    sessionStorageRemoveItem("missingRequest");
    sessionStorageRemoveItem("activeRequest");
    missingRequestAction(null);
    activeRequestAction(null);
    registerUserTextAction("");
    return history.push("/");
  };

  const guestGoBackClick = () => {
    sessionStorageRemoveItem("activeRequest");
    activeRequestAction(null);
    stepRegistrationAction("");
    return history.push("/");
  };
  const handleChangeFile = (e: any) => {
    setPdf(e.target.files[0]);
  };
  useEffect(() => {
    if (sessionId)
      if (pdf && activeRequest) {
        const formData = new FormData();
        formData.append("file", pdf);
        //@ts-ignore
        ModerationApi.verifyPdf(
          sessionId,
          activeRequest.requestId,
          formData
        ).then((res) => {
          setCheckData(res);
        });
      }
  }, [activeRequest, sessionId, requestId, pdf]);

  const regSidebarView = () => {
    if (passwordInfo) {
      if (
        (activeRequest &&
          activeRequest.requestType ===
            "VideoRegistration") ||
        (activeRequest &&
          activeRequest.requestType ===
            "Photo") ||
        (!activeRequest && missingRequest) ||
        stepRegistration ===
          "registration_WaitModeration"
      ) {
        return (
          <div
            className="password"
            ref={heightEl}
            style={
              addScroll
                ? {
                    height: monitorHeight,
                    overflowY: "scroll",
                  }
                : { overflowY: "scroll" }
            }>
            <div
              className="password__back"
              onClick={goBackClick}>
              <BackIcon />
              <span>Входящие заявки</span>
            </div>
            <div className="password__content">
              <Button
                color="primary"
                className="password__edit"
                startIcon={<EditIcon />}
                onClick={() =>
                  setDisabled(false)
                }>
                Редактировать
              </Button>
              <div className="passwordData">
                {passwordInfo &&
                  passwordInfo.additionalInfo &&
                  passwordInfo.additionalInfo
                    .desiredService && (
                    <TextField
                      fullWidth
                      label="Выбранная услуга"
                      disabled={disabled}
                      error={
                        !checkData.desiredService
                      }
                      helperText={
                        !checkData.desiredService &&
                        "Не может быть пустым"
                      }
                      value={
                        passwordInfo
                          .additionalInfo
                          .desiredService
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            desiredService:
                              event.target.value,
                          },
                        })
                      }
                    />
                  )}

                <TextField
                  fullWidth
                  label="Фамилия"
                  error={!checkData.checkSurname}
                  helperText={
                    !checkData.checkSurname &&
                    "Не соответствует данным из ГРС"
                  }
                  disabled
                  value={passwordInfo.surname}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      surname: event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Имя"
                  error={!checkData.checkName}
                  helperText={
                    !checkData.checkName &&
                    "Не соответствует данным из ГРС"
                  }
                  disabled
                  value={passwordInfo.name}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      name: event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Отчество"
                  error={
                    !checkData.checkPatronymic
                  }
                  helperText={
                    !checkData.checkPatronymic &&
                    "Не соответствует данным из ГРС"
                  }
                  disabled
                  value={passwordInfo.patronymic}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      patronymic:
                        event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Имя на латинице"
                  disabled={disabled}
                  value={
                    passwordInfo.additionalInfo
                      .nameLat
                  }
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      additionalInfo: {
                        ...passwordInfo.additionalInfo,
                        nameLat:
                          event.target.value,
                      },
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Фамилия на латинице"
                  disabled={disabled}
                  value={
                    passwordInfo.additionalInfo
                      .surnameLat
                  }
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      additionalInfo: {
                        ...passwordInfo.additionalInfo,
                        surnameLat:
                          event.target.value,
                      },
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Национальность"
                  disabled={disabled}
                  value={
                    passwordInfo.additionalInfo
                      .nation
                  }
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      additionalInfo: {
                        ...passwordInfo.additionalInfo,
                        nation:
                          event.target.value,
                      },
                    })
                  }
                />
                {/* <TextField
                                    fullWidth
                                    disabled={disabled}
                                    label="Гражданство"
                                    value={passwordInfo.additionalInfo.nationality}
                                    onChange={(event) => setPasswordInfo({
                                        ...passwordInfo,
                                        nationality: event.target.value
                                    })}
                                /> */}
                <TextField
                  fullWidth
                  error={!checkData.checkInn}
                  helperText={
                    !checkData.checkInn &&
                    "Не соответствует данным из ГРС"
                  }
                  label="Персональный номер"
                  disabled
                  value={passwordInfo.userInn}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      userInn: event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Паспорт серия №"
                  disabled
                  error={
                    !checkData.checkPassportNumber
                  }
                  helperText={
                    !checkData.checkPassportNumber &&
                    "Не соответствует данным из ГРС"
                  }
                  value={
                    passwordInfo.passportNumber
                  }
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      passportNumber:
                        event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Дата рождения"
                  disabled
                  error={
                    !checkData.checkDateBirth
                  }
                  helperText={
                    !checkData.checkDateBirth &&
                    "Не соответствует данным из ГРС"
                  }
                  value={passwordInfo.dateBirth}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      dateBirth:
                        event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Дата выдачи паспорта"
                  disabled
                  value={passwordInfo.dateIssue}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      dateIssue:
                        event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Орган, выдавший паспорт"
                  disabled
                  error={
                    checkData.hasOwnProperty(
                      checkData.checkAuthority
                    ) && !checkData.checkAuthority
                  }
                  helperText={
                    !checkData.checkAuthority &&
                    "Не соответствует данным из ГРС"
                  }
                  value={passwordInfo.authority}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      authority:
                        event.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Дата окончания срока действия"
                  disabled
                  error={
                    !checkData.checkDateExpiry
                  }
                  helperText={
                    !checkData.checkDateExpiry &&
                    "Не соответствует данным из ГРС"
                  }
                  value={passwordInfo.dateExpiry}
                  onChange={(event) =>
                    setPasswordInfo({
                      ...passwordInfo,
                      dateExpiry:
                        event.target.value,
                    })
                  }
                />
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      label="Адрес прописки"
                      disabled
                      error={
                        !checkData.checkRegistrationAddress
                      }
                      helperText={
                        !checkData.checkRegistrationAddress &&
                        "Не соответствует данным из ГРС"
                      }
                      value={
                        passwordInfo.registrationAddress
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          registrationAddress:
                            event.target.value,
                        })
                      }
                    />
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      label="Фактическое место проживания"
                      disabled={disabled}
                      error={
                        !checkData.checkActualPlaceOfResidence
                      }
                      helperText={
                        !checkData.checkActualPlaceOfResidence &&
                        "Не соответствует данным из ГРС"
                      }
                      value={
                        passwordInfo
                          .residenceAddress
                          ?.fullAddress
                      }
                      onChange={(event) => {
                        setPasswordInfo({
                          ...passwordInfo,
                          // @ts-ignore
                          residenceAddress: {
                            ...passwordInfo.residenceAddress,
                            fullAddress:
                              event.target.value,
                          },
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            actualPlaceOfResidence:
                              event.target.value,
                          },
                        });
                      }}
                    />
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      label="Адрес электронной почты (e-mail)"
                      error={
                        !checkData.checkEmail
                      }
                      helperText={
                        !checkData.checkEmail &&
                        "Не соответствует данным из ГРС"
                      }
                      value={
                        passwordInfo
                          .additionalInfo.email
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            email:
                              event.target.value,
                          },
                        })
                      }
                    />
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      label="Семейное положение"
                      disabled={disabled}
                      error={
                        !checkData.checkFamilyStatus
                      }
                      helperText={
                        !checkData.checkFamilyStatus &&
                        "Не соответствует данным из ГРС"
                      }
                      value={
                        passwordInfo
                          .additionalInfo
                          .familyStatus
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            familyStatus:
                              event.target.value,
                          },
                        })
                      }
                      select>
                      <MenuItem value="Холост/незамужем">
                        Холост/незамужем
                      </MenuItem>
                      <MenuItem value="Женат/замужем">
                        Женат/замужем
                      </MenuItem>
                      <MenuItem value="В разводе">
                        В разводе
                      </MenuItem>
                      <MenuItem value="Повторный брак">
                        Повторный брак
                      </MenuItem>
                      <MenuItem value="Вдовец / вдова">
                        Вдовец / вдова
                      </MenuItem>
                    </TextField>
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      label="Место работы"
                      value={
                        passwordInfo
                          .additionalInfo
                          .placeOfWork
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            placeOfWork:
                              event.target.value,
                          },
                        })
                      }
                    />
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      label="Должность"
                      value={
                        passwordInfo
                          .additionalInfo.post
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            post: event.target
                              .value,
                          },
                        })
                      }
                    />
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <TextField
                      fullWidth
                      disabled
                      label="Кодовое слово"
                      value={
                        passwordInfo
                          .additionalInfo.codeWord
                      }
                      onChange={(event) =>
                        setPasswordInfo({
                          ...passwordInfo,
                          additionalInfo: {
                            ...passwordInfo.additionalInfo,
                            codeWord:
                              event.target.value,
                          },
                        })
                      }
                    />
                  )}
                {settings &&
                  !settings.btbInterface && (
                    <>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          <Typography variant="subtitle1">
                            Наличие бенефициарного
                            собственника
                          </Typography>
                        </FormLabel>
                        <RadioGroup
                          value={
                            passwordInfo
                              .additionalInfo
                              .existenceOfBeneficialOwner
                          }>
                          <FormControlLabel
                            disabled
                            name="ExistenceOfBeneficialOwnerYes"
                            control={
                              <Radio
                                checked={
                                  passwordInfo
                                    .additionalInfo
                                    .existenceOfBeneficialOwner
                                }
                              />
                            }
                            label="Да"
                          />
                          <FormControlLabel
                            disabled
                            name="ExistenceOfBeneficialOwnerNo"
                            control={
                              <Radio
                                checked={
                                  !passwordInfo
                                    .additionalInfo
                                    .existenceOfBeneficialOwner
                                }
                              />
                            }
                            label="Нет"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Наличие
                          гражданства/резидентства
                          США или Green Card
                        </FormLabel>
                        <RadioGroup
                          value={
                            passwordInfo
                              .additionalInfo
                              .availabilityOfCitizenshipOrGreenCardUSA
                          }>
                          <FormControlLabel
                            disabled
                            name="yes"
                            control={
                              <Radio
                                checked={
                                  passwordInfo
                                    .additionalInfo
                                    .availabilityOfCitizenshipOrGreenCardUSA
                                }
                              />
                            }
                            label="Да"
                          />
                          <FormControlLabel
                            disabled
                            name="no"
                            control={
                              <Radio
                                checked={
                                  !passwordInfo
                                    .additionalInfo
                                    .availabilityOfCitizenshipOrGreenCardUSA
                                }
                              />
                            }
                            label="Нет"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Является ли Килент ПДЛ
                        </FormLabel>
                        <RadioGroup
                          value={
                            passwordInfo
                              .additionalInfo
                              .isPolitic
                          }>
                          <FormControlLabel
                            disabled
                            value="isPoliticYes"
                            control={
                              <Radio
                                checked={
                                  passwordInfo
                                    .additionalInfo
                                    .isPolitic
                                }
                              />
                            }
                            label="Да"
                          />
                          <FormControlLabel
                            disabled
                            value="isPoliticNo"
                            control={
                              <Radio
                                checked={
                                  passwordInfo
                                    .additionalInfo
                                    .isPolitic ===
                                  false
                                }
                              />
                            }
                            label="Нет"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Является ли Килент ПДЛ
                        </FormLabel>
                        <RadioGroup
                          value={
                            passwordInfo
                              .additionalInfo
                              .stateEmployee
                          }>
                          <FormControlLabel
                            disabled
                            value="isPoliticYes"
                            control={
                              <Radio
                                checked={
                                  passwordInfo
                                    .additionalInfo
                                    .stateEmployee
                                }
                              />
                            }
                            label="Да"
                          />
                          <FormControlLabel
                            disabled
                            value="isPoliticNo"
                            control={
                              <Radio
                                checked={
                                  passwordInfo
                                    .additionalInfo
                                    .stateEmployee ===
                                  false
                                }
                              />
                            }
                            label="Нет"
                          />
                        </RadioGroup>
                      </FormControl>
                    </>
                  )}
              </div>
              <div className="password__imgRow">
                <div className="password__imgItem">
                  {sessionId && requestId && (
                    <>
                      <h3>Фото лица</h3>
                      <img
                        style={{
                          border:
                            !checkData.checkPhoto
                              ? "5px solid red"
                              : "",
                        }}
                        src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${requestId}/photo/${
                          settings
                            ? settings.apiKey
                            : ""
                        }`}
                        alt={
                          passwordInfo.userName
                        }
                        onClick={() =>
                          imageClickOpen(0)
                        }
                      />
                      <h3>
                        Лицевая сторона паспорта
                      </h3>
                      <img
                        src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${requestId}/passportFront/${
                          settings
                            ? settings.apiKey
                            : ""
                        }`}
                        alt={
                          passwordInfo.userName
                        }
                        onClick={() =>
                          imageClickOpen(1)
                        }
                      />
                      <h3>
                        Обратная сторона паспорта
                      </h3>
                      <img
                        src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${requestId}/passportBack/${
                          settings
                            ? settings.apiKey
                            : ""
                        }`}
                        alt={
                          passwordInfo.userName
                        }
                        onClick={() =>
                          imageClickOpen(2)
                        }
                      />
                      {activeRequest &&
                        activeRequest.requestType ===
                          "Photo" && (
                          <>
                            {" "}
                            <h3>
                              Селфи с паспортом
                            </h3>
                            <img
                              src={`${baseUrl()}api/Moderation/GetUserPhoto/${sessionId}/${requestId}/userPhotoWithPassport/${
                                settings
                                  ? settings.apiKey
                                  : ""
                              }`}
                              alt={
                                passwordInfo.userName
                              }
                              onClick={() =>
                                imageClickOpen(3)
                              }
                            />{" "}
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
              {/* <div className="dataMatches error">
                                <CheckCircleIcon />
                                <span>Данные совпадают с ГРС</span>
                            </div> */}
            </div>
            {!checkRequest && (
              <Box
                display="flex"
                alignItems="center">
                <label
                  className="upload__pdf"
                  htmlFor="file">
                  {pdf && (
                    <span className="pdfWrap">
                      {checkData.success ? (
                        <Box
                          className="grsStatus"
                          display="flex"
                          alignItems="center">
                          <SuccessIcon />
                          <span
                            style={{
                              color: "#00E03D",
                              marginLeft: "5px",
                            }}>
                            Данные совпадают с ГРС
                          </span>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center">
                          <WarningIcon />
                          <span
                            style={{
                              color: "#FF3B30",
                              marginLeft: "5px",
                            }}>
                            Данные не совпадают с
                            ГРС
                          </span>
                        </Box>
                      )}
                    </span>
                  )}
                  {!pdf && (
                    <input
                      id="file"
                      name="file"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChangeFile}
                    />
                  )}
                </label>
                {pdf &&
                  !checkData.hasOwnProperty(
                    "success"
                  ) && (
                    <span
                      className="removePdf"
                      onClick={(e: any) =>
                        setPdf("")
                      }>
                      {" "}
                      <DeleteIcon />
                    </span>
                  )}
              </Box>
            )}

            <Box style={{ padding: "0px 15px" }}>
              {/* @ts-ignore */}
              <b>В черных списках банка:</b>
              <br /> {/* @ts-ignore */}
              {typeof blacklistItems ===
                "string" && (
                <p style={{ margin: "8px 0px" }}>
                  {blacklistItems}
                </p>
              )}
            </Box>

            {Boolean(blacklistItems.length) &&
              Array.isArray(blacklistItems) && (
                <ol>
                  {blacklistItems.map((item) => (
                    <li
                      style={{
                        padding: "5px 0px",
                      }}
                      key={item.id}>
                      {item.name}
                    </li>
                  ))}
                </ol>
              )}

            <div className="btnRow btn__wrap-group">
              {registerUserText !== "" ? (
                <div className="registerUserText">
                  {registerUserText}
                </div>
              ) : (
                <>
                  <Button
                    onClick={rejectClickOpen}
                    variant="outlined"
                    color="secondary"
                    disableElevation>
                    Отклонить
                  </Button>
                  <Button
                    onClick={confirmClickOpen}
                    variant="contained"
                    color="primary"
                    disableElevation>
                    Подтвердить
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      }
    }
  };
  const checkRequestFunc = () => {
    setCheckRequest(true);
  };
  return (
    <div className="videoContainer">
      <SnackbarAlert
        notification={notification}
        setNotification={setNotification}
        message={errorMessage.message}
        severity={
          errorMessage.error ? "error" : "success"
        }
        vertical="top"
        horizontal="center"
      />
      {activeRequest &&
        (activeRequest.requestType ===
          "VideoCall" ||
          activeRequest.requestType ===
            "VideoRegistration") && (
          <>
            <div className="authClientData">
              <div
                className="authClientData__back"
                onClick={goBackClick}>
                <BackIcon />
                <span>Входящие заявки</span>
              </div>
              <div className="clientInfo">
                {sessionId && settings && (
                  <Avatar
                    className="profile__avatar"
                    src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${
                      activeRequest.customerAccountId
                    }/photo/${settings.apiKey}`}>
                    {activeRequest.userName.substr(
                      0,
                      1
                    )}
                  </Avatar>
                )}
                <div className="clientInfo__content">
                  <div className="clientInfo__span">
                    Клиент банка
                  </div>
                  <div className="clientInfo__name">
                    {activeRequest.userName}
                  </div>
                </div>
              </div>
              <List className="clientList">
                <ListItem
                  button
                  onClick={() =>
                    setModalOpen({
                      document: false,
                      password:
                        !modalOpen.password,
                    })
                  }>
                  <ListItemAvatar>
                    <DataIcon />
                  </ListItemAvatar>
                  <ListItemText primary="Личные данные" />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setModalOpen({
                          document: false,
                          password: true,
                        })
                      }>
                      {modalOpen.password ? (
                        <span className="prevIcon">
                          <PrevIcon />
                        </span>
                      ) : (
                        <ArrowForwardIosIcon />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    setModalOpen({
                      document:
                        !modalOpen.document,
                      password: false,
                    })
                  }>
                  <ListItemAvatar>
                    <DocIcon />
                  </ListItemAvatar>
                  <ListItemText primary="Документы" />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setModalOpen({
                          document: true,
                          password: false,
                        })
                      }>
                      {modalOpen.document ? (
                        <span className="prevIcon">
                          <PrevIcon />
                        </span>
                      ) : (
                        <ArrowForwardIosIcon />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
              <div
                className="callTransfer"
                ref={redirectList}
                style={
                  addRedirectScroll
                    ? {
                        maxHeight: "60vh",
                        overflowY: "scroll",
                      }
                    : {}
                }>
                <div className="callTransfer__title">
                  <div className="callTransfer__icon">
                    <ShareIcon />
                  </div>
                  Перевод звонка
                </div>
                <List className="callTransferList">
                  {departments.map(
                    (department) => {
                      const currentIcon =
                        icons.find(
                          (icon) =>
                            icon.id ===
                            department.iconId
                        );
                      return (
                        <ListItem
                          button
                          key={
                            department.departmentId
                          }
                          onClick={() =>
                            redirectVideoCall(
                              department
                            )
                          }>
                          <ListItemAvatar>
                            <Icon className="clientVideoCall__icon">
                              {currentIcon ? (
                                currentIcon.name
                              ) : (
                                <PanoramaFishEyeIcon />
                              )}
                            </Icon>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              department.departmentName
                            }
                          />
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </div>
            </div>
            <div
              className="passwordModal"
              style={
                modalOpen.password
                  ? { display: "block" }
                  : { display: "none" }
              }>
              <IconButton
                className="modalClose"
                onClick={() =>
                  setModalOpen((prevState) => ({
                    ...prevState,
                    password: false,
                  }))
                }>
                <CloseIcon />
              </IconButton>
              <div className="passwordModal__header">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  className="passwordModal__header">
                  <Tab
                    label="Паспортные данные"
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Банковские данные"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </div>
              <TabPanel
                value={tabValue}
                index={0}>
                {passwordInfo && (
                  <PasswordData
                    clientInfo={
                      activeRequest.customerAccountId
                        ? true
                        : false
                    }
                    passwordInfo={passwordInfo}
                    imageClickOpen={
                      imageClickOpen
                    }
                    customerId={
                      activeRequest.customerAccountId
                        ? activeRequest.customerAccountId
                        : activeRequest.requestId
                    }
                  />
                )}
              </TabPanel>
              <TabPanel
                value={tabValue}
                index={1}>
                <BankAccounts
                  customerAccountId={
                    activeRequest.customerAccountId
                  }
                />
              </TabPanel>
            </div>
            <DocumentModal
              userInfo={userInfoForDocument}
              modalOpen={modalOpen.document}
              documentModalClose={
                documentModalClose
              }
              customerAccountId={
                activeRequest.customerAccountId ||
                idUser
              }
            />
          </>
        )}
      {activeRequest &&
        (activeRequest.requestType === "Guest" ||
          activeRequest.requestType ===
            "VideoRegistration") && (
          <>
            {status === "userIsRegistered" ? (
              <>
                <div className="authClientData">
                  <div
                    className="authClientData__back"
                    onClick={goBackClick}>
                    <BackIcon />
                    <span>Входящие заявки</span>
                  </div>
                  <div className="clientInfo">
                    {sessionId && settings && (
                      <Avatar
                        className="profile__avatar"
                        src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${
                          activeRequest.customerAccountId
                        }/photo/${
                          settings.apiKey
                        }`}>
                        {activeRequest.userName.substr(
                          0,
                          1
                        )}
                      </Avatar>
                    )}
                    <div className="clientInfo__content">
                      <div className="clientInfo__span">
                        Клиент банка
                      </div>
                      <div className="clientInfo__name">
                        {userInfoForDocument.clientName
                          ? userInfoForDocument.clientName
                          : activeRequest.userName}
                      </div>
                    </div>
                  </div>
                  <List className="clientList">
                    <ListItem
                      button
                      onClick={() =>
                        setModalOpen({
                          document: false,
                          password:
                            !modalOpen.password,
                        })
                      }>
                      <ListItemAvatar>
                        <DataIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Личные данные" />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() =>
                            setModalOpen({
                              document: false,
                              password: true,
                            })
                          }>
                          {modalOpen.password ? (
                            <span className="prevIcon">
                              <PrevIcon />
                            </span>
                          ) : (
                            <ArrowForwardIosIcon />
                          )}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() =>
                        setModalOpen({
                          document:
                            !modalOpen.document,
                          password: false,
                        })
                      }>
                      <ListItemAvatar>
                        <DocIcon />
                      </ListItemAvatar>
                      <ListItemText primary="Документы" />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() =>
                            setModalOpen({
                              document: true,
                              password: false,
                            })
                          }>
                          {modalOpen.document ? (
                            <span className="prevIcon">
                              <PrevIcon />
                            </span>
                          ) : (
                            <ArrowForwardIosIcon />
                          )}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  <div
                    className="callTransfer"
                    ref={redirectList}
                    style={
                      addRedirectScroll
                        ? {
                            maxHeight: "60vh",
                            overflowY: "scroll",
                          }
                        : {}
                    }>
                    <div className="callTransfer__title">
                      <div className="callTransfer__icon">
                        <ShareIcon />
                      </div>
                      Перевод звонка
                    </div>
                    <List className="callTransferList">
                      {departments.map(
                        (department) => {
                          const currentIcon =
                            icons.find(
                              (icon) =>
                                icon.id ===
                                department.iconId
                            );
                          return (
                            <ListItem
                              button
                              key={
                                department.departmentId
                              }
                              onClick={() =>
                                redirectVideoCall(
                                  department
                                )
                              }>
                              <ListItemAvatar>
                                <Icon className="clientVideoCall__icon">
                                  {currentIcon ? (
                                    currentIcon.name
                                  ) : (
                                    <PanoramaFishEyeIcon />
                                  )}
                                </Icon>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  department.departmentName
                                }
                              />
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  </div>
                </div>
                <div
                  className="passwordModal"
                  style={
                    modalOpen.password
                      ? { display: "block" }
                      : { display: "none" }
                  }>
                  <IconButton
                    className="modalClose"
                    onClick={() =>
                      setModalOpen(
                        (prevState) => ({
                          ...prevState,
                          password: false,
                        })
                      )
                    }>
                    <CloseIcon />
                  </IconButton>
                  <div className="passwordModal__header">
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      indicatorColor="primary"
                      textColor="primary"
                      className="passwordModal__header">
                      <Tab
                        label="Паспортные данные"
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Банковские данные"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </div>
                  <TabPanel
                    value={tabValue}
                    index={0}>
                    {passwordInfo && (
                      <PasswordData
                        passwordInfo={
                          passwordInfo
                        }
                        imageClickOpen={
                          imageClickOpen
                        }
                        customerId={
                          activeRequest.customerAccountId
                            ? activeRequest.customerAccountId
                            : activeRequest.requestId
                            ? activeRequest.requestId
                            : idUser
                        }
                        clientInfo={
                          activeRequest.customerAccountId
                            ? true
                            : false
                        }
                      />
                    )}
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={1}>
                    <BankAccounts
                      customerAccountId={
                        activeRequest.customerAccountId ||
                        idUser
                      }
                    />
                  </TabPanel>
                </div>
                <DocumentModal
                  userInfo={userInfoForDocument}
                  modalOpen={modalOpen.document}
                  documentModalClose={
                    documentModalClose
                  }
                  customerAccountId={
                    activeRequest.customerAccountId ||
                    idUser
                  }
                />
              </>
            ) : (
              <div className="authClientData">
                <div
                  className="authClientData__back"
                  onClick={guestGoBackClick}>
                  <BackIcon />
                  <span>Входящие заявки</span>
                </div>
                <div className="clientInfo">
                  <Avatar>
                    {activeRequest.userName.substr(
                      0,
                      1
                    )}
                  </Avatar>
                  <div className="clientInfo__content">
                    <div className="clientInfo__span">
                      Гость
                    </div>
                    <div className="clientInfo__name">
                      {activeRequest.userName}
                    </div>
                  </div>
                </div>
                <div className="registrationBtn">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    startIcon={
                      <AccountBoxIcon fontSize="large" />
                    }
                    fullWidth
                    onClick={clientRegClickOpen}>
                    Регистрация клиента
                  </Button>
                </div>
                {settings &&
                  settings.name !==
                    "Бакай Банк" && (
                    <ClientRegistration
                      setCheckRegistration={
                        checkRequestFunc
                      }
                      clientRegOpen={
                        clientRegOpen
                      }
                      clientRegClickClose={
                        clientRegClickClose
                      }
                    />
                  )}
                <ProcessDialog
                  processOpen={processOpen}
                  processClickClose={
                    processClickClose
                  }
                />
              </div>
            )}
          </>
        )}
      {registred ? null : regSidebarView()}
      <div className="iframe">
        {activeRequest &&
          activeRequest.requestType !== "Photo" &&
          conferenceJoinLink && (
            <iframe
              src={conferenceJoinLink}
              allow="camera;microphone"
              className="iframeContent"
              title="video call"
            />
          )}
        {/* <div className="iframePreview">
                    <img src={PreviewImg} alt=""/>
                </div>
                <div className="iframe__icons">
                    <span><MicrophoneIcon /></span>
                    <span><VideoIcon /></span>
                    <span><CallIcon /></span>
                </div> */}
        {requestId && settings && (
          <ImageModal
            clientInfo={
              activeRequest &&
              activeRequest.customerAccountId
                ? true
                : false
            }
            openImage={openImage}
            imageClickClose={(value: boolean) =>
              imageClickClose(value)
            }
            sessionId={sessionId}
            idx={idxImg}
            apiKey={settings.apiKey}
            customerId={
              activeRequest &&
              activeRequest.customerAccountId
                ? activeRequest.customerAccountId
                : parseInt(requestId)
            }
          />
        )}
      </div>
      <ConfirmModal
        status={status}
        openConfirm={openConfirm}
        confirmClickClose={confirmClickClose}
        text="Вы уверены, что хотите подтвердить личность клиента?"
        buttonText="Подтвердить"
        cinfirm={true}
      />
      <ConfirmModal
        openConfirm={openReject}
        confirmClickClose={rejectClickClose}
        text="Вы уверены, что хотите отклонить заявку клиента?"
        buttonText="Да"
        cinfirm={false}
      />
      {department && (
        <CallTransferDialog
          callOpen={callOpen}
          callClickClose={callClickClose}
          department={department}
        />
      )}
    </div>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } =
    props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && (
        <div className="passwordModal__content">
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  conferenceJoinLink:
    state.moderation.conferenceJoinLink,
  activeRequest: state.moderation.activeRequest,
  missingRequest: state.moderation.missingRequest,
  registerUserText:
    state.moderation.registerUserText,
  settings: state.admin.settings,
  stepRegistration: state.admin.stepRegistration,
  requestId: state.moderation.requestId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> &
    ThunkDispatch<any, any, AnyAction>
) => ({
  setLoaderAction: (loading: boolean) =>
    dispatch(
      actions.auth.setLoaderAction(loading)
    ),
  stepRegistrationAction: (
    stepRegistration: StepRegistration | string
  ) =>
    dispatch(
      actions.admin.stepRegistrationAction(
        stepRegistration
      )
    ),
  missingRequestAction: (
    missingRequest: MissingRequest | null
  ) =>
    dispatch(
      actions.moderation.missingRequestAction(
        missingRequest
      )
    ),
  activeRequestAction: (
    activeRequest: ActiveRequest | null
  ) =>
    dispatch(
      actions.moderation.activeRequestAction(
        activeRequest
      )
    ),
  registerUserTextAction: (
    registerUserText: string
  ) =>
    dispatch(
      actions.moderation.registerUserTextAction(
        registerUserText
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoCall);
