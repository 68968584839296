import React, {ChangeEvent, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './CreateNewDocumentModal.scss';
import {PathAndName} from "../../store/CreateDocument/types";

type Props = {
    open: boolean,
    setOpen: (obj: any) => any,
    addNameAndPath: (obj: PathAndName) => any,
    options: string[],
    settings: any
}

const CreateNewDocumentModal: React.FC<Props> = ({open, setOpen, addNameAndPath, options, settings}) => {

    const [documentPath, setDocumentPath] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [documentEnhancedSign, setDocumentEnhancedSign] = useState(false)

    const submitForm = () => {
        setOpen({first: false, second: true});
        addNameAndPath({
            documentPath,
            documentName,
            useQualifiedSignature: documentEnhancedSign
        });
        setDocumentName('');
        setDocumentPath('');
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={setOpen}
                className="document-modal"
            >
                <DialogTitle className="document-modal__title">
                    Создание нового документа
                </DialogTitle>
                <DialogContent>
                    <TextField value={documentName}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => setDocumentName(e.target.value)}
                               type={"text"} className="document-modal__input" label="Название"/>
                    <Autocomplete
                        id="controlled-demo"
                        freeSolo
                        disableClearable
                        onInputChange={(event: any, newValue: any) => {
                            setDocumentPath(newValue)
                        }}
                        options={options}
                        renderInput={(params) => <TextField {...params}
                                                            onChange={({target: {value}}: ChangeEvent<HTMLInputElement>) => setDocumentPath(value)}
                                                            InputProps={{...params.InputProps, type: 'search'}}
                                                            value={documentPath}
                                                            type={"text"} className="document-modal__input"
                                                            label="Услуга"/>}
                    />
                    {settings && settings.name !== "Avcon" &&  <FormControlLabel
                        control={
                            <Checkbox
                                checked={documentEnhancedSign}
                                onChange={event => setDocumentEnhancedSign(event.target.checked)}
                                color="primary"
                            />
                        }
                        label="Требуется усиленная подпись"
                    />}
                   
                </DialogContent>
                <DialogActions className="document-modal__action">
                    <Button onClick={() => setOpen({first: false, second: false})}
                            className="document-modal__action-button document-modal__action-button--white">
                        Отмена
                    </Button>
                    <Button disabled={!((documentPath && documentPath.length) > 0 && documentName.length > 0)}
                            onClick={submitForm}
                            className="document-modal__action-button document-modal__action-button--green">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CreateNewDocumentModal;
