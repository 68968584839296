export interface IDocument {
  documentName: string,
  documentPath: string,
  documentTemplateId: number
}

export interface IFileOfDocument {
  [key: string]: IDocument[]
}

export interface CreateDocumentState {
  documentTemplateHtml: string,
  documentTags: string[] | null,
  documentTemplateId: number,
  documentName: string,
  documentPath: string,
  documents: IFileOfDocument[],
  oneDocuments: IDocument[],
  documentForEditing: IOneDocument | {},
  useQualifiedSignature: boolean
}

export interface IOneDocument {
  documentTemplateHtml: string,
  documentTags: string[] | null,
  documentTemplateId: number,
  documentName: string,
  documentPath: string,
}

export enum CreateDocumentTypes {
  ADD_DOCUMENT_TEMPLATE_HTML = 'ADD_DOCUMENT_TEMPLATE_HTML',
  ADD_DOCUMENT_TAGS = 'ADD_DOCUMENT_TAGS',
  ADD_DOCUMENT_NAME_AND_PATH = 'ADD_DOCUMENT_NAME_AND_PATH',
  GET_DOCUMENTS_START = 'GET_DOCUMENTS_START',
  GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAIL = 'GET_DOCUMENTS_FAIL',
  ADD_ONE_DOCUMENT_TO_STORE = 'ADD_ONE_DOCUMENT_TO_STORE',
  CLEAR_ONE_DOCUMENT = 'CLEAR_ONE_DOCUMENT',
  GET_ONE_DOCUMENT_SUCCESS = 'GET_ONE_DOCUMENT_SUCCESS',
  CHANGE_ONE_DOCUMENT = 'CHANGE_ONE_DOCUMENT',
  REMOVE_ONE_DOCUMENT = 'REMOVE_ONE_DOCUMENT'
}

export interface PathAndName {
  documentName: string,
  documentPath: string,
  useQualifiedSignature: boolean
}
