import React, { Dispatch, useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
} from "@material-ui/core";
import { ModerationApi } from "../../api/ModerationApi";
import { CameraIcon } from "../../icons/icons";
import MissedVideoCallRoundedIcon from "@material-ui/icons/MissedVideoCallRounded";
import { useHistory } from "react-router-dom";
import { ActiveRequest, MissingRequest } from "../../store/Moderation/types";
import Pagination from "@material-ui/lab/Pagination";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;
type MissingRequestState = {
  [key: number]: MissingRequest[];
};

const MissingTable: React.FC<Props> = ({
  sessionId,
  activeRequestAction,
  conferenceJoinLinkAction,
  missingRequestAction,
  requestIdAction,
}) => {
  let history = useHistory();
  const [missingRequest, setMissingRequest] = useState<MissingRequestState>({
    1: [],
  });
  const [currentCount, setCurrentCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (sessionId) {
      ModerationApi.getMissingRequests(sessionId).then((data) => {
        if (data) {
          let paginationData = data.reverse().reduce(
            (calculator: MissingRequestState, item: MissingRequest) => {
              let currentPage = Object.keys(calculator).length;
              if (calculator[currentPage].length === 10) {
                calculator[currentPage + 1] = [item];
              } else {
                calculator[currentPage].push(item);
              }
              return calculator;
            },
            { 1: [] }
          );
          setCurrentCount(Object.keys(paginationData).length);
          setMissingRequest(paginationData);
        }
      });
    }
  }, [sessionId]);

  // const missingVideoCallClick = (missingRequest: MissingRequest) => {
  //     if (sessionId) {
  //         ModerationApi.startVideoCall(sessionId, missingRequest.requestId).then(data => {
  //             conferenceJoinLinkAction(data.conferenceJoinLink);
  //             missingRequestAction(missingRequest);
  //             requestIdAction(missingRequest.requestId);
  //             return history.push('/video-call');
  //         });
  //     }
  // }
  const startVideCallBack = (missingRequest: any) => {
    if (sessionId) {
      ModerationApi.startVideoCallback(
        sessionId,
        missingRequest.requestId
      ).then((data) => {
        conferenceJoinLinkAction(data.conferenceJoinLink);
        activeRequestAction(missingRequest);
        requestIdAction(missingRequest.requestId);
        return history.push("/video-call");
      });
    }
  };
  const paginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };
  return (
    <>
      <TableContainer className="tableContainer">
        <Table className="table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell style={{ width: 16 }}>№</TableCell>
              <TableCell align="left">Фамилия Имя Отчество</TableCell>
              <TableCell align="left">Дата и время заявки</TableCell>
              <TableCell align="left">Номер телефона</TableCell>
              <TableCell align="left">Запрос клиента</TableCell>
              <TableCell align="right">Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(missingRequest[page].length) &&
              missingRequest[page].map((item: MissingRequest, i: number) => (
                <TableRow key={"missing" + item.requestId}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    {item.userName}
                  </TableCell>
                  <TableCell align="left">{item.requestDate}</TableCell>
                  <TableCell align="left">{item.phone}</TableCell>
                  <TableCell align="left">
                    <Box
                      className="tableLinkBlue"
                      display="flex"
                      alignItems="center">
                      {item.requestType === "Guest" && (
                        <span className="table__name">Гость</span>
                      )}
                      {item.requestType === "VideoCall" && (
                        <>
                          <span className="table__icon">
                            <CameraIcon />
                          </span>
                          <span className="table__name">Видеозвонок</span>
                        </>
                      )}
                      {item.requestType === "VideoRegistration" && (
                        <>
                          <span className="table__icon">
                            <MissedVideoCallRoundedIcon />
                          </span>
                          <span className="table__name">Видеорегистрация</span>
                        </>
                      )}
                      {item.requestType === "Photo" && (
                        <>
                          <span className="table__icon">
                            <CameraIcon />
                          </span>
                          <span className="table__name">Фото</span>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      className="tableLinkBlue"
                      onClick={() => startVideCallBack(item)}>
                      <>
                        <span className="table__name">Вызвать клиента</span>
                      </>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="justify-center">
        {currentCount > 1 && (
          <Pagination
            count={currentCount}
            page={page}
            onChange={paginationChange}
            shape="rounded"
            className="paginations"
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  conferenceJoinLinkAction: (conferenceJoinLink: string) =>
    dispatch(actions.moderation.conferenceJoinLinkAction(conferenceJoinLink)),
  missingRequestAction: (missingRequest: MissingRequest) =>
    dispatch(actions.moderation.missingRequestAction(missingRequest)),
  requestIdAction: (requestId: number) =>
    dispatch(actions.moderation.requestIdAction(requestId)),
  activeRequestAction: (activeRequest: ActiveRequest) =>
    dispatch(actions.moderation.activeRequestAction(activeRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MissingTable);
