import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { baseUrl } from "../../utils/baseUrl";
import { IPasswordInfo } from "../../store/Moderation/types";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import { ClientAPI } from "../../api/ClientAPI";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";

type Props = ReturnType<typeof mapStateToProps> & {
  passwordInfo: IPasswordInfo;
  imageClickOpen: (number: number) => void;
  customerId: number;
  clientInfo?: boolean;
  pdf?: any;
};

const ViewClientInfo: React.FC<Props> = (props) => {
  const {
    sessionId,
    customerId,
    imageClickOpen,
    settings,
    clientInfo,
    getUserInfo,
    pdf,
  } = props;
  const [phone, setPhone] = useState("");
  const [passwordInfo, setCustomer] = useState<IPasswordInfo | null>();
  const { id } = useParams<{ id?: string }>();

  // useEffect(() => {
  //     if(
  //         passwordInfo !== null && passwordInfo.phone
  //     ){
  //         setPhone(passwordInfo!.phone)
  //     }

  // }, [passwordInfo]);

  useEffect(() => {
    if (sessionId && id) {
      ClientAPI.getPassportInfo(sessionId, Number(id))
        .then((data) => {
          setCustomer(data);
          setPhone(data.phone);
        })
        .catch(({ response }) => console.log(response.data.message));
    }
  }, [sessionId, id, setCustomer]);
  console.log(passwordInfo);

  const downloadPdfDocument = (rootElementId: any) => {
    const input = document.getElementById(rootElementId);
    html2canvas(input!, { scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${passwordInfo!.userName}.pdf`);
    });
  };

  return (
    <div
      style={{
        padding: "32px 0 10px 32px",
        backgroundColor: "#fff",
        color: "#333333",
      }}>
      <div
        className="personInfo"
        id="info"
        style={{ display: "flex", justifyContent: "center" }}>
        {sessionId && settings && passwordInfo && (
          <>
            <div
              className="personInfo__imgRow"
              style={{
                marginRight: "22px",
                maxWidth: "160px",
                width: "100%",
              }}>
              <img
                style={{
                  display: "block",
                  marginBottom: "10px",
                  maxWidth: "160px",
                  width: "100%",
                  cursor: "pointer",
                }}
                src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${id}/photo/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(0)}
              />
              <img
                style={{
                  display: "block",
                  marginBottom: "10px",
                  maxWidth: "160px",
                  width: "100%",
                  cursor: "pointer",
                }}
                src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${id}/passportFront/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(1)}
              />
              <img
                style={{
                  display: "block",
                  marginBottom: "10px",
                  maxWidth: "160px",
                  width: "100%",
                  cursor: "pointer",
                }}
                src={`${baseUrl()}api/Client/GetUserPhoto/${sessionId}/${id}/passportBack/${
                  settings.apiKey
                }`}
                alt={passwordInfo.userName}
                onClick={() => imageClickOpen(2)}
              />
            </div>
          </>
        )}
        <div className="personInfoRow">
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Ф.И.О.
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.userName}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Персональный номер
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.userInn}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Номер паспорта
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.passportNumber}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Дата рождения
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.dateBirth}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Пол
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {(passwordInfo && passwordInfo.userInn[0] === "1" && "Женский") ||
                (passwordInfo && passwordInfo.userInn[0] === "2" && "Мужской")}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Дата выдачи паспорта
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.dateIssue}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Орган, выдавший паспорт
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.authority}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Дата окончания срока действия
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.dateExpiry}
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Номер телефона
            </div>

            <div className="personInfo__phone">
              <InputMask
                style={{ border: "none" }}
                mask="+999 999 999 999"
                value={phone}
                className="personInfo__input"
                id="changePhone"
                readOnly
              />
            </div>
          </div>
          <div className="personInfo__item" style={{ marginBottom: "8px" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Адрес прописки
            </div>
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF" }}>
              {passwordInfo && passwordInfo.registrationAddress}
            </div>
          </div>

          <div
            className="personInfo__item"
            style={{ marginBottom: "8px", fontWeight: "bold" }}>
            <div
              className="personInfo__label"
              style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Кодовое слово
            </div>
            {/*  @ts-ignore */}
            <div
              className="personInfo__textField"
              style={{ borderBottom: "1px solid #AFAFAF", fontWeight: 500 }}>
              {passwordInfo &&
                passwordInfo.additionalInfo &&
                passwordInfo.additionalInfo.codeWord}
            </div>
          </div>
          {passwordInfo && passwordInfo.additionalInfo && (
            <>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  Гражданство
                </div>
                {/*  @ts-ignore */}
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo && passwordInfo.additionalInfo.nationality}
                </div>
              </div>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  ФИО на латинице
                </div>
                {/*  @ts-ignore */}
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo &&
                    `${passwordInfo.additionalInfo.nameLat} ${passwordInfo.additionalInfo.surnameLat}`}
                </div>
              </div>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  Фактическое место проживания
                </div>
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo && passwordInfo.residenceAddress?.fullAddress}
                </div>
              </div>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  Адрес электронной почты (e-mail)
                </div>
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo && passwordInfo.additionalInfo.email}
                </div>
              </div>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  Семейное положение
                </div>
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo && passwordInfo.additionalInfo.familyStatus}
                </div>
              </div>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  Место работы
                </div>
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo && passwordInfo.additionalInfo.placeOfWork}
                </div>
              </div>
              <div className="personInfo__item" style={{ marginBottom: "8px" }}>
                <div
                  className="personInfo__label"
                  style={{ fontWeight: "bold", marginBottom: "4px" }}>
                  Должность
                </div>
                <div
                  className="personInfo__textField"
                  style={{ borderBottom: "1px solid #AFAFAF" }}>
                  {passwordInfo && passwordInfo.additionalInfo.post}
                </div>
              </div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <span style={{ fontSize: 14 }}>
                    Наличие бенефициарного собственника
                  </span>
                </FormLabel>
                <RadioGroup
                  value={
                    passwordInfo &&
                    passwordInfo.additionalInfo.existenceOfBeneficialOwner
                  }>
                  <FormControlLabel
                    disabled={true}
                    name="ExistenceOfBeneficialOwnerYes"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          passwordInfo.additionalInfo.existenceOfBeneficialOwner
                        }
                      />
                    }
                    label="Да"
                  />
                  <FormControlLabel
                    disabled={true}
                    name="ExistenceOfBeneficialOwnerNo"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          !passwordInfo.additionalInfo
                            .existenceOfBeneficialOwner
                        }
                      />
                    }
                    label="Нет"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <span style={{ fontSize: 14 }}>
                    Наличие гражданства/резидентства США или Green
                  </span>
                  Card
                </FormLabel>
                <RadioGroup
                  value={
                    passwordInfo.additionalInfo
                      .availabilityOfCitizenshipOrGreenCardUSA
                  }>
                  <FormControlLabel
                    disabled={true}
                    name="yes"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          passwordInfo.additionalInfo
                            .availabilityOfCitizenshipOrGreenCardUSA
                        }
                      />
                    }
                    label="Да"
                  />
                  <FormControlLabel
                    disabled={true}
                    name="no"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          !passwordInfo.additionalInfo
                            .availabilityOfCitizenshipOrGreenCardUSA
                        }
                      />
                    }
                    label="Нет"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <span style={{ fontSize: 14 }}>Является ли Клиент ПДЛ</span>
                </FormLabel>
                <RadioGroup
                  value={passwordInfo && passwordInfo.additionalInfo.isPolitic}>
                  <FormControlLabel
                    disabled={true}
                    value="isPoliticYes"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo && passwordInfo.additionalInfo.isPolitic
                        }
                      />
                    }
                    label="Да"
                  />
                  <FormControlLabel
                    disabled={true}
                    value="isPoliticNo"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          passwordInfo.additionalInfo.isPolitic === false
                        }
                      />
                    }
                    label="Нет"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <span style={{ fontSize: 14 }}>
                    Является ли клиент Гос. служащим?
                  </span>
                </FormLabel>
                <RadioGroup
                  value={
                    passwordInfo && passwordInfo.additionalInfo.stateEmployee
                  }>
                  <FormControlLabel
                    disabled={true}
                    value="isPoliticYes"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          passwordInfo.additionalInfo.stateEmployee
                        }
                      />
                    }
                    label="Да"
                  />
                  <FormControlLabel
                    disabled={true}
                    value="isPoliticNo"
                    control={
                      <Radio
                        size="small"
                        checked={
                          passwordInfo &&
                          passwordInfo.additionalInfo.stateEmployee === false
                        }
                      />
                    }
                    label="Нет"
                  />
                </RadioGroup>
              </FormControl>
              {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>



                            <Button style={{ margin: '10px' }} color="primary" variant='outlined' onClick={() => downloadPdfDocument('info')}>скачать</Button>
                        </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  settings: state.admin.settings,
  getUserInfo: state.auth.getUserInfo,
});

export default connect(mapStateToProps)(ViewClientInfo);
