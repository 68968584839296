import {CreateDocumentState} from '../store/CreateDocument/types';
import {InstanceDocumentHead} from "./InstanceHead";

class CreateDocumentAPI {
    public static async postDocument(sessionId: string, data: CreateDocumentState) {
        return await InstanceDocumentHead.instance.post(`/References/SetDocumentTemplate/${sessionId}`, data).then(res => res.data)
    }

    public static async getDocuments(sessionId: string) {
        return await InstanceDocumentHead.instance.get(`/References/GetDocumentShortTemplates/${sessionId}`).then(res => res.data)
    }

    public static async getOneDocument(sessionId: string, documentTemplateId: string | number) {
        return await InstanceDocumentHead.instance.get(`/References/GetDocumentTemplate/${sessionId}/${documentTemplateId}`).then(res => res.data)
    }

    public static async removeDoc(sessionId: string, documentTemplateId: string | number) {
        return await InstanceDocumentHead.instance.post(`/References/DeleteDocumentTemplate/${sessionId}/${documentTemplateId}`).then(res => res.data)
    }
}

export default CreateDocumentAPI;
