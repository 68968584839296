import React, {Dispatch, useState} from 'react';
import {connect} from 'react-redux';
import {RootState, actions} from '../../store';
import {ThunkDispatch} from 'redux-thunk';
import {Action, AnyAction} from 'redux';
import Button from '@material-ui/core/Button';
import {Typography, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import {SessionAPI} from '../../api/SessionAPI';
import {useHistory} from 'react-router-dom';
import {ExitIcon} from "../../icons/icons";

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Header: React.FC<Props> = ({sessionId, settings, logoutAction}) => {
    let history = useHistory();
    const [logoutOpen, setLogoutOpen] = useState(false);

    const logoutClick = () => {
        if (sessionId) {
            SessionAPI.closeSession(sessionId);
            logoutAction();
            return history.push('/');
        }
    }

    return (
        <header className="header">
            <div className="header-wrap">
                <div className="header__name">
                    {settings && settings.name === "Avcon" ?
                    <Typography variant="h3" component="h3"
                                className="title">{'Казактелеком'}</Typography>
                                : <Typography variant="h3" component="h3"
                                className="title">{settings ? settings.name : ''}</Typography>
                    }
                </div>
                <div className="header__exit" onClick={logoutClick}>
                    <span>Выход</span>
                    <ExitIcon/>
                </div>
            </div>
            <Dialog
                open={logoutOpen}
                className="modal confirm">
                <DialogTitle>Выход из системы</DialogTitle>
                <DialogContent>
                    <p className="modal__text">Вы уверены, что хотите выйти ?</p>
                </DialogContent>
                <DialogActions className="modal__actions">
                    <Button onClick={() => setLogoutOpen(false)} color="primary">Отмена</Button>
                    <Button
                        variant="contained"
                        color={"secondary"}
                        disableElevation
                        onClick={logoutClick}>Выход</Button>
                </DialogActions>
            </Dialog>
        </header>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    getUserInfo: state.auth.getUserInfo,
    settings: state.admin.settings,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    logoutAction: () => dispatch(actions.auth.logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
