import {Action, ActionCreator} from 'redux';
import {CreateDocumentTypes, PathAndName, IFileOfDocument, IDocument, IOneDocument} from './types';

interface IAddDocumentNameAndPath extends Action<CreateDocumentTypes.ADD_DOCUMENT_NAME_AND_PATH> {
    payload: PathAndName
}

interface IAddDocumentHtmlTemplate extends Action<CreateDocumentTypes.ADD_DOCUMENT_TEMPLATE_HTML> {
    payload: string
}

interface IAddDocumentTags extends Action<CreateDocumentTypes.ADD_DOCUMENT_TAGS> {
    payload: string[]
}

interface IGetCreatedDocumentsSuccess extends Action<CreateDocumentTypes.GET_DOCUMENTS_SUCCESS> {
    payload: IFileOfDocument[]
}

interface IAddOneDocumentToStore extends Action<CreateDocumentTypes.ADD_ONE_DOCUMENT_TO_STORE> {
    payload: IDocument[]
}

interface IClearOneDocument extends Action<CreateDocumentTypes.CLEAR_ONE_DOCUMENT> {
    payload: []
}

interface IGetOneDocumentSuccess extends Action<CreateDocumentTypes.GET_ONE_DOCUMENT_SUCCESS> {
    payload: IOneDocument
}

interface IChangeOneDocument extends Action<CreateDocumentTypes.CHANGE_ONE_DOCUMENT> {
    payload: string
}

interface IRemoveOneDocument extends Action<CreateDocumentTypes.REMOVE_ONE_DOCUMENT> {
    payload: number
}

export type CreateDocumentType =
    IAddDocumentNameAndPath
    | IAddDocumentHtmlTemplate
    | IAddDocumentTags
    | IGetCreatedDocumentsSuccess
    | IAddOneDocumentToStore
    | IClearOneDocument
    | IGetOneDocumentSuccess
    | IRemoveOneDocument
    | IChangeOneDocument;

export const addNameAndPath: ActionCreator<IAddDocumentNameAndPath> = (payload: PathAndName) => {
    return {
        type: CreateDocumentTypes.ADD_DOCUMENT_NAME_AND_PATH,
        payload
    }
}
export const removeOneDocumentAction: ActionCreator<IRemoveOneDocument> = (payload: number) => {
    return {
        type: CreateDocumentTypes.REMOVE_ONE_DOCUMENT,
        payload
    }
}
export const addHtmlTemplate: ActionCreator<IAddDocumentHtmlTemplate> = (payload: string) => {
    return {
        type: CreateDocumentTypes.ADD_DOCUMENT_TEMPLATE_HTML,
        payload
    }
};

export const addTags: ActionCreator<IAddDocumentTags> = (payload: string[]) => {
    return {
        type: CreateDocumentTypes.ADD_DOCUMENT_TAGS,
        payload
    }
}

export const getCreatedDocuments: ActionCreator<IGetCreatedDocumentsSuccess> = (payload: IFileOfDocument[]) => {
    return {
        type: CreateDocumentTypes.GET_DOCUMENTS_SUCCESS,
        payload
    }
}

export const addOneDocumentToStore: ActionCreator<IAddOneDocumentToStore> = (payload: IDocument[]) => {
    return {
        type: CreateDocumentTypes.ADD_ONE_DOCUMENT_TO_STORE,
        payload
    }
}

export const clearOneDocument: ActionCreator<IClearOneDocument> = () => {
    return {
        type: CreateDocumentTypes.CLEAR_ONE_DOCUMENT,
        payload: []
    }
}

export const getOneDocument: ActionCreator<IGetOneDocumentSuccess> = (payload: IOneDocument) => {
    return {
        type: CreateDocumentTypes.GET_ONE_DOCUMENT_SUCCESS,
        payload
    }
}

export const changeOneDocument: ActionCreator<IChangeOneDocument> = (payload: string) => {
    return {
        type: CreateDocumentTypes.CHANGE_ONE_DOCUMENT,
        payload
    }
}
