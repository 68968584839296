import {Action, ActionCreator} from "redux";
import {ReductionTypes, Reduction} from './types';

interface IGetReductionActionSuccess extends Action<ReductionTypes.GET_REDUCTION_SUCCESS> {
    payload: Reduction[]
}

interface IAddSuccessReduction extends Action<ReductionTypes.ADD_REDUCTION> {
}

export type ReductionActions = IGetReductionActionSuccess | IAddSuccessReduction;

export const getReductionActionSuccessActionCreator: ActionCreator<IGetReductionActionSuccess> = (payload: Reduction[]) => {
    return {
        type: ReductionTypes.GET_REDUCTION_SUCCESS,
        payload: payload
    }
};
export const addReductionSuccess: ActionCreator<IAddSuccessReduction> = () => {
    return {
        type: ReductionTypes.ADD_REDUCTION
    }
};
