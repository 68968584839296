// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import { ClientDocumentsAPI } from '../api/ClientDocumentsAPI'
import { OrganizationAPI } from '../api/OrganizationAPI'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'
import jwtDecode from 'jwt-decode'
import { SignedIcon } from '../icons/icons'

const useStyles = makeStyles({
  wrapper: {
    padding: 15
  },
  htmlDocument: {
    marginBottom: 20
  }
})

function DocumentPreview(props: any) {
  const classes = useStyles()

  const [document, setDocument] = React.useState(null)
  const [department, setDepartment] = React.useState(null)

  React.useEffect(() => {
    ClientDocumentsAPI
      .getDocument(props.sessionId, props.match.params.id)
      .then(documentData => {
        setDocument(documentData)
        return OrganizationAPI.getDepartmentDetails(props.sessionId, documentData.departmentId)
      })
      .then(departmentData => {
        setDepartment(departmentData)
      })
  }, [props])

  if (document && department) return (
    <div className={classes.wrapper}>
      <div
        dangerouslySetInnerHTML={{ __html: document.documentHtml }}
        className={classes.htmlDocument}
      />

      <div className="signedContract">
        <div className="signedContract__item">
          <div
            className="signedContract__label">Филиал: <strong>{department.departmentName}</strong>
          </div>
          <div
            className="signedContract__label">Банк: <strong>{department.departmentHeadFullName}</strong>
          </div>
        </div>

        <div className="signedContract__item">
          <div className="signedContract__label">
            Клиент: {document.userName ?
              <strong>{document.userName}</strong> : <strong>
                {jwtDecode(document.documentSignature).signatures[0].userFullName}
              </strong>
            }
            </div>
        </div>

        <div className="signed__signature">
          Подписан: <span>
            <Moment
              format="DD.MM.YYYY">{document.createdDate}
            </Moment>
          </span> в <span>
            <Moment
              format="hh:mm:ss">{document.createdDate}
            </Moment>
          </span>
        </div>

        <div className="signed__item">
          <div className="signed__icon"><SignedIcon /></div>
          <span>
            {jwtDecode(document.documentSignature).signatures[0].signatureInfo}
          </span> :
          <span style={{ marginLeft: 15 }}>
              Подписано квалифициронной электронной подписью
          </span>
        </div>
      </div>
    </div>
  )

  return <span>Loading...</span>
}

const mapStateToProps = (state: any) => ({
    sessionId: state.auth.sessionId
})

export default connect(mapStateToProps)(DocumentPreview)
