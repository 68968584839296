import React, {Dispatch, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import './ModalOfReduction.scss';
import {Action, AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {addReductionSuccess} from "../../store/Reduction/action";
import {connect} from "react-redux";
import {RootState} from "../../store";
import {ReductionAPI} from "../../api/ReductionAPI";


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    open: boolean,
    setOpen: () => any,
};

const SecondReductionModal: React.FC<Props> = ({open, setOpen, sessionId, addReductionSuccess, settings}) => {
    const [tagKey, setKey] = useState<any>('');
    const [tagDescription, setTagDescription] = useState('');
    const [tagValue, setTagValue] = useState('');
    const [tagValueId, setId] = useState('');
    const [tagValueName, setName] = useState('');
    const [error, setError] = useState('');
    const saveTags = () => {
        if (tagKey.slice(-1) !== ']' || tagKey[0] !== '[') {
            setTimeout(() => {
                setError('')
            }, 2500)
            return setError('Тег необходимо указывать в []')
        }
        if (sessionId) {
            ReductionAPI.setTags(sessionId, tagKey, tagDescription, tagValue, tagValueId, tagValueName).then((res) => {
                if (res.success) {
                    addReductionSuccess()
                    setOpen()
                    setTagDescription('')
                    setKey('')
                    setTagValue('')
                }
            })
        }
    };
    const handleCancel = () => {
        setOpen()
        setError('');
        setTagDescription('')
        setKey('')
    }
    return (
        <div>
            <Dialog open={open} onClose={setOpen} className={"reduction reduction-second"}>
                <DialogTitle className={"reduction__title"}>
                    Добавить сокращение
                </DialogTitle>
                <DialogContent className="reduction__content">
                    <TextField
                        value={tagKey}
                        onChange={(e) => setKey(e.target.value)} inputProps={{autoComplete: 'off'}}
                        id="standard-basic" label="Ключ"
                        placeholder={"[name]"} className={"reduction__input"}/>
                    <TextField
                        value={tagDescription}
                        onChange={(e) => setTagDescription(e.target.value)} inputProps={{autoComplete: 'off'}}
                        id="standard-basic" label="Описание"
                        className={"reduction__input"}/>
                    <TextField
                        value={tagValue}
                        onChange={(e) => setTagValue(e.target.value)} inputProps={{autoComplete: 'off'}}
                        id="standard-basic" label="Значение"
                        className={"reduction__input"}/>
                    {settings && settings.name === 'ОАО «Кыргызкоммерцбанк»' &&
                    <>
                    <TextField
                    value={tagValueName}
                    onChange={(e) => setName(e.target.value)} inputProps={{autoComplete: 'off'}}
                    id="standard-basic" label="Имя переменной"
                    className={"reduction__input"}/>
                <TextField
                    value={tagValueId}
                    onChange={(e) => setId(e.target.value)} inputProps={{autoComplete: 'off'}}
                    id="standard-basic" label="id переменной"
                    className={"reduction__input"}/>
                    </>}
                </DialogContent>
                <div style={{color: 'red'}}>{error}</div>
                <DialogActions className="reduction__actions reduction__actions-second">
                    <Button onClick={handleCancel}
                            className="reduction__action-button reduction__action-button--border-none">
                        Отмена
                    </Button>
                    <Button disabled={(tagKey.length > 2 && tagDescription.length > 2)? false : true} onClick={saveTags}
                            className="reduction__action-button reduction__action-button--green">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};
const mapStateToProps = (store: RootState) => ({
    sessionId: store.auth.sessionId,
    settings: store.admin.settings
});
const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addReductionSuccess: () => dispatch(addReductionSuccess())
});
export default connect(mapStateToProps, mapDispatchToProps)(SecondReductionModal);
