import axios, { AxiosInstance } from 'axios';
import { baseUrl } from '../utils/baseUrl';
import store from "../store";
import {logoutAction} from "../store/Auth/actions";

export class InstanceHead {
    public static instance: AxiosInstance
    public static init(apiKey: string) {
        InstanceHead.instance = axios.create({
            baseURL: `${baseUrl()}api/`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "apiKey" : apiKey
            }
        })
        InstanceHead.instance.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response;
                if (status === 401) {
                    store.dispatch(logoutAction());
                }
                return Promise.reject(error);
            }
        );
    }
}
export class InstanceDocumentHead {
    public static instance: AxiosInstance;
    public static init(url: string) {
        InstanceDocumentHead.instance = axios.create({
            baseURL: url,
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        })
        InstanceDocumentHead.instance.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response;
                if (status === 401) {
                    store.dispatch(logoutAction());
                }
                return Promise.reject(error);
            }
        );
    }
}

export class InstanceCreditHead {
    public static instance: AxiosInstance;
    public static init(url: string, apiKey: string) {
        InstanceCreditHead.instance = axios.create({
            baseURL: url,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "apiKey": apiKey
            }
        })
        InstanceCreditHead.instance.interceptors.response.use(
            response => response,
            error => {
                return Promise.reject(error);
            }
        );
    }
}

