import {InstanceDocumentHead} from "./InstanceHead";



export class ReductionAPI {
    public static async getReductions(sessionId: string) {
        return await InstanceDocumentHead.instance.get(`/references/GetTags/${sessionId}`).then(res => res.data)
    };

    public static async setTags(sessionId: string, tagKey: string, tagDescription: string, tagValue: string, tagValueId?: string, tagValueName?: string) {
        return await InstanceDocumentHead.instance.post(`/references/SetTag/${sessionId}`, {
            tagKey,
            tagDescription,
            tagValue,
            tagValueId,
            tagValueName
        }).then(res => {
            return res.data
        })
    };

    public static async deleteTags(sessionId: string, documentTagId: number) {
        return await InstanceDocumentHead.instance.post(`/references/DeleteTag/${sessionId}/${documentTagId}`).then(res => {
            return res.data
        })
    };
}
