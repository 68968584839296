import {CreateDocumentState, CreateDocumentTypes} from './types';
import {CreateDocumentType} from './action';
import {Reducer} from "redux";

const initialState: CreateDocumentState = {
    documentName: '',
    documentPath: '',
    documentTags: null,
    documentTemplateHtml: '',
    documentTemplateId: 0,
    documents: [],
    oneDocuments: [],
    documentForEditing: {},
    useQualifiedSignature: false
};

const CreateDocumentReducer: Reducer<CreateDocumentState, CreateDocumentType> = (state = initialState, action) => {

    switch (action.type) {
        case CreateDocumentTypes.ADD_DOCUMENT_TAGS:
            return {
                ...state,
                documentTags: [...action.payload]
            };
        case CreateDocumentTypes.ADD_DOCUMENT_NAME_AND_PATH:
            const {payload: {documentName, documentPath, useQualifiedSignature}} = action;
            return {
                ...state,
                documentName,
                documentPath,
                useQualifiedSignature
            };
        case CreateDocumentTypes.ADD_DOCUMENT_TEMPLATE_HTML:
            return {
                ...state,
                documentTemplateHtml: action.payload
            };
        case CreateDocumentTypes.GET_DOCUMENTS_SUCCESS:

            const groupBy = function (xs: any, key: any) {
                return xs.reduce(function (rv: any, x: any) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                }, {});
            };
            const parsedJson = groupBy(action.payload, 'documentPath');
            return {
                ...state,
                documents: parsedJson
            };
        case CreateDocumentTypes.ADD_ONE_DOCUMENT_TO_STORE:
            return {
                ...state,
                oneDocuments: action.payload
            };
        case CreateDocumentTypes.CLEAR_ONE_DOCUMENT:
            return {
                ...state,
                oneDocuments: []
            };
        case CreateDocumentTypes.REMOVE_ONE_DOCUMENT:
            return {
                ...state,
                oneDocuments: state.oneDocuments.filter(doc => doc.documentTemplateId !== action.payload)
            }
        case CreateDocumentTypes.GET_ONE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documentForEditing: action.payload
            };
        case CreateDocumentTypes.CHANGE_ONE_DOCUMENT:
            return {
                ...state,
                documentForEditing: {
                    ...state.documentForEditing,
                    documentTemplateHtml: action.payload
                }
            };
        default:
            return state
    }
}

export default CreateDocumentReducer;
