import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    DialogActions,
    DialogContent,
    Button,
} from '@material-ui/core';
import './CreateNewDocumentModal.scss';
import Editor from "../Document/Editor";

type Props = {
    open: boolean,
    setOpen: (obj: any) => any,
    addHtmlTemplate: (str: string) => any,
    postData: () => any,
    postNew: boolean,
    values: any,
}

const CreateNewDocumentModalWithEditor: React.FC<Props> = ({open, addHtmlTemplate, values, setOpen, postData, postNew}) => {

    const sendData = () => {
        setOpen({second: false, first: false});
        postData();
    };
    return (
        <div>
            <Dialog
                open={open}
                className="document-modal document-modal-editor"
            >
                <DialogContent>
                    <div style={{marginTop: '20px'}}>
                        {postNew ? (
                            <Editor html={addHtmlTemplate} propsValue={null}/>
                        ) : (
                            <Editor html={addHtmlTemplate} propsValue={values}/>
                        )}

                    </div>
                </DialogContent>
                <DialogActions className="document-modal__action">
                    <Button onClick={() => setOpen(postNew ? {second: false, first: false} : {second: false})}
                            className="document-modal__action-button document-modal__action-button--white">
                        Отмена
                    </Button>
                    <Button onClick={sendData}
                            className="document-modal__action-button document-modal__action-button--green">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CreateNewDocumentModalWithEditor;
