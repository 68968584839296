export interface Reduction {
    tagKey: string,
    tagDescription: string,
    documentTagId: number,
    tagValue: string
}

export interface ReductionState {
    reduction: Reduction[] | null,
    addReductionSuccess: boolean
}

export enum ReductionTypes {
    ADD_REDUCTION = 'ADD_REDUCTION',
    GET_REDUCTION_SUCCESS = 'GET_REDUCTION_SUCCESS',
}
