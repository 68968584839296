import React, {Dispatch, useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import {connect} from "react-redux";
import {RootState} from "../../store";
import Typography from "@material-ui/core/Typography";
import "./Organization.scss";
import Box from "@material-ui/core/Box";
import {DepartmentItemIcon, DocumentEditIcon, DocumentRemoveIcon, EditOrganizationIcon} from "../../icons/icons";
import Button from "@material-ui/core/Button";
import EditOrganization from "./Modals/EditOrganization/EditOrganization";
import AddDepartment from "./Modals/AddDepartment/AddDepartment";
import {NavLink} from "react-router-dom";
import {OrganizationAPI} from "../../api/OrganizationAPI";
import {Action, AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {addReductionSuccess} from "../../store/Reduction/action";
import {AdminAPI} from "../../api/AdminAPI";


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const Organization: React.FC<Props> = ({settings, success, sessionId, addReductionSuccess,}) => {
    const [addDepartmentModal, setDepartmentModal] = useState(false);
    const [editOrganizationModal, setEditOrganization] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [id, setId] = useState(0);
    const [employees, setEmployees] = useState([]);
    const addDepartmentToggle = () => {
        setDepartmentModal(!addDepartmentModal)
    };
    const editOrganizationToggle = () => {
        setEditOrganization(!editOrganizationModal);
    };
    useEffect(() => {
        if (sessionId) {
            OrganizationAPI.getOrganization(sessionId).then(res => {
                setOrganizations(res)
            })
        }
    }, [sessionId, success]);
    useEffect(() => {
        if (sessionId) {
            AdminAPI.searchEmployees(sessionId, 0, 0).then(data => {
                setEmployees(data.employees);
            }).catch(({response}) => console.error(response.data.message));
        }
    }, [sessionId])
    const getIdDepartment = (id: number) => {
        setId(id)
        setDepartmentModal(true)
    };
    const handleDelete = (id: number) => {
        if (sessionId && id !== 0) {
            if (window.confirm("Вы уверены что хотите удалить?")) {
                OrganizationAPI.deleteDepartments(sessionId, id).then(res => {
                    if (res.success) {
                        setDepartmentModal(false);
                        addReductionSuccess()
                        setId(0);
                    }
                })
            }
        }
    };
    return (
        <div className="organization">
            <Grid container spacing={3} justify="space-between" alignItems="center">
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h2" component="h2"
                                    className="title">Редактировать профиль организации</Typography>
                        <Box className="editOrganization" onClick={() => setEditOrganization(true)}>
                            <EditOrganizationIcon/>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => setDepartmentModal(true)}>Добавить</Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={'auto'}>
                    <Box mt="30px" display="flex" flexWrap="wrap">
                        {organizations.map((item: any) => {
                            return <Box className="departmentBox" key={item.departmentId}>
                                <NavLink to='#'>
                                    <Box display="flex" className="departmentBox__title">
                                        <div className="departmentTitle">{item.departmentName}</div>
                                        <Box className="departmentIcons">
                                            <div className="departmentIconDelete"
                                                 onClick={() => handleDelete(item.departmentId)}>
                                                <DocumentRemoveIcon/>
                                            </div>
                                            <div className="departmentIconEdit"
                                                 onClick={() => getIdDepartment(item.departmentId)}>
                                                <DocumentEditIcon/>
                                            </div>
                                        </Box>
                                    </Box>
                                    <div className="departmentBox__wrap">
                                        <DepartmentItemIcon/>
                                        <div className="departmentBox__author">
                                            {item.departmentHeadFullName}
                                        </div>
                                    </div>
                                </NavLink>
                            </Box>
                        })}
                    </Box>
                </Grid>
            </Grid>
            <EditOrganization
                open={editOrganizationModal}
                editToggle={editOrganizationToggle}/>
            <AddDepartment
                id={id}
                employees={employees}
                setId={setId}
                open={addDepartmentModal}
                addDepartmentModal={addDepartmentToggle}
            />
        </div>
    );
};


const mapStateToProps = (state: RootState) => ({
    settings: state.admin.settings,
    sessionId: state.auth.sessionId,
    success: state.reduction.addReductionSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addReductionSuccess: () => dispatch(addReductionSuccess())
});
export default connect(mapStateToProps, mapDispatchToProps)(Organization);
