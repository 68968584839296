import React, { Dispatch, useState } from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../store";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "react-moment";
import { SignedIcon } from "../../icons/icons";
import { ClientDocumentsAPI } from "../../api/ClientDocumentsAPI";
import SnackbarAlert from "./SnackbarAlert";
import { Action, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    contractOpen: boolean;
    contractClickClose: () => void;
    documentId: string;
    request?: boolean;
    doc?: any;
    decode?: any;
    departmentInfo?: any;
    successDoc?: boolean;
    setSuccess?: (value: boolean) => void;
  };

const ContractModal: React.FC<Props> = ({
  contractOpen,
  successDoc,
  setSuccess,
  departmentInfo,
  doc,
  decode,
  request,
  contractClickClose,
  sessionId,
  documentId,
  settings,
}) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [notification, setNotification] = useState(false);

  const changeStatusDoc = () => {
    if (sessionId) {
      ClientDocumentsAPI.changeStatusDocument(
        sessionId,
        doc.documentId,
        "Completed",
        comment
      ).then((res) => {
        //@ts-ignore
        if (res.success) {
          contractClickClose();
          setOpen(false);
          setComment("");
          setNotification(true);
          //@ts-ignore
          setSuccess(!successDoc);
        }
      });
    }
  };
  const handleChange = (e: any) => {
    setComment(e.target.value);
  };

  const date = new Date(doc && doc.createdDate);
  // const Hh = date.getHours()
  // const Mm = date.getMinutes()
  // const Ss = date.getSeconds()

  return (
    <>
      <Dialog
        open={contractOpen}
        className='contractModal'
        fullWidth
        scroll='body'
      >
        <IconButton className='modalClose' onClick={contractClickClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {request ? (
            <div className='center-preloader'>
              <CircularProgress size={24} />
            </div>
          ) : (
            <>
              {sessionId && settings && doc && doc.documentType !== "Pdf" && (
                //@ts-ignore
                <iframe
                  className='documentIframe'
                  srcDoc={documentId}
                  title='description'
                ></iframe>
              )}
              {doc && doc.documentType === "Pdf" && (
                <iframe
                  title='generated'
                  width='100%'
                  style={{ height: "100vh" }}
                  src={doc.url}
                ></iframe>
              )}
              {doc && (
                <div className='signedContract'>
                  <div className='signedContract__item'>
                    {settings &&
                      departmentInfo.hasOwnProperty("departmentName") && (
                        <>
                          <div className='signedContract__label'>
                            Филиал:{" "}
                            <strong>{departmentInfo.departmentName}</strong>
                          </div>
                          <div className='signedContract__label'>
                            Банк:{" "}
                            <strong>
                              {departmentInfo.departmentHeadFullName}
                            </strong>
                          </div>
                        </>
                      )}
                  </div>
                  <div className='signedContract__item'>
                    <div className='signedContract__label'>
                      Клиент:{" "}
                      {doc.userName ? (
                        <strong>{doc.userName}</strong>
                      ) : (
                        <strong>
                          {decode && decode.signatures[0].userFullName}
                        </strong>
                      )}
                    </div>
                  </div>
                  <div className='signed__signature'>
                    Подписан:{" "}
                    <span>
                      <Moment format='DD.MM.YYYY'>{doc.createdDate}</Moment>
                    </span>{" "}
                    в{" "}
                    <span>
                      <Moment format='hh:mm:ss'>{date}</Moment>
                    </span>
                  </div>
                  <div className='signed__item'>
                    <div className='signed__icon'>
                      <SignedIcon />
                    </div>
                    {decode ? (
                      <span>{decode.signatures[0].signatureInfo}</span>
                    ) : (
                      <span>
                        Подписано квалифициронной электронной подписью
                      </span>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <div className='executorInfo'>
            {doc && <div className='executorName'>{doc.executorName} </div>}
            {doc && <div> {doc.executionComment}</div>}
          </div>
        </DialogContent>
        {doc && doc.executionStatus !== "Completed" && (
          <DialogActions>
            <Box className='buttonWrap_document'>
              <Button onClick={contractClickClose}>Отмена</Button>
              <Button
                onClick={() => setOpen(true)}
                variant='contained'
                color='primary'
              >
                Исполнить
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
      <Dialog open={open}>
        <DialogContent className='commentModal'>
          <TextField
            label='Напишите комментарий исполнения'
            value={comment}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Box className='buttonWrap_document'>
            <Button onClick={() => setOpen(false)}>Отмена</Button>
            <Button onClick={changeStatusDoc} variant='contained'>
              Готово
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        notification={notification}
        setNotification={setNotification}
        message='Вы успешно исполнили документ!'
        severity='success'
        vertical='top'
        horizontal='center'
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  settings: state.admin.settings,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  contractSuccessAction: (contractSuccess: boolean) =>
    dispatch(actions.document.contractSuccessAction(contractSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractModal);
