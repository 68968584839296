import React, {useEffect, useState} from 'react';
import {Dialog, Input} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {RootState} from "../../../../store";
import {connect} from "react-redux";
import {OrganizationAPI} from "../../../../api/OrganizationAPI";

type Props = ReturnType<typeof mapStateToProps> & {
    editToggle: () => void;
    open: boolean
}
const EditOrganization: React.FC<Props> = ({editToggle, sessionId, open}) => {

    const [form, setForm] = useState({
        organizationName: '',
        organizationInn: '',
        bank: '',
        bik: '',
        paymentAccount: '',
        organizationAddress: ''
    });
    useEffect(() => {
        if (sessionId) {
            OrganizationAPI.getOrganizationInfo(sessionId).then(res => {
                setForm(res)
            })
        }
    }, [sessionId]);
    const saveOrganization = () => {
        if (sessionId) {
            const {organizationName, organizationInn, organizationAddress, bank, bik, paymentAccount} = form;
            OrganizationAPI.setOrganization(sessionId, organizationName, organizationInn, organizationAddress, bank, bik, paymentAccount).then(data => {
                if(data.success) {
                    editToggle()
                }
            })
        }
    };
    const handleChangeForm = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value})
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={editToggle}
                className="addOrganizationModal"
            >
                <DialogContent className="addOrganizationModal__content">
                    <div className="addOrganizationModal__title">Редактирование организации</div>
                    <FormControl fullWidth required>
                        <InputLabel>Наименование организации</InputLabel>
                        <Input
                            value={form.organizationName}
                            name="organizationName"
                            autoFocus
                            onChange={handleChangeForm}
                            disabled={form.organizationName.length ? true : false}
                            endAdornment={
                              !form.organizationName.length &&  <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, organizationName: ''})}>
                                    <IconButton>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel>ИНН</InputLabel>
                        <Input
                            value={form.organizationInn}
                            name="organizationInn"
                            autoFocus
                            onChange={handleChangeForm}
                            disabled={form.organizationInn.length ? true : false}
                            endAdornment={
                             !form.organizationInn.length &&   <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, organizationInn: ''})}>
                                    <IconButton>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel>Адрес</InputLabel>
                        <Input
                            value={form.organizationAddress}
                            name="organizationAddress"
                            autoFocus
                            onChange={handleChangeForm}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, organizationAddress: ''})}>
                                    <IconButton>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel>Банк</InputLabel>
                        <Input
                            value={form.bank}
                            name="bank"
                            autoFocus
                            onChange={handleChangeForm}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, bank: ''})}>
                                    <IconButton>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel>БИК</InputLabel>
                        <Input
                            value={form.bik}
                            name="bik"
                            autoFocus
                            onChange={handleChangeForm}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, bik: ''})}>
                                    <IconButton>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel>Расчетный счет</InputLabel>
                        <Input
                            value={form.paymentAccount}
                            name="paymentAccount"
                            autoFocus
                            onChange={handleChangeForm}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, organizationAddress: ''})}>
                                    <IconButton>
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }/>
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    <Box className="modalButtonGroup">
                        <Button onClick={editToggle}>Отмена</Button>
                        <Button variant="contained" className="green-button"
                                onClick={saveOrganization}>Сохранить</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.admin.settings,
    sessionId: state.auth.sessionId,

});
export default connect(mapStateToProps)(EditOrganization);
