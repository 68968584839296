// @ts-nocheck
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { ThunkDispatch } from "redux-thunk";
import { Action, AnyAction } from "redux";
import {
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Radio,
  Checkbox,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PaymentOrderTable from "../bricks/PaymentOrderTable";
import PaymentOrderModal from "../bricks/PaymentOrderModal";
import DocumentTable from "./DocumentTable";
import {
  BackDocumentArrowIcon,
  CheckedIcon,
  ContractIcon,
  DepartmentItemIcon,
} from "../../icons/icons";
import SnackbarAlert from "./SnackbarAlert";
import { OrganizationAPI } from "../../api/OrganizationAPI";
import Box from "@material-ui/core/Box";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { uuidv4 } from "../../utils/generateId";
import PreviewDocument from "../Modals/PreviewDocument/PreviewDocument";
import { ClientDocumentsAPI } from "../../api/ClientDocumentsAPI";

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    modalOpen: boolean;
    documentModalClose: () => void;
    customerAccountId: number;
    userInfo: any;
  };

// function DocSign(props: any) {
//     return <div className="signedContract">
//         <div className="signedContract__item">
//             {props.settings && props.departmentInfo.hasOwnProperty("departmentName") &&
//                 <>
//                     <div
//                         className="signedContract__label">Филиал: <strong>{props.departmentInfo.departmentName}</strong>
//                     </div>
//                     <div
//                         className="signedContract__label">Банк: <strong>{props.departmentInfo.departmentHeadFullName}</strong>
//                     </div>
//                 </>
//             }
//         </div>
//         <div className="signedContract__item">
//             <div className="signedContract__label">Клиент: {props.document.userName ?
//                 <strong>{props.document.userName}</strong> : <strong>
//                     {props.decode && props.decode.signatures[0].userFullName}
//                 </strong>}</div>
//         </div>
//         <div className="signed__signature">
//             Подписан: <span><Moment
//                 format="DD.MM.YYYY">{props.document.createdDate}</Moment></span> в <span><Moment
//                     format="hh:mm:ss">{props.document.createdDate}</Moment></span>
//         </div>
//         <div className="signed__item">
//             <div className="signed__icon"><SignedIcon /></div>
//             {props.decode ? <span>{props.decode.signatures[0].signatureInfo}</span> : <span>
//                 Подписано квалифициронной электронной подписью
//                             </span>}
//         </div>
//     </div>
// }

let ids = [];

const withId = (data) => {
  return data.map((item, index) => {
    if (!ids[index]) {
      ids[index] = Math.round(Math.random() * 100) + index;
    }

    return { ...item, id: ids[index] };
  });
};

const DocumentModal: React.FC<Props> = (props) => {
  const {
    modalOpen,
    documentModalClose,
    userInfo,
    customerAccountId,
    sessionId,
    contractSuccess,
    requestId,
    contractSuccessAction,
  } = props;

  const [paymentOpen, setPaymentOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [notification, setNotification] = useState(false);
  const [documentId, setDocumentId] = useState(0);
  const [errorMessage, setErrorMessage] = useState({
    error: false,
    message: "",
  });
  const [documents, setDocuments] = useState([]);
  const [childDocuments, setChildDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({
    documentTemplateId: 0,
    documentTemplateHtml: "",
    documentTags: [],
  });
  const [replaceDataDoc, setReplaceDataDoc] = useState([]);
  const [inputForEmptyTags, setInput] = useState<any>([]);
  const [departments, setDepartments] = useState([]);
  const [replaceDataDepartment, setReplaceDataDepartment] = useState([]);
  const [descriptionDoc, setDescriptionDoc] = useState("");
  // console.warn(': ', descriptionDoc)
  // const [clientInfo, setUserInfo] = useState<any>({});
  const [previewDocument, setPreviewDocument] = useState(false);
  const [html, setHtml] = useState("");
  const [bread, setBread] = useState("");
  const [groupTag, setGroupTag] = useState<any>({});
  const [groupTagObj, setGroupObj] = useState<any>({});
  const [request, setRequest] = useState(false);
  const [sendDocRequest, setSendDocRequest] = useState(false);
  const [templateId, setTemplateId] = useState<any>(null);
  const [generateDataRequest, setGenerateRequest] = useState(false);
  const [departmentInfo, setDepartmentInfo] = useState<any>(null);
  const [decode, setDecode] = useState<any>(null);
  const [documentRequestTime, setDocumentRequestTime] = useState<number>(0);
  const [radioIndex, setRadioIndex] = useState<null | number>(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function replaceAll(
    document: any,
    tags: any,
    bankInfo?: any,
    documentId?: number
  ) {
    for (let key in groupTagObj) {
      if (typeof groupTagObj[key] === "boolean")
        document = document.replaceAll(key, groupTagObj[key] ? "Да" : "Нет");
      else document = document.replaceAll(key, groupTagObj[key] || "");
    }
    for (let { tagKey, tagValue } of tags) {
      if (typeof tagValue === "boolean")
        document = document.replaceAll(tagKey, tagValue ? "Да" : "Нет");
      else document = document.replaceAll(tagKey, tagValue || "");
    }
    if (bankInfo) {
      for (let key in bankInfo) {
        if (typeof bankInfo[key] === "boolean")
          document = document.replaceAll(key, bankInfo[key] ? "Да" : "Нет");
        else document = document.replaceAll(key, bankInfo[key] || "");
      }
    }
    if (userInfo) {
      for (let key in userInfo) {
        if (typeof userInfo[key] === "boolean")
          document = document.replaceAll(key, userInfo[key] ? "Да" : "Нет");
        else {
          // Быстрый и легкий костыль :)
          if (key === "clientInn") {
            document = document.replaceAll(key, userInfo[key] || "");
            document = document.replaceAll("clientINN", userInfo[key] || "");
          } else if (key === "clientFamilyStatus") {
            document = document.replaceAll(key, userInfo[key] || "");
            document = document.replaceAll(
              "clientMarital",
              userInfo[key] || ""
            );
          } else {
            document = document.replaceAll(key, userInfo[key] || "");
          }
        }
      }
    }
    setTemplateId(documentId);
    setHtml(document);
    return document;
  }

  useEffect(() => {
    if (html) {
      //@ts-ignore
      setHistoryTemplate(
        historyTemplate.map((x: any) =>
          x.documentTemplateId === templateId
            ? {
                ...x,
                documentTemplateHtml: html,
              }
            : x
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, html]);

  useEffect(() => {
    if (sessionId) {
      OrganizationAPI.getDocumentShortTemplate(sessionId).then((res) => {
        setDocuments(res);
      });

      OrganizationAPI.getOrganization(sessionId).then((res) => {
        setDepartments(res);
        setReplaceDataDepartment(res[0]);
      });
    }
  }, [sessionId]);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [groupTemplate, setGroupTemplate] = useState([]);
  const [historyTemplate, setHistoryTemplate] = useState([]);
  useEffect(() => {
    if (sessionId && showModal) {
      setRequest(true);
      const fetchData = async () => {
        for (const x of childDoc) {
          await OrganizationAPI.getDocumentTemplate(
            sessionId,
            x.documentTemplateId
          )
            .then((res) => {
              // console.log('res: ', res)
              setSelectedDocument(res);
              //@ts-ignore
              historyTemplate.push({ ...res, id: x.id });
              setRequest(false);
            })
            .catch((err) => {
              setRequest(false);
            })
            .finally(() => setRequest(false));
        }
        setGroupTemplate(historyTemplate);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, sessionId]);
  const catchError = (message: string) => {
    setNotification(true);
    setErrorMessage({ error: true, message });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const paymentClickOpen = () => {
    setPaymentOpen(true);
  };

  const paymentClickClose = () => {
    setPaymentOpen(false);
  };
  const groupArray = (array?: any) => {
    //@ts-ignore
    const data = array.reduce((doc: any, elem: any) => {
      if (!doc[elem.tagDescription]) {
        doc[elem.tagDescription] = [];
      }
      doc[elem.tagDescription].push(elem);
      return doc;
    }, {});
    setGroupTag(data);
  };
  const groupTags = Object.keys(groupTag).map((title: string) => {
    return {
      title,
      id: uuidv4(),
      tags: groupTag[title],
    };
  });
  useEffect(() => {
    setGenerateRequest(true);
    const fetchData = async () => {
      if (sessionId && groupTemplate.length) {
        try {
          let tags = await OrganizationAPI.getTags(sessionId);
          //@ts-ignore
          const countMap = new Map<string>();
          //@ts-ignore
          for (let key of groupTemplate[documentIndex].documentTags) {
            //@ts-ignore
            const count = countMap.get(key) ?? 0;
            //@ts-ignore
            countMap.set(key, count + 1);
          }
          //@ts-ignore
          let selectedTag = tags.filter(
            (t: any) => (countMap.get(t.tagKey) || 0) > 1
          );
          groupArray(selectedTag);
          //@ts-ignore
          const countTag = new Map<any>();
          //@ts-ignore
          for (let key of groupTemplate[documentIndex].documentTags) {
            //@ts-ignore
            const count = countTag.get(key) ?? 0;
            //@ts-ignore
            countTag.set(key, count + 1);
          }
          //@ts-ignore
          const tagArray = tags.filter(
            (tg: any) => countTag.get(tg.tagKey) || 0
          );
          setReplaceDataDoc(tagArray);
          const emptyArr = tagArray.filter((tg: any) => !tg.tagValue);
          setInput(emptyArr);
          setGenerateRequest(false);
        } catch (e) {}
      }
    };
    fetchData();
  }, [groupTemplate, documentIndex, selectedDocument, sessionId, showModal]);

  const sendClientContract = () => {
    setShowModal(true);
  };

  const data = documents.reduce((doc: any, elem: any) => {
    if (!doc[elem.documentPath]) {
      doc[elem.documentPath] = [];
    }
    doc[elem.documentPath].push(elem);
    return doc;
  }, {});
  const groupDocuments = Object.keys(data).map((title: string) => {
    return {
      title,
      id: uuidv4(),
      documents: data[title],
    };
  });
  const childFiles = (arr: any, name: string) => () => {
    setChildDocuments(withId(arr).map((x: any) => ({ ...x, selected: false })));
    setBread(name);
  };

  const [childDoc, setChildDoc] = useState<any>([]);
  const documentInfoSelected = (idx: number, elem: any) => {
    if (radioIndex === 0 || childDoc.length <= 1) {
      setRadioIndex(elem.id);
    }
    //@ts-ignore
    setChildDocuments(
      childDocuments.map((x: any, index) =>
        idx === index ? { ...x, selected: !x.selected } : x
      )
    );

    //@ts-ignore
    if (
      childDoc.filter(
        (x: any) => x.documentTemplateId === elem.documentTemplateId
      ).length > 0
    ) {
      //@ts-ignore
      setChildDoc(
        childDoc.filter(
          (x: any) => x.documentTemplateId !== elem.documentTemplateId
        )
      );
    } else {
      //@ts-ignore
      setChildDoc([...childDoc, elem]);
    }
  };
  const backFolder = () => {
    setChildDocuments([]);
    setChildDoc([]);
    setDocumentId(0);
    setHistoryTemplate([]);
    setGroupTemplate([]);
    setRadioIndex(0);
    setDocumentIndex(0);
    setUploadFiles(false);
    setSelectedFiles([]);
  };
  const handleChangeValue = (text: string, index: number) => {
    //@ts-ignore
    setReplaceDataDoc((prevState) =>
      [...prevState].map((item: any) => {
        if (index === item.documentTagId) {
          item.tagValue = !item.tagValue && !text ? " " : text;
        }
        return item;
      })
    );
  };
  const checkEmptyIsArray = () => {
    if (inputForEmptyTags.length) return true;
    return false;
  };

  // useEffect(() => {
  //     if (sessionId) {
  //         console.log('DEBUG', documentId)
  //         ClientDocumentsAPI.getDocument(sessionId, String(documentId))
  //             .then(res => {
  //                 res.documentSignature && setDecode(jwt_decode(res.documentSignature))
  //                 setDocument(res)
  //                 return res.departmentId
  //             })
  //             .then(departmentId => {
  //                 OrganizationAPI.getDepartmentDetails(sessionId, departmentId).then(res => {
  //                     setDepartmentInfo(res);

  //                     console.log('DEBUG', renderToString(
  //                         <DocSign
  //                             documentId={documentId}
  //                             departmentInfo={departmentInfo}
  //                             decode={decode}
  //                             document={document}
  //                             settings={props.settings}
  //                         />
  //                     ))

  //                 })
  //             })
  //     }
  // }, [documentId])
  async function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          id: file.id,
          documentDescription: file.name.split(".")[0],
          wordDocumentBase64: reader.result.split(",")[1],
        });
      reader.onerror = (error) => reject(error);
    });
  }
  const sendDoc = () => {
    if (!isUploadFiles) {
      const html = `
            <html>
                <head>
                    <meta charset="UTF-8">
                </head>
                <body>
                    ${/* @ts-ignore */ ""}
                    ${
                      historyTemplate.find(
                        (template) => template.id === radioIndex
                      ).documentTemplateHtml
                    }
                </body>
            </html>
            `;
      setRequest(true);
      if (sessionId) {
        setSendDocRequest(true);
        //@ts-ignore
        let id = replaceDataDepartment.departmentId
          ? replaceDataDepartment.departmentId
          : 0;

        OrganizationAPI.addDocument(
          Number(requestId),
          sessionId,
          customerAccountId,
          historyTemplate.find((template) => template.id === radioIndex)
            .documentName,
          historyTemplate.find((template) => template.id === radioIndex)
            .documentTemplateId,
          html,
          id,
          // requestId,
          historyTemplate,
          historyTemplate.find((template) => template.id === radioIndex)
            .useQualifiedSignature
        )
          .then((data) => {
            //@ts-ignore
            if (data.success) {
              contractSuccessAction(!contractSuccess);
              setPreviewDocument(false);
              setSendDocRequest(false);
              setHistoryTemplate([]);
              setGroupTemplate([]);
              setDocumentIndex(0);
              setShowModal(false);
              setRequest(false);
              setRadioIndex(0);
              backFolder();
              ClientDocumentsAPI.getSessionEvents(sessionId)
                .then((data) => {
                  if (data) {
                    let filteredEvents = data.filter((item) => {
                      return item.eventName !== "DocumentRead";
                    });
                    if (filteredEvents.length > 0) {
                      if (
                        filteredEvents.filter(
                          (event) => event.eventName === "DocumentDelivered"
                        ).length > 0
                      ) {
                        setErrorMessage({
                          error: false,
                          message: "Документы успешно доставлены клиенту",
                        });
                        setNotification(true);
                        setTimeout(() => setShowModal(false), 3000);
                      }
                      if (
                        filteredEvents.filter(
                          (event) => event.eventName === "DocumentRead"
                        ).length > 0
                      ) {
                        setErrorMessage({
                          error: false,
                          message: "Клиент открыл документ!",
                        });
                        setNotification(true);
                        setShowModal(false);
                      } else {
                        tryOneMorTime();
                      }
                    }
                  }
                })
                .catch(({ response }) => {
                  if (!response) {
                    tryOneMoreTime();
                  }
                });
            }
          })
          .catch(({ response }) => {
            setSendDocRequest(false);
            console.log(response);
            catchError(response.data.message);
          });
      }
    } else {
      // do something
      setRequest(true);
      Promise.all(selectedFiles.map((file) => getBase64(file)))
        .then((data) => {
          OrganizationAPI.addDocument(
            Number(requestId),
            sessionId,
            customerAccountId,
            data.find((file) => file.id === radioIndex).documentDescription,
            data.find((file) => file.id === radioIndex).wordDocumentBase64,
            "undefined",
            0,
            data.filter((file) => file.id !== radioIndex),
            true,
            true
          )
            .then((data) => {
              //@ts-ignore
              if (data.success === true) {
                contractSuccessAction(!contractSuccess);
                setPreviewDocument(false);
                setSendDocRequest(false);
                setHistoryTemplate([]);
                setUploadFiles(false);
                setGroupTemplate([]);
                setDocumentIndex(0);
                setShowModal(false);
                setRadioIndex(0);
                backFolder();
                ClientDocumentsAPI.getSessionEvents(sessionId)
                  .then((data) => {
                    if (data) {
                      setRequest(false);
                      setSelectedFiles([]);
                      let filteredEvents = data.filter((item) => {
                        return item.eventName !== "DocumentRead";
                      });
                      if (filteredEvents.length > 0) {
                        if (
                          filteredEvents.filter(
                            (event) => event.eventName === "DocumentDelivered"
                          ).length > 0
                        ) {
                          setErrorMessage({
                            error: false,
                            message: "Документы успешно доставлены клиенту",
                          });
                          setNotification(true);
                          setTimeout(() => setShowModal(false), 3000);
                        }
                        if (
                          filteredEvents.filter(
                            (event) => event.eventName === "DocumentRead"
                          ).length > 0
                        ) {
                          setErrorMessage({
                            error: false,
                            message: "Клиент открыл документ!",
                          });
                          setNotification(true);
                          setShowModal(false);
                        } else {
                          tryOneMorTime();
                        }
                      }
                    }
                  })
                  .catch(({ response }) => {
                    if (!response) {
                      setUploadFiles(false);
                      tryOneMoreTime();
                    }
                  });
              }
            })
            .catch(({ response }) => {
              setSendDocRequest(false);
              setRequest(false);
              console.log(response);
              catchError(response.data.message);
            });
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage({
            error: true,
            message: "Неудалось конвертировать файл(ы)",
          });
        });
    }
  };
  function tryOneMoreTime() {
    ClientDocumentsAPI.getSessionEvents(sessionId)
      .then((data) => {
        if (data) {
          let filteredEvents = data.filter((item) => {
            return (
              (new Date().getTime() - new Date(item.evenTime).getTime()) /
                1000 <=
              30
            );
          });
          if (filteredEvents.length > 0) {
            if (
              filteredEvents.filter(
                (event) => event.eventName === "DocumentDelivered"
              ).length > 0
            ) {
              setErrorMessage({
                error: false,
                message: "Документы успешно доставлены клиенту",
              });
              setNotification(true);
              setShowModal(false);
              setTimeout(() => setShowModal(false), 3000);
            }
            if (
              filteredEvents.filter(
                (event) => event.eventName === "DocumentRead"
              ).length > 0
            ) {
              setErrorMessage({
                error: false,
                message: "Клиент открыл документ!",
              });
              setNotification(true);
              setShowModal(false);
            } else {
              tryOneMoreTime();
            }
          }
        }
      })
      .catch(({ response }) => {
        if (!response) {
          tryOneMoreTime();
        }
      });
  }
  useEffect(() => {
    if (groupTemplate[documentIndex]) {
      const html = `
            <html>
                <head>
                    <meta charset="UTF-8">
                </head>
                <body>
                    ${/* @ts-ignore */ ""}
                    ${groupTemplate[documentIndex].documentTemplateHtml}
                </body>
            </html>
            `;
      //@ts-ignore
      setHtml(
        replaceAll(
          html,
          replaceDataDoc,
          replaceDataDepartment,
          groupTemplate[documentIndex].documentTemplateId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previewDocument,
    replaceAll,
    documentIndex,
    replaceDataDoc,
    replaceDataDepartment,
    showModal,
  ]);
  const getInfoDoc = (id: number, docmentName: string) => () => {
    setDocumentId(id);
    setDescriptionDoc(docmentName);
  };
  const closeModal = () => {
    setShowModal(false);
    setReplaceDataDoc([]);
    setGroupObj({});
    setHistoryTemplate([]);
    setGroupTemplate([]);
    setRadioIndex(0);
    setDocumentIndex(0);
    setChildDocuments([]);
    setChildDoc([]);
  };
  // const togglePreviewOpen = () => {
  //     setPreviewDocument(true)
  // };
  const togglePreviewClose = () => {
    setPreviewDocument(false);
  };
  //@ts-ignore
  const handleChangeTag = ({ target: { name: tagId, value } }: any) => {
    setGroupObj({ ...groupTagObj, [tagId]: value });
  };
  // console.log('historyTemplate: ', historyTemplate)
  // console.log('group: ', groupTemplate)
  const [isFirstSelector, toggleFileSelector] = useState(false);
  const [isUploadFiles, setUploadFiles] = useState(false);
  const [changeFilesState, setChangeFilesState] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const file = useRef();
  const file2 = useRef();

  React.useEffect(() => {
    if (changeFilesState) {
      let newSelectedFiles = selectedFiles;
      newSelectedFiles.map((f) => {
        if (f.id === changeFilesState.id) {
          f.isSelected = !f.isSelected;
        }
        return f;
      });
      let filteredFiles = newSelectedFiles.filter((file) => file.isSelected);
      if (filteredFiles.length > 0) {
        setRadioIndex(filteredFiles[0].id);
      } else {
        setRadioIndex(0);
      }
      setSelectedFiles(newSelectedFiles);
      setChangeFilesState(false);
    }
  }, [changeFilesState, radioIndex, selectedFiles]);

  const getMesurement = (arrLength) => {
    let number = parseInt(arrLength.toString().split("").reverse()[0]);

    return number === 1 && arrLength !== 11
      ? ""
      : number > 1 && number < 5 && (arrLength < 11 || arrLength > 14)
      ? "a"
      : "ов";
  };

  const handleFileSelect = (e) => {
    if (Boolean(e.target.files[0])) {
      let arrOfFiles = [],
        counter = selectedFiles.length + 1;
      Object.entries(e.target.files).forEach(([_, file]) => {
        let name = file.name ? `${file.name.slice(0, 10)}..` : file.name;

        file.id = counter;
        file.shortName = name;
        file.isSelected = true;
        counter += 1;
        arrOfFiles.push(file);
      });

      let concat = [...selectedFiles, ...arrOfFiles];
      setSelectedFiles(concat);
      setRadioIndex(concat[0].id);
    } else {
      setSelectedFiles(e.target.files);
    }
  };
  return (
    <>
      <input
        ref={file}
        onChange={handleFileSelect}
        type="file"
        accept=".doc, .docx, .xlsx, .xls"
        multiple
        hidden
      />
      <input
        ref={file2}
        onChange={handleFileSelect}
        type="file"
        accept=".doc, .docx, .xlsx, .xls"
        multiple
        hidden
      />
      <div
        className="documentModal"
        style={modalOpen ? { display: "block" } : { display: "none" }}>
        <IconButton className="modalClose" onClick={documentModalClose}>
          <CloseIcon />
        </IconButton>
        <div className="tableTop">
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <div className="tableTop__title">Документы</div>
            </Grid>
            <Grid item>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                className="documentModal__tabs">
                <Tab label="Платежные поручения" {...a11yProps(0)} />
                <Tab label="Документы" {...a11yProps(1)} />
              </Tabs>
            </Grid>
          </Grid>
        </div>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={3} justify="flex-end" alignItems="center">
            <Grid item>
              <Button
                onClick={paymentClickOpen}
                variant="contained"
                color="primary"
                disableElevation>
                Платежное поручение
              </Button>
            </Grid>
          </Grid>
          <br />
          <PaymentOrderTable customerAccountId={customerAccountId} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className="contract">
            <Grid container spacing={3}>
              <Grid item xs={12} sm="auto">
                <Box display="flex" flexWrap="wrap">
                  {!isUploadFiles && !childDocuments.length ? (
                    <>
                      <Box className="documentBoxModal">
                        <NavLink to="#">
                          <Box onClick={() => setUploadFiles(true)}>
                            <div className="documentTitle">
                              Добавление документов
                            </div>
                            <div className="documentBox__wrap">
                              <DepartmentItemIcon />
                            </div>
                          </Box>
                        </NavLink>
                      </Box>

                      {groupDocuments.map((item: any, idx: number) => {
                        return (
                          <Box key={item.id} className="documentBoxModal">
                            <NavLink to="#">
                              <Box
                                onClick={childFiles(
                                  item.documents,
                                  item.title
                                )}>
                                <div className="documentTitle">
                                  {item.title}
                                </div>
                                <div className="documentBox__wrap">
                                  <DepartmentItemIcon />
                                  <div className="documentBox__author">
                                    {`${
                                      groupDocuments[idx].documents.length
                                    } документ${getMesurement(
                                      groupDocuments[idx].documents.length
                                    )}`}
                                  </div>
                                </div>
                              </Box>
                            </NavLink>
                          </Box>
                        );
                      })}
                    </>
                  ) : (
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      className="documents__button">
                      <div className="back-document" onClick={backFolder}>
                        <BackDocumentArrowIcon />
                        <span>{bread}</span>
                      </div>
                      <Box>
                        <Box display="flex" flexWrap="wrap" mb="15px">
                          {isUploadFiles && (
                            <>
                              <div
                                className="child-documents"
                                onClick={() => {
                                  if (isFirstSelector) {
                                    file.current.click();
                                  } else {
                                    file2.current.click();
                                  }
                                  toggleFileSelector((state) => !state);
                                }}
                                style={{
                                  cursor: "pointer",
                                  padding: " 42px 10px 42px 10px",
                                  minHeight: 185,
                                  boxSizing: "border-box",
                                }}>
                                <div className="child-documents__content">
                                  <div className="contract__icon">
                                    <ContractIcon />
                                  </div>
                                  <div className="contract__title">
                                    Добавьте документы
                                  </div>
                                </div>
                              </div>
                              {selectedFiles.length > 0 &&
                                selectedFiles.map(
                                  (file: any, index: number) => {
                                    return (
                                      <div
                                        className="child-documents"
                                        key={index}>
                                        <div
                                          className="child-documents__checkbox"
                                          onClick={() => {
                                            setChangeFilesState(file);
                                          }}>
                                          <Checkbox
                                            color="primary"
                                            checked={file.isSelected}
                                          />
                                        </div>
                                        <div className="child-documents__content">
                                          <div className="contract__icon">
                                            <ContractIcon />
                                          </div>
                                          <div
                                            className="contract__title"
                                            style={{
                                              padding: "0px 10px 0px 10px",
                                            }}>
                                            {file.shortName}
                                          </div>
                                        </div>
                                        <div
                                          className="child-documents__radio"
                                          onClick={() => {
                                            setRadioIndex(file.id);
                                            if (!file.isSelected) {
                                              let newSelectedFiles =
                                                selectedFiles;
                                              newSelectedFiles.map((f) => {
                                                if (f.id === file.id) {
                                                  f.isSelected = !f.isSelected;
                                                }
                                                return f;
                                              });
                                              setSelectedFiles(
                                                newSelectedFiles
                                              );
                                            }
                                          }}>
                                          <Radio
                                            checked={file.id === radioIndex}
                                            checkedIcon={<CheckedIcon />}
                                          />
                                          <div>Главная</div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </>
                          )}
                          {!isUploadFiles &&
                            childDocuments.map((elem: any, idx: number) => {
                              // console.log('asadasd', elem)
                              return (
                                <div className="child-documents">
                                  <div
                                    className="child-documents__checkbox"
                                    onClick={() => {
                                      documentInfoSelected(idx, elem);
                                      getInfoDoc(
                                        elem.documentTemplateId,
                                        elem.documentName
                                      )();
                                    }}>
                                    <Checkbox
                                      color="primary"
                                      checked={elem.selected}
                                    />
                                  </div>
                                  <div className="child-documents__content">
                                    <div className="contract__icon">
                                      <ContractIcon />
                                    </div>
                                    <div
                                      className="contract__title"
                                      style={{ padding: "0px 10px 0px 10px" }}>
                                      {elem.documentName}
                                    </div>
                                  </div>
                                  {elem.selected && (
                                    <div
                                      className="child-documents__radio"
                                      onClick={() => setRadioIndex(elem.id)}>
                                      <Radio
                                        checked={elem.id === radioIndex}
                                        checkedIcon={<CheckedIcon />}
                                      />
                                      <div>Главная</div>
                                    </div>
                                  )}
                                </div>
                                // <Box key={elem.documentTemplateId} className="child-documents"
                                //      onClick={() => {
                                //          documentInfoSelected(idx)
                                //          getInfoDoc(elem.documentTemplateId, elem.documentName)()
                                //      }}>
                                //     <FormControlLabel
                                //         className="contract__item"
                                //         control={
                                //             <Radio
                                //                 checked={elem.documentTemplateId === documentId}
                                //                 name="checkedB"
                                //                 color="primary"
                                //             />
                                //         }
                                //         label={
                                //             <>
                                //                 <div className="contract__icon"><ContractIcon/>
                                //                 </div>
                                //                 <div
                                //                     className="contract__title">{elem.documentName}</div>
                                //             </>
                                //         }
                                //     />
                                // </Box>
                              );
                            })}
                        </Box>
                        <Button
                          className="send-client__button"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={
                            (!isUploadFiles && documentId === 0) ||
                            (isUploadFiles && selectedFiles.length === 0) ||
                            request
                          }
                          onClick={
                            isUploadFiles ? sendDoc : sendClientContract
                          }>
                          {request ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Отправить клиенту"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="documentHistory">
            <div className="documentHistory__title">История договоров</div>
            <DocumentTable customerAccountId={customerAccountId} />
          </div>
        </TabPanel>
        <PaymentOrderModal
          paymentOpen={paymentOpen}
          paymentClickClose={paymentClickClose}
          customerAccountId={customerAccountId}
        />
        <SnackbarAlert
          notification={notification}
          setNotification={setNotification}
          message={errorMessage.message}
          severity={errorMessage.error ? "error" : "success"}
          vertical="top"
          horizontal="center"
        />
      </div>
      <Dialog
        open={showModal && (request || childDoc.length)}
        onClose={closeModal}>
        {request ? (
          <CircularProgress size={24} />
        ) : (
          <DialogContent style={{ width: 500, padding: 0 }}>
            {childDoc.map((x: any, index: number) => {
              return (
                <React.Fragment key={x.documentTemplateId}>
                  {documentIndex === index && (
                    <>
                      <div className="modal-paper">
                        <h3 style={{ textAlign: "center" }}>
                          {x.documentName}
                        </h3>
                        {departments.length ? (
                          <List className="document-list">
                            <FormControl>
                              <h3 style={{ fontSize: 14 }}>
                                Выберите подразделение
                              </h3>
                              <Select
                                value={
                                  replaceDataDepartment
                                    ? replaceDataDepartment
                                    : ""
                                }
                                onChange={(e: any) =>
                                  setReplaceDataDepartment(e.target.value)
                                }>
                                {departments.map((elem: any) => {
                                  return (
                                    <MenuItem
                                      key={elem.departmentId}
                                      value={elem}>
                                      {elem.departmentName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </List>
                        ) : null}
                        <Box width={1}>
                          {groupTags.length
                            ? groupTags.map(
                                ({ tags, title, id }: any, i: number) => (
                                  <div key={id}>
                                    <div className="selectedName">{title}</div>
                                    <Select
                                      className="select-tag"
                                      value={groupTagObj[tags[0].tagKey]}
                                      name={tags[0].tagKey}
                                      onChange={handleChangeTag}>
                                      {tags.map(({ tagValue }: any) => (
                                        <MenuItem value={tagValue}>
                                          {tagValue}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                )
                              )
                            : null}
                        </Box>
                        <Box>
                          {checkEmptyIsArray() && (
                            <h3 style={{ fontSize: 14, marginBottom: 0 }}>
                              Заполните значения
                            </h3>
                          )}
                          {generateDataRequest ? (
                            <CircularProgress size={24} />
                          ) : (
                            inputForEmptyTags
                              //   .sort((a: any, b: any) => {
                              //     let textA = a.tagKey.toUpperCase()
                              //     let textB = b.tagKey.toUpperCase()
                              //     return textA > textB
                              //       ? -1
                              //       : textA < textB
                              //       ? 1
                              //       : 0
                              //   })
                              .map((tag: any) => {
                                // console.log(tag)
                                return (
                                  <Box
                                    key={tag.documentTagId}
                                    display="flex"
                                    className="empty-tags__input">
                                    <TextField
                                      name={tag.tagKey}
                                      label={tag.tagDescription.slice(0, 25)}
                                      onChange={(e) =>
                                        handleChangeValue(
                                          e.target.value,
                                          tag.documentTagId
                                        )
                                      }
                                    />
                                  </Box>
                                );
                              })
                          )}
                        </Box>
                        <span
                          className="previewDoc"
                          onClick={() => setPreviewDocument(true)}>
                          Посмотреть
                        </span>
                        <Box mb="20px" className="doc-button-wrap">
                          {/*<Button className="green-button tags-modal" variant="contained"*/}
                          {/*        onClick={togglePreviewOpen}>Посмотреть</Button>*/}
                          <Button
                            // className="send-client__button"
                            color="primary"
                            disableElevation
                            disabled={documentId === 0 || request}
                            onClick={() => {
                              if (childDoc.length > 1) {
                                if (childDoc.length - 1 === documentIndex) {
                                  return setDocumentIndex(
                                    (prevState) => prevState - 1
                                  );
                                } else if (!documentIndex) {
                                  setChildDoc([]);
                                  return closeModal();
                                } else {
                                  return setDocumentIndex(
                                    (prevState) => prevState - 1
                                  );
                                }
                              } else {
                                setChildDoc([]);
                                closeModal();
                              }
                            }}>
                            {!documentIndex ? "Отмена" : "Назад"}
                          </Button>
                          <Button
                            // className="send-client__button"
                            variant="contained"
                            color="primary"
                            disableElevation
                            disabled={documentId === 0 || request}
                            onClick={() => {
                              return childDoc.length - 1 === documentIndex
                                ? sendDoc()
                                : setDocumentIndex(
                                    (prevState) => prevState + 1
                                  );
                            }}>
                            {childDoc.length - 1 === documentIndex
                              ? "Отправить"
                              : "Далее"}
                          </Button>
                        </Box>
                      </div>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </DialogContent>
        )}
      </Dialog>
      <PreviewDocument
        preloader={sendDocRequest}
        send={sendDoc}
        show={previewDocument}
        toggleShow={togglePreviewClose}
        index={documentIndex}
        setIndex={setDocumentIndex}
        html={historyTemplate}
      />
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  paymentOrderSuccess: state.document.paymentOrderSuccess,
  contractSuccess: state.document.contractSuccess,
  requestId: state.moderation.requestId,
  settings: state.admin.settings,
  documents: state.admin.documents,
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  contractSuccessAction: (contractSuccess: boolean) =>
    dispatch(actions.document.contractSuccessAction(contractSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentModal);
