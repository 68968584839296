import React, { Dispatch, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DeleteIcon } from "../../icons/icons";
import "./ModalOfReduction.scss";
import SecondReductionModal from "./SecondModalOfReduction";
import { Reduction } from "../../store/Reduction/types";
import { RootState } from "../../store";
import { Action, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { addReductionSuccess } from "../../store/Reduction/action";
import { connect } from "react-redux";
import { ReductionAPI } from "../../api/ReductionAPI";
import { createStyles, TableContainer, Theme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    open: boolean;
    openClose: () => void;
    data: Reduction[];
  };
const ReductionModal: React.FC<Props> = ({
  open,
  openClose,
  sessionId,
  addReductionSuccess,
  data,
}) => {
  const [secondModalOpen, setSecondModalOpen] = useState(false);

  const openCloseSecondModal = () => setSecondModalOpen(!secondModalOpen);
  const deleteTag = (id: number) => {
    if (sessionId) {
      if (window.confirm("Удалить сокращения?"))
        ReductionAPI.deleteTags(sessionId, id).then((res) => {
          if (res.success) {
            addReductionSuccess();
          }
        });
    }
  };
  const classes = useStyles();
  return (
    <div>
      <Dialog open={open} onClose={openClose} className={"reduction"}>
        <DialogTitle className={"reduction__title"}>
          Сокращения
          <Button
            onClick={openCloseSecondModal}
            variant="outlined"
            color="primary"
            className="reduction__add-button">
            Добавить
          </Button>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} className={classes.root}>
            <Table>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell align="left">№</TableCell>
                  <TableCell>Имя тега</TableCell>
                  <TableCell align="left">Описание</TableCell>
                  <TableCell align="left">Значение</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.body}>
                {data.map((tag, i: number) => (
                  <TableRow key={tag.documentTagId}>
                    <TableCell align="left">{i + 1}</TableCell>
                    <TableCell align="left">{tag.tagKey}</TableCell>
                    <TableCell align="left">{tag.tagDescription}</TableCell>
                    <TableCell align="left">{tag.tagValue}</TableCell>
                    <TableCell align="left">
                      <div
                        className="reduction__item-interface"
                        onClick={() => deleteTag(tag.documentTagId)}>
                        <DeleteIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className="reduction__actions">
          <Button onClick={openClose} className="reduction__action-button">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
      <SecondReductionModal
        open={secondModalOpen}
        setOpen={openCloseSecondModal}
      />
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& a": {
        color: "#085CFF",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    head: {
      fontFamily: "Nunito sans-serif",
      backgroundColor: "#fff",
      "& .MuiTableCell-root": {
        color: "#333",
        fontWeight: 700,
      },
    },
    body: {
      "& .MuiTableRow-root:hover": {
        background: "#F1F6F8",
      },
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: 20,
      fontSize: 12,
    },
    positionName: {
      color: "#333",
      textDecoration: "underline",
      fontSize: "14px",
    },
    copyContactIcon: {
      cursor: "pointer",
    },
  })
);
const mapStateToProps = (store: RootState) => ({
  sessionId: store.auth.sessionId,
});
const mapDispatchToProps = (
  dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
  addReductionSuccess: () => dispatch(addReductionSuccess()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReductionModal);
