import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { ClientDocumentsAPI } from "../../api/ClientDocumentsAPI";
import Moment from "react-moment";
import "moment/locale/ru";
import { IContract } from "../../store/Document/types";
import ContractModal from "../bricks/ContractModal";
import jwt_decode from "jwt-decode";
import { OrganizationAPI } from "../../api/OrganizationAPI";
import printer from "../../icons/printer.svg";
import video from "../../icons/video.svg";
import { AdminAPI } from "../../api/AdminAPI";
import { sessionStorageGetItem } from "../../utils/storage";

type Props = ReturnType<typeof mapStateToProps> & {
  customerAccountId?: number;
};

interface IGetVideoLink {
  completedConferenceLink: string;
  success: boolean;
  message?: string;
}

const DocumentTable: React.FC<Props> = ({
  sessionId,
  customerAccountId,
  contractSuccess,
}) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [documentId, setDocumentId] = useState("");
  const [contractOpen, setContractOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState<any>(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [departmentInfo, setDepartmentInfo] = useState({});
  const [decode, setDecode] = useState<any>(null);
  const [successDoc, setSuccessDoc] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);

  const contractClickOpen = (documentId: any) => {
    if (documentId.documentType === "Pdf") {
      // @ts-ignore\
      let { apiUrl } = JSON.parse(sessionStorage.getItem("settings")),
        url = `${apiUrl}/documents/GetDocumentPdf/${sessionId}/${documentId.documentId}`;

      if (xs) {
        return window.open(url, "_blank");
      }

      documentId.url = url;
    }
    setContractOpen(true);
    setDoc(documentId);
    setDocumentId(documentId.documentHtml);
  };

  const contractClickClose = () => {
    setContractOpen(false);
  };

  // @ts-ignore
  const getPdf = (contract) => {
    if (contract.documentType === "Pdf") {
      // @ts-ignore\
      let { apiUrl } = JSON.parse(sessionStorage.getItem("settings")),
        url = `${apiUrl}/documents/GetDocumentPdf/${sessionId}/${contract.documentId}`;

      return window.open(url, "_blank");
    }

    window.open("/document-preview/" + contract.documentId, "_blank");
  };

  useEffect(() => {
    if (sessionId && customerAccountId) {
      setLoading(true);
      ClientDocumentsAPI.getDocuments(
        sessionId,
        customerAccountId,
        "Signed"
      ).then((data) => {
        setContracts(data);
        setLoading(false);
      });
      //@ts-ignore
      let intervals;
      intervals = setInterval(() => {
        ClientDocumentsAPI.getDocuments(
          sessionId,
          customerAccountId,
          "Signed"
        ).then((data) => {
          setContracts(data);
        });
      }, 5000);
      //@ts-ignore
      return () => clearInterval(intervals);
    }
  }, [sessionId, customerAccountId, contractSuccess, successDoc]);

  useEffect(() => {
    if (sessionId) {
      if (doc) {
        //@ts-ignore
        ClientDocumentsAPI.getDocument(sessionId, doc.documentId).then(
          (res) => {
            setDepartmentId(res.departmentId);
            res.documentSignature &&
              setDecode(jwt_decode(res.documentSignature));
          }
        );
      }
    }
  }, [sessionId, doc]);

  useEffect(() => {
    if (sessionId) {
      //@ts-ignore
      if (departmentId) {
        OrganizationAPI.getDepartmentDetails(sessionId, departmentId).then(
          (res) => {
            setDepartmentInfo(res);
          }
        );
      }
    }
  }, [sessionId, departmentId]);

  const getVideo = useCallback(
    async (requestId: any) => {
      setVideoLoading(true);
      try {
        const videoLink: IGetVideoLink = await AdminAPI.getClientRequestVideo(
          sessionId!,
          requestId
        );
        if (videoLink.success) {
          window.open(videoLink.completedConferenceLink);
        } else {
          alert(videoLink.message);
        }
        setVideoLoading(false);
      } catch (e) {
        console.error(e);
        setVideoLoading(false);
      }
    },
    [sessionId]
  );

  // @ts-ignore
  return (
    <Paper>
      <TableContainer className="tableContainer clientsTable">
        <Table className="table" stickyHeader aria-label="sticky table">
          <TableHead className="tableHead">
            <TableRow>
              <TableCell align="left">Дата</TableCell>
              <TableCell align="left">Документ</TableCell>
              <TableCell align="left">Действие</TableCell>
              <TableCell align="right">Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts
              .sort((a: any, b: any) => {
                let textA = a.createdDate;
                let textB = b.createdDate;
                return textA > textB ? -1 : textA < textB ? 1 : 0;
              })
              .map((contract, i) => (
                <TableRow key={i}>
                  <TableCell
                    align="left"
                    style={{
                      color:
                        contract.executionStatus === "New" ? "red" : "green",
                    }}>
                    <Moment format="D MMMM YYYY">{contract.createdDate}</Moment>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      color:
                        contract.executionStatus === "New" ? "red" : "green",
                    }}
                    component="th"
                    scope="row">
                    {contract.documentDescription}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      color="primary"
                      className="tableButton"
                      variant="outlined"
                      onClick={() => contractClickOpen(contract)}>
                      Просмотр
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <div
                      className={`document-table__actions document-table__actions${
                        videoLoading ? "--loading" : ""
                      }`}>
                      <img
                        alt=""
                        src={video}
                        onClick={() => getVideo(contract.requestId)}
                      />
                      <img
                        alt=""
                        src={printer}
                        onClick={() => getPdf(contract)}
                      />
                    </div>
                    {/* <Button color="primary" className="tableButton" variant="outlined"
                                            onClick={() => getPdf(contract)}>Печать</Button> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ContractModal
        successDoc={successDoc}
        setSuccess={setSuccessDoc}
        departmentInfo={departmentInfo}
        decode={decode}
        doc={doc}
        contractOpen={contractOpen}
        contractClickClose={contractClickClose}
        documentId={documentId}
      />
      {loading && (
        <div className="preloader-for-table">
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.auth.sessionId,
  contractSuccess: state.document.contractSuccess,
  getUserInfo: state.auth.getUserInfo,
});

export default connect(mapStateToProps)(DocumentTable);
