import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import {Avatar, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import {
    PersonalIcon,
    HistoryIcon,
    OrganizationIcon,
    ServicesIcon,
    LogoIconBrand,
    DocumentMenuIcon,
    AvconIcon
} from '../../icons/icons';
import { NavLink } from 'react-router-dom';
import Toolbar from "@material-ui/core/Toolbar";
import {randomColor} from "../../utils/randomColor";

type Props = ReturnType<typeof mapStateToProps>

const Menu: React.FC<Props> = (props) => {
    const { getUserInfo, settings } = props;
    const userName = getUserInfo ? getUserInfo.fullName : '';

    const companyAdmin = () => {
        if(getUserInfo && getUserInfo.roles === "CompanyAdmin") {
            return (
                <List>
                    {settings && settings.name !== "Avcon" &&<NavLink className="menuNav" to="/" exact>
                        <ListItem button>
                            <ListItemIcon>
                                <OrganizationIcon />
                            </ListItemIcon>
                            <ListItemText primary="Организация" />
                        </ListItem>
                    </NavLink>}
                    {/* <NavLink className="menuNav" to="/" exact>
                        <ListItem button>
                            <ListItemIcon>
                                <OrganizationIcon />
                            </ListItemIcon>
                            <ListItemText primary="Организация" />
                        </ListItem>
                    </NavLink> */}
                    <NavLink className="menuNav" to="/services" exact>
                        <ListItem button>
                            <ListItemIcon>
                                <ServicesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Услуги" />
                        </ListItem>
                    </NavLink>
                    <NavLink className="menuNav" to="/personal" exact>
                        <ListItem button>
                            <ListItemIcon>
                                <PersonalIcon />
                            </ListItemIcon>
                            <ListItemText primary="Персонал" />
                        </ListItem>
                    </NavLink>
                    <NavLink className="menuNav" to="/clients">
                        <ListItem button>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Клиенты" />
                        </ListItem>
                    </NavLink>
                    <NavLink className="menuNav" to="/list/document">
                        <ListItem button>
                            <ListItemIcon>
                                <DocumentMenuIcon />
                            </ListItemIcon>
                            <ListItemText primary="Документы" />
                        </ListItem>
                    </NavLink>
                </List>
            )
        }
    }

    const moderator = () => {
        if(getUserInfo && getUserInfo.roles === "Moderator") {
            return (
                <List>
                    <NavLink className="menuNav" to="/" exact>
                        <ListItem button>
                            <ListItemIcon>
                                <PersonalIcon />
                            </ListItemIcon>
                            <ListItemText primary="Заявки" />
                        </ListItem>
                    </NavLink>
                    <NavLink className="menuNav" to="/clients">
                        <ListItem button>
                            <ListItemIcon>
                                <HistoryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Клиенты" />
                        </ListItem>
                    </NavLink>
                </List>
            )
        }
    }

    return (
        <Drawer
            variant="permanent"
            className="menuDrawer">
            <div className="logo-icon">
                {settings && settings.name === "Avcon" ? <AvconIcon/> : <LogoIconBrand />}
            </div>
            {companyAdmin()}
            {moderator()}
            <div className="profile-menu">
                <Toolbar className="profile-menu__toolbar">
                    <Box className="profile-menu__profile-box">
                        <Box className="profile-menu__profile">
                            <Avatar alt="" src="/broken-image.jpg" style={{backgroundColor: randomColor()}}>
                                {userName.substr(0, 1)}
                            </Avatar>
                            <Typography>{userName}</Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </div>
        </Drawer>
    )
}

const mapStateToProps = (state: RootState) => ({
    getUserInfo: state.auth.getUserInfo,
    settings: state.admin.settings
});

export default connect(mapStateToProps)(Menu);
