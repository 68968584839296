import {Reducer} from "redux";
import {DocumentActionsTypes, DocumentState} from "./types";
import {DocumentActions} from "./actions";

const defaultState: DocumentState = {
    paymentOrderSuccess: false,
    contractSuccess: false,
    reRenderBankAccount: false
};

export const DocumentReducer: Reducer<DocumentState, DocumentActions> = (state = defaultState, action) => {

    switch (action.type) {
        case DocumentActionsTypes.SUCCESS_BANKACCOUNT:
            return {
                ...state,
                reRenderBankAccount: !state.reRenderBankAccount
            };
        case DocumentActionsTypes.PAYMENT_ORDER_SUCCESS:
            return {
                ...state,
                paymentOrderSuccess: action.paymentOrderSuccess
            };
        case DocumentActionsTypes.CONTRACT_SUCCESS:
            return {
                ...state,
                contractSuccess: action.contractSuccess
            };
        default:
            return state;
    }
};

export default DocumentReducer;
