import { InstanceCreditHead } from './InstanceHead'

export class CreditAPI {
    public static async getSmartIdCustomer(sessionId: string, clientAccountId: number) {
        return await InstanceCreditHead.instance
            .post(`Registration/GetSmartIdCustomer/${sessionId}/${clientAccountId}`)
            .then(res => {
                return res.data;
            })
            .catch(() => console.log('GetSmartIdCustomer erorr'))
    }
    
    public static async getUserReports(sessionId: string, clientAccountId: number) {
        try {
            return await InstanceCreditHead.instance
                .get(`Customers/GetUserReports/${sessionId}/${clientAccountId}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => console.log('GetUserReports error'))
        } catch (error) {
            throw error
        }
    }
    
    public static async requestReport(sessionId: string, clientAccountId: number, reportType: string) {
        try {
            return await InstanceCreditHead.instance
                .post(`Customers/RequestReport/${sessionId}/${clientAccountId}/${reportType}`)
                .then(res => {
                    return res.data;
                })
                .catch(() => console.log('RequestReport error'))
        } catch (error) {
            throw error
        }
    }

    public static async getReportStatus(sessionId: string, requestId: number) {
        return await InstanceCreditHead.instance.get(`Customers/GetReportStatus/${sessionId}/${requestId}`).then(res => {
            return res.data;
        });
    }

    public static async getCreditReportPdf(sessionId: string, customerId: string) {
        return await InstanceCreditHead.instance.get(`Pdf/GetIshenimReportPdf/?sessionId=${sessionId}&customerId=${customerId}`).then(res => {
            return res.data
        })
    }

    public static async getSalaryReportPdf(sessionId: string, customerId: string) {
        return await InstanceCreditHead.instance.get(`Pdf/GetSettlementServiceReportPdf/?sessionId=${sessionId}&customerId=${customerId}`).then(res => {
            return res.data
        })
    }
}
