import React, {Dispatch, useEffect, useState} from 'react';
import {Dialog, Input, TextField} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {RootState} from "../../../../store";
import {connect} from "react-redux";
import {OrganizationAPI} from "../../../../api/OrganizationAPI";
import {Action, AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {addReductionSuccess} from "../../../../store/Reduction/action";

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    addDepartmentModal: () => void;
    open: boolean;
    id: number;
    setId: (id: number) => void;
    employees: any
}

const initialError = {
    text: '',
    error: false,
};

const initialMessage = {
    departmentName: initialError,
    departmentHeadFullName: initialError,
    departmentHeadPosition: initialError,
    departmentAppointment: initialError,
    departmentAddress: initialError,
    departmentBIK: initialError,
    departmentPhone: initialError,
    departmentDateAppointment: initialError,
    departmentHeadAssignedByWhom: initialError,
    departmentBank: initialError,
    departmentPaymentAccount: initialError,
};


const AddDepartment: React.FC<Props> = ({addDepartmentModal, employees, setId, addReductionSuccess, id, sessionId, open}) => {
    const [form, setForm] = useState({
        departmentName: '',
        departmentHeadFullName: '',
        departmentHeadPosition: '',
        departmentAppointment: '',
        departmentAddress: '',
        departmentBIK: '',
        departmentINN: '',
        departmentOKPO: '',
        departmentPhone: '',
        departmentDateAppointment: '',
        departmentHeadAssignedByWhom: '',
        departmentBank: '',
        departmentPaymentAccount: '',
        filialCode: '',
        sberkassa: '',
        swift: '',
    });
    let defaultData = {
        departmentName: '',
        departmentHeadFullName: '',
        departmentHeadPosition: '',
        departmentAppointment: '',
        departmentAddress: '',
        departmentINN: '',
        departmentOKPO: '',
        departmentPhone: '',
        departmentDateAppointment: '',
        departmentHeadAssignedByWhom: '',
        departmentBIK: '',
        departmentBank: '',
        departmentPaymentAccount: '',
        filialCode: '',
        sberkassa: '',
        swift: '',
    };
    const [message, setMessage] = useState(initialMessage);

    useEffect(() => {
        if (sessionId && id !== 0) {
            OrganizationAPI.getDepartmentDetails(sessionId, id).then(res => {
                setForm(res);
            })
        }
    }, [id, open, sessionId]);

    const validate = () => {
        let departmentName = initialError;
        let departmentHeadFullName = initialError;
        let departmentHeadPosition = initialError;
        let departmentAppointment = initialError;
        let departmentAddress = initialError;
        let departmentPhone = initialError;
        let departmentDateAppointment = initialError;



        if (form.departmentName.length < 3) {
            departmentName = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }

        if (form.departmentHeadFullName.length < 3) {
            departmentHeadFullName = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }
        if (form.departmentHeadPosition.length < 3) {
            departmentHeadPosition = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }
        if (form.departmentAppointment.length < 3) {
            departmentAppointment = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }
        if (form.departmentAddress.length < 3) {
            departmentAddress = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }
        if (form.departmentPhone.length < 3) {
            departmentPhone = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }
        //@ts-ignore
        if (form.departmentDateAppointment.length < 3) {
            departmentDateAppointment = {
                text: 'Количество символов не меньше 3х',
                error: true,
            };
        }


        if ( departmentHeadPosition.text || departmentName.text || departmentPhone.text || departmentAddress.text || departmentAppointment.text || departmentDateAppointment.text || departmentHeadFullName.text) {
            setMessage({
                ...message,
                departmentName,
                departmentPhone,
                departmentAddress,
                departmentAppointment,
                departmentDateAppointment,
                departmentHeadFullName,
                departmentHeadPosition,
            });
            return false;
        }

        return true;

    };

    const saveDepartment = () => {
        const isValid = validate()
        if (isValid) {
            setMessage(initialMessage);
        }
        if (sessionId && isValid) {
            // @ts-ignore
            const {
                departmentName, departmentHeadFullName, departmentHeadPosition,
                departmentAppointment,
                departmentAddress,
                departmentBIK,
                departmentBank,
                departmentPaymentAccount,
                departmentINN,
                departmentOKPO,
                departmentPhone,
                departmentDateAppointment,
                departmentHeadAssignedByWhom,
                filialCode,
                sberkassa,
                swift
            } = form;
            OrganizationAPI.setDepartment(sessionId, departmentName, departmentHeadFullName, departmentHeadPosition, departmentAppointment, departmentAddress, departmentBIK, departmentINN, departmentOKPO, departmentPhone, departmentDateAppointment, departmentHeadAssignedByWhom, id, departmentPaymentAccount, departmentBank, filialCode, sberkassa, swift).then(res => {
                if (res.success) {
                    setId(0);
                    addDepartmentModal();
                    addReductionSuccess()
                    setForm(defaultData)
                }
            })
        }
    };
    const handleChange = (e: any) => {
        if ((e.target.name === "departmentINN" && +e.target.value.length > 14) || (e.target.name === "departmentOKPO" && +e.target.value.length > 8)) {
            return
        }
        setForm({...form, [e.target.name]: e.target.value})
    };
    // const handleDelete = () => {
    //     if (sessionId && id !== 0) {
    //         OrganizationAPI.deleteDepartments(sessionId, id).then(res => {
    //             if (res.success) {
    //                 addDepartmentModal();
    //                 addReductionSuccess()
    //                 setId(0)
    //                 setForm(defaultData)
    //             }
    //         })
    //     }
    // };
    const handleCancel = () => {
        addDepartmentModal();
        setId(0);
        setForm(defaultData);
        setMessage(initialMessage);
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                className="addOrganizationModal">
                <DialogContent className="addOrganizationModal__content">
                    <div
                        className="addOrganizationModal__title">{id !== 0 ? "Редактирование подразделение" : "Новое подразделение"}</div>

                    <FormControl fullWidth required error={message.departmentName.error}>

                        <InputLabel>Наименование подразделения ({' [departmentName]'})</InputLabel>
                        <Input
                            value={form.departmentName}
                            disabled={id !== 0}
                            name="departmentName"
                            autoFocus
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentName: ''})}>
                                    {form.departmentName && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{message.departmentName.text}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required
                                 error={Boolean(form.departmentINN && form.departmentINN.length !== 14)}>

                        <InputLabel>ИНН подразделения ({' [departmentINN]'})</InputLabel>
                        <Input
                            disabled={id !== 0}
                            value={form.departmentINN}
                            name="departmentINN"
                            type="number"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentINN: ''})}>
                                    {form.departmentINN && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>

                        <FormHelperText>{form.departmentINN.length + '/14'}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required
                                 error={Boolean(form.departmentOKPO && form.departmentOKPO.length !== 8)}>

                        <InputLabel>ОКПО подразделения ({' [departmentOKPO]'})</InputLabel>
                        <Input
                            value={form.departmentOKPO}
                            name="departmentOKPO"
                            type="number"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentOKPO: ''})}>
                                    {form.departmentOKPO && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{form.departmentOKPO.length + '/8'}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required
                    >
                        <InputLabel>Код филила ({'[filialCode]'})</InputLabel>
                        <Input
                            value={form.filialCode}
                            name="filialCode"
                            type="text"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, filialCode: ''})}>
                                    {form.filialCode && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <FormControl fullWidth required
                                >

                        <InputLabel>Сберкасса ({'[sberkassa]'})</InputLabel>
                        <Input
                            value={form.sberkassa}
                            name="sberkassa"
                            type="text"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, sberkassa: ''})}>
                                    {form.sberkassa && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <FormControl fullWidth required
                     >

                        <InputLabel>Swift ({'[swift]'})</InputLabel>
                        <Input
                            value={form.swift}
                            name="swift"
                            type="text"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, swift: ''})}>
                                    {form.swift && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <FormControl fullWidth required error={message.departmentAddress.error}>
                        <InputLabel>Адрес подразделения ({' [departmentAddress]'})</InputLabel>
                        <Input
                            value={form.departmentAddress}
                            name="departmentAddress"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentAddress: ''})}>
                                    {form.departmentAddress && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{message.departmentAddress.text}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required error={message.departmentPhone.error}>
                        <InputLabel>Контактный телефон подразделения ({' [departmentPhone]'})</InputLabel>
                        <Input
                            value={form.departmentPhone}
                            name="departmentPhone"
                            onChange={handleChange}
                            type="number"
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentPhone: ''})}>
                                    {form.departmentPhone && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{message.departmentPhone.text}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth required error={message.departmentHeadFullName.error}>
                        <InputLabel>ФИО руководителя ({' [departmentHeadFullName]'})</InputLabel>
                        <Input
                            value={form.departmentHeadFullName}
                            name="departmentHeadFullName"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentHeadFullName: ''})}>
                                    {form.departmentHeadFullName && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{message.departmentHeadFullName.text}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required error={message.departmentHeadPosition.error}>
                        <InputLabel>Должность руководителя ({' [departmentHeadPosition]'})</InputLabel>
                        <Input
                            value={form.departmentHeadPosition}
                            name="departmentHeadPosition"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentHeadPosition: ''})}>
                                    {form.departmentHeadPosition && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{message.departmentHeadPosition.text}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required error={message.departmentAppointment.error}>
                        <InputLabel>Основание назначения ({' [departmentAppointment]'})</InputLabel>
                        <Input
                            value={form.departmentAppointment}
                            name="departmentAppointment"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear" onClick={() => setForm({
                                    ...form,
                                    departmentAppointment: ''
                                })}>
                                    {form.departmentAppointment && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                        <FormHelperText>{message.departmentAppointment.text}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required error={message.departmentAppointment.error}>
                        <TextField
                            id="date"
                            label="Дата назначения руководителя ([departmentDateAppointment])"
                            type="date"
                            name="departmentDateAppointment"
                            onChange={handleChange}
                            value={form.departmentDateAppointment}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormHelperText>{message.departmentAppointment.text}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth required>
                        <InputLabel>Кем удостоверено назначение ({' [departmentHeadAssignedByWhom]'})</InputLabel>
                        <Input
                            value={form.departmentHeadAssignedByWhom}
                            name="departmentHeadAssignedByWhom"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({
                                                    ...form,
                                                    departmentHeadAssignedByWhom: ''
                                                })}>
                                    {form.departmentHeadAssignedByWhom && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Банк ({' [departmentBank]'})</InputLabel>
                        <Input
                            value={form.departmentBank}
                            name="departmentBank"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentBank: ''})}>
                                    {form.departmentBank && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <FormControl fullWidth>

                        <InputLabel>БИК ({' [departmentBIK]'})</InputLabel>
                        <Input
                            value={form.departmentBIK}
                            name="departmentBIK"
                            type="number"
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentBIK: ''})}>
                                    {form.departmentBIK && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <FormControl fullWidth>

                        <InputLabel>Расчетный счет ({' [departmentPaymentAccount]'})</InputLabel>
                        <Input
                            value={form.departmentPaymentAccount}
                            name="departmentPaymentAccount"
                            onChange={handleChange}
                            type="number"
                            endAdornment={
                                <InputAdornment position="end" className="iconClear"
                                                onClick={() => setForm({...form, departmentPaymentAccount: ''})}>
                                    {form.departmentPaymentAccount && <IconButton>
                                        <CloseIcon/>
                                    </IconButton>}
                                </InputAdornment>
                            }/>
                    </FormControl>
                    <Box className="modalButtonGroup">
                        <Button onClick={handleCancel}>Отмена</Button>
                        <Button variant="contained" className="green-button"
                                onClick={saveDepartment}>Сохранить</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addReductionSuccess: () => dispatch(addReductionSuccess())
});
export default connect(mapStateToProps, mapDispatchToProps)(AddDepartment);
