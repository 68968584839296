import React, {useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../store';
import {Dialog, DialogTitle, DialogContent, DialogActions, Box, Button} from '@material-ui/core';
import {ModerationApi} from '../../api/ModerationApi';
import ProcessDialog from './ProcessDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {DeleteIcon} from "../../icons/icons";

type Props = ReturnType<typeof mapStateToProps> & {
    clientRegOpen: boolean;
    clientRegClickClose: () => void;
    setCheckRegistration: () => void
}

const ClientRegistration: React.FC<Props> = ({sessionId, setCheckRegistration, activeRequest, clientRegOpen, clientRegClickClose}) => {
    const [processOpen, setProcessOpen] = useState(false);
    const [pdf, setPdf] = useState<any>('');
    const processClickOpen = () => {
        setProcessOpen(true)
    }

    const processClickClose = () => {
        setProcessOpen(false)
    }

    const clientRegistration = () => {
        const formData = new FormData();
        formData.append('file', pdf);
        if (sessionId && activeRequest) {
            ModerationApi.requestRegistrationForGuest(sessionId, activeRequest.requestId, formData).then(data => {
                if (data.success === true) {

                    setCheckRegistration()
                    clientRegClickClose();
                    processClickOpen();
                }
            }).catch(({response}) => alert(response.data.message));
        }
    };
    const handleChangeFile = (e: any) => {
        setPdf(e.target.files[0])
    };
    return (
        <>
            <Dialog
                open={clientRegOpen}
                className="modal confirm sendPdf__modal"
            >
                <DialogTitle>Регистрация клиента</DialogTitle>
                <DialogContent>
                    <p className="modal__text">Отправить клиенту форму регистрации</p>
                    <Box display="flex" alignItems="center">
                        <label className="upload__pdf" htmlFor="file">
                            <AttachFileIcon/>
                            {pdf ? <span className="pdfWrap">
                                   <span>{pdf.name}</span>
                           </span> :
                                <span>Нажмите для загрузки ГРНП</span>}
                            {!pdf && <input id="file" name="file" style={{display: "none"}} type="file"
                                            onChange={handleChangeFile}/>}
                        </label>
                        {pdf && <span className="removePdf"
                                      onClick={(e: any) => setPdf('')}>  <DeleteIcon/></span>}
                    </Box>
                </DialogContent>
                <DialogActions className="modal__actions">
                    <Button onClick={clientRegClickClose} color="primary">Отмена</Button>
                    <Button onClick={clientRegistration} disabled={pdf === ''} variant="contained" color="primary"
                            disableElevation>Отправить</Button>
                </DialogActions>
            </Dialog>
            <ProcessDialog
                processOpen={processOpen}
                processClickClose={processClickClose}/>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    activeRequest: state.moderation.activeRequest,
});

export default connect(mapStateToProps)(ClientRegistration);
